import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'

import {actions as authActions} from '../../redux/modules/Auth'//
import ResellerSettings from './Settings/ResellerSettings'//
import ResellerSettingsMarkTypes from './Settings/ResellerSettingsMarkTypes'//
import ResellerSettingsAreas from './Settings/ResellerSettingsAreas'//

import './Settings/ResellerSettings.css'//
import ResellerSettingsCategories from './Settings/ResellerSettingsCategories'//
import ResellerSettingsAdminUsers from './Settings/ResellerSettingsAdminUsers'//
import ResellerSettingsDocumentTypes from './Settings/ResellerSettingsDocumentTypes'//
import ResellerSettingsVehicles from './Settings/ResellerSettingsVehicles'//
import ResellerTests from './Tests/ResellerTests'//

/*
TODO - [ ] Costruire una modale per mostrare i messaggi di errore o di warning
*/

const mapStateToProps = (state) => {
  return {
    state: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  }
}

class ResellerSettingsContainer extends Component {
  constructor (props) {
    super(props)    
  }

  render () {
    return (
      <React.Fragment>
        <div className="panel panel-default panel-border">
          <div className="panel-body">
            <div className="titolo-categoria">
              <h3 className="text-left sectionTitle">                    
                <FontAwesomeIcon size="1x" className="sectionIcons" icon={solidIcons.faGears} />&nbsp;&nbsp;IMPOSTAZIONI
              </h3>
            </div>
            <Switch>        
              <Route path="/reseller/settings/adminUsers"    component={ResellerSettingsAdminUsers} />
              <Route path="/reseller/settings/markTypes"     component={ResellerSettingsMarkTypes} />
              <Route path="/reseller/settings/areas"         component={ResellerSettingsAreas} />
              <Route path="/reseller/settings/documentTypes" component={ResellerSettingsDocumentTypes} />
              <Route path="/reseller/settings/vehicles"      component={ResellerSettingsVehicles} />
              <Route path="/reseller/settings/categories"    component={ResellerSettingsCategories} />
              <Route path="/reseller/settings"               component={ResellerSettings} />
              <Route path="/reseller/settings/tests"         component={ResellerTests} />

            </Switch>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerSettingsContainer)
