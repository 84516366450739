
// TYPES
export const types = {
    SAVE_USER_INFO: "REGISTRATION/SAVE_USER_INFO",
    SAVE_USER_INFO_AND_GO_TO_MARKS: "REGISTRATION/SAVE_USER_INFO_AND_GO_TO_MARKS",
    CLEAR: "REGISTRATION/CLEAR",
};

// INITIAL STATE
export const initialState = {
    userInfo: null,
    userInfoInitialized: false
};

// REDUCER
export default (state = initialState, action) => {
    switch (action.type) {
        case types.SAVE_USER_INFO:
            return {
                ...state,
                userInfo: action.payload,
                userInfoInitialized: true
            };

        case types.CLEAR:
            return initialState;

        default:
            return state
    }
}

// ACTIONS
export const actions = {

    saveUserInfo: (data) => {
        return {
            type: types.SAVE_USER_INFO,
            payload: data
        };
    },

    clear: () => {
        return {
            type: types.CLEAR
        }
    }
};