export const MESSAGE_TYPES = {
    TECHNICAL  : { value: "TECHNICAL", descr: "Tecnica" },
    ADMIN      : { value: "ADMIN",     descr: "Amministrativa" },
    GENERAL    : { value: "GENERAL",   descr: "Info Generali" }
}
  
export const TECNICAL_SUBJECTS = [
    { value: ""                   , type: ""             , descr: "Selezionare il soggetto della segnalazione ..." },  
    { value: "MARK_REQUEST_ERROR" , type: "ERROR_REPORT" , descr: "ERRORE durante la richiesta abbonamento" },  
    { value: "PAYMENT_ERROR"      , type: "ERROR_REPORT" , descr: "ERRORE effettuando un pagamento" },
    { value: "USER_DATA_ERROR"    , type: "ERROR_REPORT" , descr: "Dati del profilo ERRATI" },
    { value: "TECH_GENERAL_ERROR" , type: "ERROR_REPORT" , descr: "Altro... " },
]

export const ADMIN_SUBJECTS = [
    { value: ""                         , type: ""             , descr: "Selezionare il soggetto della richiesta o segnalazione ..." },
    { value: "INFO_REQUEST"             , type: "INFO_REQUEST" , descr: "Richiedi informazioni" },
    { value: "RECEIPT_DATA_ERROR"       , type: "ERROR_REPORT" , descr: "Errore sui dati di una ricevuta" },
    { value: "PAYMENT_DATA_ERROR"       , type: "ERROR_REPORT" , descr: "Problema sui dati di pagamento" },
    { value: "COMMERCIAL_GENERAL_ERROR" , type: "ERROR_REPORT" , descr: "Altro..." },
]

export const GENERAL_SUBJECTS = [
    { value: ""             ,  type: ""             , descr: "Selezionare il soggetto della richiesta ..." },  
    { value: "DOC_INFO" ,      type: "INFO_REQUEST" , descr: "Informazioni sulla documentazione richiesta" },
    { value: "MARKS_INFO" ,    type: "INFO_REQUEST" , descr: "Informazioni sulla validità dei permessi" },
    { value: "GENERAL_INFO" ,  type: "INFO_REQUEST" , descr: "Altro..." },
]


/**
   * --------------------------------------------------------------
   * @param {*} msgSubject 
   * @returns 
   */
export function getSubjectDescription(msgSubject) {
    var subjects = msgSubject.split(" ")

    if ( subjects != null && subjects.length > 1 ) {
      var messageType    = getMessageType(subjects[0])
      var subMessageType = getSubMessageType(subjects[0],subjects[1])

      if ( messageType != null &&  subMessageType != null  )
        return messageType.descr+": "+subMessageType.descr
      else 
        return msgSubject
      
    }
    else  
      return msgSubject
  }  

/**
 * --------------------------------------------------------------
 * @param {*} messageTypeValue 
 * @returns 
 */
export function getSubMessageTypeList(messageTypeValue) {
    if ( messageTypeValue === MESSAGE_TYPES.TECHNICAL.value) {
        return TECNICAL_SUBJECTS      
    }
    else if ( messageTypeValue === MESSAGE_TYPES.ADMIN.value ) {
        return ADMIN_SUBJECTS
    }
    else if ( messageTypeValue === MESSAGE_TYPES.GENERAL.value) {
        return GENERAL_SUBJECTS
    }

    return []
}

/**
 * --------------------------------------------------------------
 * @param {*} messageTypeValue 
 * @param {*} subMessageTypeValue 
 * @returns 
 */
export function getSubMessageType(messageTypeValue,subMessageTypeValue) {
    var subMessageType = getSubMessageTypeList(messageTypeValue).filter( item => item.value === subMessageTypeValue )

    if ( subMessageType !=  null && subMessageType.length > 0  )
        return subMessageType[0]

    return null
}

/**
 * --------------------------------------------------------------
 * @param {*} messageTypeValue 
 * @param {*} subMessageTypeValue 
 * @returns 
 */
export function getMessageType(messageTypeValue) {
    var messageType = MESSAGE_TYPES[messageTypeValue]
    //console.log("messageType",messageType)

    if ( messageType !=  null )
        return messageType

    return null
}
  