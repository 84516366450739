import React, {Component} from 'react'
import './BackOfficeUserScreen.css'
import ReactTable from 'react-table'
import moment from 'moment'
import { Oval } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { getGridHeight } from 'libs/utils'

moment.locale('it')

export default class BackOfficeUserDetailLogsTable extends Component {

  /**
   * ----------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)

    this.state = {
      maxHeight: 0,
      columns: [
        {
          Header: 'ID',
          accessor: 'id',
          filterable: false,
          sortable: false,
          width: 80,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },
        {
          Header: 'Utente',
          accessor: 'Operator',
          filterable: false,
          sortable: false,
          width: 150,
          Cell: (row) => {
            return (<div>{row.value.firstName + ' ' + row.value.lastName}</div>)
          }
        },
        {
          Header: 'Data',
          accessor: 'createdAt',
          filterable: false,
          sortable: false,
          width: 150,
          Cell: (row) => {
            return (<div>{moment(row.value).format('L LT')}</div>)
          }
        },
        {
          Header: 'Codice',
          accessor: 'message',
          filterable: false,
          sortable: false,
          width: 150,
          Cell: (row) => {
            return (<div><strong>{row.value}</strong></div>)
          }
        },
        {
          Header: 'Descrizione',
          accessor: 'description',
          filterable: false,
          sortable: false,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        }
      ]

    }

    this.handleResize  = this.handleResize.bind(this)
  }

  /**
   * ----------------------------------------------------------------
   */
   componentDidMount () {    
    window.addEventListener('resize', this.handleResize)
    this.setState({ maxHeight : getGridHeight() })
  }

  /**
   * ----------------------------------------------------------------
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  /**
   * ----------------------------------------------------------------
   */
  handleResize ( ) {        
    this.setState({ maxHeight : getGridHeight() })    
  }


  render () {
    const {
      columns,
      maxHeight
    } = this.state
    return (<div>
      <ReactTable
        loading={this.props.loading}
        noDataText={'Nessun Log Presente'}
        className="-highlight"
        data={this.props.logs ? this.props.logs : []}
        style={{maxHeight: maxHeight}}
        defaultPageSize={100}
        nextText={<FontAwesomeIcon  className="mainIconsNoAction"  size="2x" icon={solidIcons.faArrowRight} />}
        previousText={<FontAwesomeIcon  className="mainIconsNoAction"  size="2x" icon={solidIcons.faArrowLeft} />}
        loadingText={<Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />}
        rowsText="elementi"
        ofText="di"
        pageText="Pagina"
        columns={columns} />
    </div>)
  }
}
