import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actions as addressActions} from '../redux/modules/Address'
import {actions as authActions} from '../redux/modules/Auth'
import SostaOnlineApiService from '../services/SostaOnlineApiService'
import BackOfficeUserEditScreen from '../screens/BackOfficeUserEditScreen'
import { formatCityString, randomPassword, validateEmail } from 'libs/utils'
import { getComuni, getComuniWithoutCurrentCity } from 'libs/geo'


const fileDownload = require('js-file-download')

/**
 * ----------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    state: state.users,
    stateAuth: state.auth,
    addresses: state.address
  }
}

/**
 * ----------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    authActions:    bindActionCreators(authActions,   dispatch),
    addressActions: bindActionCreators(addressActions, dispatch)
  }
}



/**
 * ================================================================
 * 
 */
class BackOfficeUserEditContainer extends Component {

  // ----------------------------------------------------------------
  //
  //
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      error: null,
      userTypes: [],
      categories: [],
      userEditResult: {},
      userId: props.match.params.id,
      user: {},
      values: {},
      success: false,
      areas: [],
      selectedCategories: [],
      selectedParkingSpaceArea: null,
      cities: {},
      province: {},
      city: {},
      residenceDataForAllUsers: false
    }

    this.onGenerateUserCredentials     = this.onGenerateUserCredentials.bind(this)
    this.onCategoriesChange            = this.onCategoriesChange.bind(this)
    this.onParkingSpaceAddressSelected = this.onParkingSpaceAddressSelected.bind(this)
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  async componentDidMount () {
    this.setState({loading: true})

    var publicConfigs = await SostaOnlineApiService.fetchPublicConfigs()    
      .catch( error => {
        console.log("ERROR",error)
      }) 

    var residenceDataForAllUsers = false;

    if ( publicConfigs && publicConfigs.data != null  ) {
      this.setState({
        city: publicConfigs.data.city,
        residenceDataForAllUsers:  publicConfigs.data.residenceDataForAllUsers 
      })

      residenceDataForAllUsers = publicConfigs.data.residenceDataForAllUsers 
    }
    
    var cities = await SostaOnlineApiService.fetchGeoCities()
      .catch( error => {
        console.error("ERROR",error)
      })
        
    if ( cities != null && cities.data != null ) {                          
      this.setState({ cities: cities.data })
    }

    var province = await SostaOnlineApiService.fetchGeoProvince()
      .catch( error => {
        console.error("ERROR",error)
      })
        
    if ( province != null && province.data != null ) {                          
      this.setState({ province: province.data })
    }    

    await this.fetchUserCategories()
    this.props.addressActions.fetchAddresses()
    this.fetchUser(residenceDataForAllUsers)
    this.fetchAreas()

    this.setState({loading: false})
    
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  fetchUser (residenceDataForAllUsers=false) {
    this.setState({loading: true})
    SostaOnlineApiService.fetchUser(this.state.userId)
      .then(data => {

        let isResident     = data.data.categories.filter(item => item.resident     ).length > 0
        let isWorker       = data.data.categories.filter(item => item.worker       ).length > 0
        let isWorkerPerson = data.data.categories.filter(item => item.workerPerson ).length > 0

        let residenceArea         = null
        let workArea              = null
        let workAddress           = null
        let workAddressId         = null
        let companyLegalAddress   = null
        let companyLegalAddressId = null
        let residenceCity         = null
        let residenceAddress      = data.data.residenceAddress

        if (data.data.workArea) {
          workArea = data.data.workArea.id
        }

        if (data.data.residenceArea) {
          residenceArea = data.data.residenceArea.id
        }

        let parkingSpaceAddressId
        let parkingSpaceArea

        if (data.data.parkSpaceAddress) {
          parkingSpaceAddressId = data.data.parkSpaceAddress.id
          parkingSpaceArea = data.data.parkSpaceAddress.Area
        }        

        if ( isWorker || isWorkerPerson  ) {          
          let address = this.props.addresses.data.find(item => { return item.name === data.data.workAddress })
  
          if ( address ) {
            workAddress   = address.name
            workAddressId = address.id
          }
          else {
            workAddress = data.workAddress            
          }
        }

        if ( isWorker || isWorkerPerson  ) {          
          let address = this.props.addresses.data.find(item => { return item.name === data.data.companyLegalAddress })
  
          if ( address ) {
            companyLegalAddress   = address.name
            companyLegalAddressId = address.id
          }
          else {
            companyLegalAddress = data.companyLegalAddress            
          }
        }


        if ( isResident  ) {          
          let address = this.props.addresses.data.find(item => { return item.name === data.data.residenceAddress })
  
          if ( address )
            residenceAddress = address.id          
        }
        else if ( residenceDataForAllUsers ) {
          residenceAddress = data.data.residenceAddress
          residenceCity    = data.data.residenceCity
        }

        this.setState({
          user: data.data,
          loading: false,
          selectedParkingSpaceArea: parkingSpaceArea,
          values: {
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            email: data.data.email,
            phone: data.data.phone,
            fiscalCode: data.data.fiscalCode,
            userCategories: data.data.categories.map(item => {
              return {
                value: item.id,
                label: item.label.charAt(0).toUpperCase() + item.label.slice(1),
                worker: item.worker,
                workerPerson: item.workerPerson,
                resident: item.resident,
                domiciled: item.domiciled
              }
            }),
            residenceCity: residenceCity,
            residenceAddress: residenceAddress,
            residenceCivicNumber: data.data.residenceCivicNumber,
            residenceInside: data.data.residenceInside,
            residenceCap: data.data.residenceCap,
            secondaryResidenceAddress: data.data.secondaryResidenceAddress,
            secondaryResidenceCivicNumber: data.data.secondaryResidenceCivicNumber,
            secondaryResidenceInside: data.data.secondaryResidenceInside,
            secondaryResidenceCity: data.data.secondaryResidenceCity,
            secondaryResidenceCap: data.data.secondaryResidenceCap,
            birthDate: data.data.birthDate,
            birthPlace: data.data.birthPlace,
            sex: data.data.sex,
            companyName: data.data.companyName,
            companyLegalCity: data.data.companyLegalCity,
            companyLegalAddress: data.data.companyLegalAddress,     
            companyLegalAddressId: companyLegalAddressId,                   
            companyLegalCivicNumber: data.data.companyLegalCivicNumber,
            companyLegalInside: data.data.companyLegalInside,
            companyLegalCap: data.data.companyLegalCap,
            workCity: data.data.workCity,
            workCap: data.data.workCap,
            vatNumber: data.data.vatNumber,
            workAddress: workAddress,
            workAddressId: workAddressId,
            workCivicNumber: data.data.workCivicNumber,
            workInside: data.data.workInside,
            workAreaId: workArea,
            residenceAreaId: residenceArea,
            parkingSpaceAddress: parkingSpaceAddressId
          },
          selectedCategories: this.mapCategories(data.data.categories)
        })
      })
      .catch( error => {
        console.log("ERROR",error)
      })
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} items 
   * @returns 
   */
  mapCategories (items) {
    return items.map(item => {
      let children = null
      if (item.Children && item.Children.length > 0) {
        children = this.mapCategories(item.Children)
      }

      return {
        value: item.id,
        label: item.label.charAt(0).toUpperCase() + item.label.slice(1),
        children: children,
        resident: item.resident,
        worker: item.worker,
        workerPerson: item.workerPerson,
        parkingInDifferentPlace: item.parkingInDifferentPlace
      }
    })
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  fetchUserTypes () {
    this.setState({loading: true})
    SostaOnlineApiService.fetchUserTypes().then((data) => {
      this.setState({userTypes: data.data, loading: false})
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  fetchAreas () {
    SostaOnlineApiService.fetchAreas().then(data => {
      this.setState({areas: data.data})
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  async fetchUserCategories () {
    this.setState({loading: true})
    return SostaOnlineApiService.fetchUserCategories().then((data) => {
      this.setState({categories: this.mapCategories(data.data), loading: false})
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} data 
   */
  onSubmit (data) {    

    this.setState({loading: true, error: null, success: false})
    let isResident       = data.userCategories.filter(item => item.resident     ).length > 0
    let isWorker         = data.userCategories.filter(item => item.worker       ).length > 0
    let isWorkerPerson   = data.userCategories.filter(item => item.workerPerson ).length > 0

    let residenceCap                  = null
    let residenceCity                 = null
    let residenceCivicNumber          = null
    let residenceInside               = null
    let secondaryResidenceCity        = null
    let secondaryResidenceAddress     = null
    let secondaryResidenceCivicNumber = null
    let secondaryResidenceInside      = null
    let secondaryResidenceCap         = null
    let residenceAddress              = null
    let residenceAreaId               = null

    let workCity            = null
    let workCap             = null
    let workAddress         = null    
    let workAreaId          = null        
    let workCivicNumber     = null
    let workInside          = null

    let companyName             = null
    let vatNumber               = null
    let companyLegalAddress     = null
    let companyLegalCap         = null
    let companyLegalCity        = null
    let companyLegalCivicNumber = null
    let companyLegalInside      = null
    
    let sex                 = null
    let birthDate           = null
    let birthPlace          = null

    //console.log("DATA",data)
    
    // Aziende
    if ( isWorker ) {
      companyName             = data.companyName
      vatNumber               = data.vatNumber
      workCity                = formatCityString(this.state.city.longName , this.state.city.province )
      workCap                 = this.state.city.cap
      workCivicNumber         = data.workCivicNumber
      workInside              = data.workInside
      companyLegalCap         = data.companyLegalCap
      companyLegalCity        = data.companyLegalCity
      companyLegalCivicNumber = data.companyLegalCivicNumber
      companyLegalInside      = data.companyLegalInside

      // Indirizzo legale Azienda
      if ( data.companyLegalAddressId  ) {
        let address = this.props.addresses.data.find(item => { return item.id === data.companyLegalAddressId })

        if ( address != null ) {
          companyLegalAddress = address.name
        }
      }
      else {
        companyLegalAddress = data.companyLegalAddress
      }

      // indirizzo di lavoro aziendale
      if ( data.workAddressId  ) {
        let address = this.props.addresses.data.find(item => { return item.id === data.workAddressId })

        if ( address != null ) {
          workAddress = address.name          
        }
      }
      else if ( data.workAddress != null  ) {
        workAddress = data.workAddress
      }      

      workAreaId = data.workAreaId != null ? data.workAreaId : data.companyLegalAreaId
    }
    // Lavorratori dipendenti/titolari
    else if ( isWorkerPerson ) {
      workAreaId          = data.workAreaId
      workCivicNumber     = data.workCivicNumber
      workInside          = data.workInside
      companyName         = data.companyName
      vatNumber           = data.vatNumber
      workCity            = formatCityString(this.state.city.longName , this.state.city.province )
      workCap             = this.state.city.cap      

      if ( data.workAddressId ) {
        let address = this.props.addresses.data.find(item => { return item.id === data.workAddressId })

        if ( address ) {
          workAddress         = address.name          
        }
      }

    }
    
    // Residenti
    if ( isResident && data.residenceAddress ) {
      let address = this.props.addresses.data.find(item => { return item.id === data.residenceAddress })

      if ( address )
        residenceAddress = address.name

      residenceAreaId               = data.residenceAreaId
      residenceCap                  = this.state.city.cap 
      residenceCity                 = formatCityString(this.state.city.longName , this.state.city.province )
      residenceCivicNumber          = data.residenceCivicNumber
      residenceInside               = data.residenceInside
      secondaryResidenceCity        = data.secondaryResidenceCity
      secondaryResidenceAddress     = data.secondaryResidenceAddress
      secondaryResidenceCivicNumber = data.secondaryResidenceCivicNumber
      secondaryResidenceInside      = data.secondaryResidenceInside
      secondaryResidenceCap         = data.secondaryResidenceCap
      sex                           = data.sex
      birthDate                     = data.birthDate
      birthPlace                    = data.birthPlace
    }
    else if ( this.state.residenceDataForAllUsers ) {
      residenceAddress          = data.residenceAddress
      residenceCap              = data.residenceCap
      residenceCity             = data.residenceCity
      residenceCivicNumber      = data.residenceCivicNumber
      residenceInside           = data.residenceInside  
    }

    let obj = {
      id: this.state.userId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      fiscalCode: data.fiscalCode,

      residenceCity: residenceCity,
      residenceAddress: residenceAddress,
      residenceCivicNumber: residenceCivicNumber,
      residenceInside: residenceInside,
      residenceCap: residenceCap,
      residenceAreaId: residenceAreaId,
      secondaryResidenceCity: secondaryResidenceCity,
      secondaryResidenceAddress: secondaryResidenceAddress,
      secondaryResidenceCivicNumber: secondaryResidenceCivicNumber,
      secondaryResidenceInside: secondaryResidenceInside,
      secondaryResidenceCap: secondaryResidenceCap,

      sex:             sex,
      birthDate:       birthDate,
      birthPlace:      birthPlace,

      workCity:            workCity,
      workAddress:         workAddress,
      workCivicNumber:     workCivicNumber,
      workInside:          workInside,
      workCap:             workCap,
      workAreaId:          workAreaId,

      vatNumber:               vatNumber,
      companyName:             companyName,
      companyLegalAddress:     companyLegalAddress,
      companyLegalCivicNumber: companyLegalCivicNumber,
      companyLegalInside:      companyLegalInside,
      companyLegalCap:         companyLegalCap,
      companyLegalCity:        companyLegalCity,

      userCategories: this.state.selectedCategories.map(item => item.value),
            
      parkSpaceAddressId: data.parkingSpaceAddress
    }

    if ( !validateEmail(data.email) )  {      
      this.setState({
        error: 'Email specificata non valida',
        loading: false,
        success: false
      })
    }

    if ( ! this.state.selectedCategories || this.state.selectedCategories.length <= 0 ) {
      this.setState({
        error: 'Selezionare categoria utente',
        loading: false,
        success: false
      })
    }

    else {

      SostaOnlineApiService.updateUser(obj)
        .then(result => {
          this.setState({error: null, loading: false, success: true, userCreateResult: result})
        })
        .catch(err => {
          
          console.log("ERROR",err)
          
          this.setState({error: 'Errore nella modifica utente', success: false, loading: false})
        })

    }
    
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  onGenerateUserCredentials () {
    SostaOnlineApiService.generateUserCredentials(this.state.userId, randomPassword(8)).then(result => {
      this.setState({error: null, loading: false, success: true, userCreateResult: result})

      SostaOnlineApiService.downloadUserCredentials(this.state.userId).then(data => {
        fileDownload(data, 'credenziali-utente-' + this.state.userId + '.pdf', 'application/pdf')
      })
      .catch( error => {
        console.log("ERROR",error)
      })

    })
    .catch(err => {
      console.log("ERROR",err)
      this.setState({error: 'Errore nella modifica utente', success: false, loading: false})
    })
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  onDeleteUser () {

    SostaOnlineApiService.deleteUser(this.state.userId)
      .then(data => {
        this.props.history.push('/backoffice/users')
      })
      .catch(err => {
        console.log("ERROR",err)
        this.setState({error: 'Errore nella modifica utente', success: false, loading: false})
      })

  }

  /**
   * ----------------------------------------------------------------
   * @param {*} selectedNodes 
   */
  onCategoriesChange (selectedNodes) {
    this.setState({
      selectedCategories: selectedNodes.map(item => ({
        label: item.label,
        value: item.value,
        resident: item.resident,
        worker: item.worker,
        workerPerson: item.workerPerson,
        parkingInDifferentPlace: item.parkingInDifferentPlace
      }))
    })
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} addressId 
   * @returns 
   */
  onParkingSpaceAddressSelected (addressId) {
    if (!addressId) {
      this.setState({selectedParkingSpaceArea: null})
      return
    }

    let address = this.props.addresses.data.filter(item => {
      return item.id === addressId
    })
    this.setState({selectedParkingSpaceArea: address[0].Area})
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  render () {

    const {      
      cities,
      city,
      values,
      categories,
      user,
      loading,
      error,
      success,
      result,
      areas,
      selectedCategories,
      selectedParkingSpaceArea,
      residenceDataForAllUsers
    } = this.state

    const {
      addresses
    } = this.props

    //console.log("VALUES",values)

    return (
      <BackOfficeUserEditScreen
        city={city}
        addresses={addresses}
        initialValues={values}
        categoryOptions={categories}
        user={user}
        loading={loading}
        errorMessage={error}
        success={success}
        result={result}
        comuni={getComuni(cities)}
        comuniWithoutCurrentCity={getComuniWithoutCurrentCity(cities,city)}
        areas={areas}
        onCategoriesChange={this.onCategoriesChange}
        selectedCategories={selectedCategories}
        onGenerateUserCredentials={this.onGenerateUserCredentials}
        onSubmit={this.onSubmit.bind(this)}
        selectedParkingSpaceArea={selectedParkingSpaceArea}
        onParkingSpaceAddressSelected={this.onParkingSpaceAddressSelected}
        onDeleteUser={this.onDeleteUser.bind(this)}
        residenceDataForAllUsers={residenceDataForAllUsers} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeUserEditContainer)
