import {applyMiddleware, combineReducers, compose, legacy_createStore} from 'redux'
import {autoRehydrate, persistStore} from 'redux-persist'
import React, { Component }  from 'react'
import fetch                 from './redux/middlewares/fetch'
import redirect              from './redux/middlewares/redirect'
import SostaOnlineApiService from './services/SostaOnlineApiService'
import * as reducers         from './redux/modules'
import LoadingComponent      from './components/LoadingComponent'
import MainContainer         from 'screens/MainContainer'
import {Provider}            from 'react-redux'
import thunk                 from 'redux-thunk'

import 'react-table/react-table.css'
import './App.css'

import moment from 'moment'
import 'moment/locale/it'


const reducer = combineReducers(reducers)
const store   = legacy_createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  compose(
    applyMiddleware(fetch, redirect, thunk),
    autoRehydrate()
  )
)

export default class App extends Component {

  constructor (props) {
    super(props)
    this.state = {
      rehydrated: false,
      initialized: false
    }
  }

  initializePersistor () {
    this.persistor = persistStore(
      store, 
      {
        blacklist: [
          'form',
          'markRules',
          'markTypes',
          'address',
          'area'
        ]
      }, 
      () => {

        this.setState({rehydrated: true})

        if (!store.getState().auth.token) {
          this.setState({initialized: true})
          return
        }
          
        SostaOnlineApiService.setToken(store.getState().auth.token)
        this.setState({initialized: true})
      }
    )
  }  

  componentDidMount () {
    document.title = "Piano Sosta Comune di "+process.env.REACT_APP_CITYL
    moment.locale('it')
    this.initializePersistor()    
  }

  render () {
    if (!this.state.initialized || !this.state.rehydrated) {
      return (<LoadingComponent />)
    }
    return (<Provider store={store}><MainContainer/></Provider>)
  }
}

