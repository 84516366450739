import {actions} from '../modules/Auth'
import {stopSubmit} from 'redux-form'
import SostaOnlineApiService from '../../services/SostaOnlineApiService'

const HOST = SostaOnlineApiService.HOST;

// ----------------------------------------------------------------
//
//
export const methods = {
    GET_SPID_TOKEN:      "getSpidToken",
    SPID_LOGIN:          "spidLogin",
    LOGIN:               "login",
    LOGOUT:              "logout",
    CLEAR_SPID_TOKEN:    "clearSpidToken",
    CLEAR_LOGIN_ERROR:   "cleartLoginError",
    SET_SPID_ENABLE:     "setSpidEnable"
};

// ----------------------------------------------------------------
//
//
const getHeaders = (
    options = {
        contentType: 'application/json'
    }) => {
    let opts = {
        contentType: options.contentType || 'application/json; charset=UTF-8',
        authorization: options.authorization || null
    };
    let headers = {};
    if(opts.contentType) {
        headers['Content-Type'] = opts.contentType;
    }
    if(opts.authorization) {
        headers['Authorization'] = "Bearer " + opts.authorization;
    }
    return headers;
};

// ----------------------------------------------------------------
//
//
const baseFetch = (url, method, body = null, headers = {}) => {
    return fetch(url , {
        method: method,
        headers: getHeaders(headers),
        body: body
    }).then((response) => {
        if(response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            throw new Error(response);
            //return response.json().then(Promise.reject.bind(Promise));
        }
    });
};

// ----------------------------------------------------------------
//
//
export default store => next => action => {

    if(!action.$fetch && !action.$api) {
        return next(action);
    }

    next(action);

    if(action.$fetch) {
        // fetch REQUEST
        next({
            type: action.type + "/REQUEST",
            payload: action.payload,
            data: action.$fetch
        });

        baseFetch(HOST + action.$fetch.url, action.$fetch.method, action.$fetch.body, {
            contentType: action.$fetch.contentType ? action.$fetch.contentType : 'application/json',
            authorization: store.getState().auth.token
        }).then(data => {
            return next({
                type: action.type + "/SUCCESS",
                payload: action.payload,
                data: data
            });
        }).catch(err => {
            err.json().then(data => next({
                type: action.type + "/FAILURE",
                data: {
                    body: data,
                    status: err.status
                },
                payload: action.payload
            }));
        });
    } else {
        // api REQUESTS
        next({
            type: action.type + "/REQUEST",
            data: action.$api
        });

        switch (action.$api.method) {

            // ----------------------------------------------------------------
            // verifica se spid è abilitato
            //
            case methods.SET_SPID_ENABLE:

                SostaOnlineApiService.isSpidEnabled().then( res => {
                    
                    return next({
                        type: action.type,
                        data: res.data
                    });

                  }).catch( err => {
                    return next({
                        type: action.type,
                        data: null
                    });
                  })

                break;

            // ----------------------------------------------------------------
            // Recupera il token di sessione per spid
            //
            case methods.GET_SPID_TOKEN:
                SostaOnlineApiService.getSpidToken().then( data => {
                    
                    store.dispatch(stopSubmit('login', {}));

                    if ( data != null && data.data != null && data.data.data != null )  {
                       
                        var token = data.data.data

                        SostaOnlineApiService.getSpidIdp().then( idpData => {
                            var url = idpData.data.idp+'/Profile?SpidToken='+token                            
                            document.getElementById('linkSPIDSTART').href = url;
                            document.getElementById('linkSPIDSTART').click();
                            
                        }).catch( err => {
                            console.log('Spid Err',err)
                            store.dispatch(stopSubmit('login', {_error: "SPID"}));
                            return next({
                                type: action.type,
                                data: err
                            });
                        })
                        
                    }
                    else {
                        console.log('SpidToken not found')
                        store.dispatch(stopSubmit('login', {_error: "SPID"}));
                        return next({
                            type: action.type,
                            data: 'SpidToken not found'
                        });
                    }

                    return next({
                        type: action.type,
                        data: data, 
                    })

                }).catch( err => {
                    console.log("ERR",err)
                    store.dispatch(stopSubmit('login', {_error: "SPID"}));

                    return next({
                        type: action.type,
                        data: err
                    });

                });

                break;
            
            // ----------------------------------------------------------------
            //
            //
            case methods.CLEAR_LOGIN_ERROR:
                store.dispatch(stopSubmit('login', { _error: "NONE" }));
                return next({
                    type: action.type
                })
                


            // ----------------------------------------------------------------
            //
            //
            case methods.SPID_LOGIN:

                var spidToken = action.$api.body.spidToken

                SostaOnlineApiService.spidLogin(spidToken).then(data => {

                    //console.log("LOGIN DATA RESULT",data)
                    var user = data.data.user;

                    if ( user!= null ) {

                        if ( user.spidProfile ) {

                            SostaOnlineApiService.setToken(data.data.token);

                            store.dispatch(stopSubmit('login', {}));
                            next({
                                type: action.type + "/SUCCESS",
                                data: data,
                            });

                            
                            store.dispatch({
                                type: "FETCH_MIDDLEWARE/LOGIN_SUCCESS_REDIRECT",
                                $redirect: {
                                    location: '/'
                                }
                            });

                            return next({
                                type: "FETCH_MIDDLEWARE/LOGIN_SUCCESS",
                            });
                        }
                        else {
                            
                            SostaOnlineApiService.requestEnableSpidProfile(user.id).then(data => {

                                store.dispatch(stopSubmit('login', {}));

                                store.dispatch({
                                    type: "FETCH_MIDDLEWARE/REGISTRATION_REDIRECT",
                                    $redirect: {
                                        location: '/register/requestEnableProfile'
                                    }
                                });   
        
                                return next({
                                    type: action.type + "/REGISTRATION_REDIRECT",
                                    data: data,
                                });

                            }).catch(err => {
                                console.log("ERRO",err)
                                store.dispatch(stopSubmit('login', {_error: "SPID"}));
                            })
                        }
                    }
                    else {
                        console.log("REDIRECT /register/choose-category")

                        store.dispatch({
                            type: "FETCH_MIDDLEWARE/REGISTRATION_REDIRECT",
                            $redirect: {
                                location: '/register/choose-category'
                            }
                        });   

                        return next({
                            type: action.type + "/REGISTRATION_REDIRECT",
                            data: data,
                        });
                    }
                    
                }).catch(err => {
                    store.dispatch(stopSubmit('login', {_error: 'SPID'}));
                    return next({
                        type: action.type + "/FAILURE",
                        data: err
                    });
                });
                break;

            // ----------------------------------------------------------------
            //
            //
            case methods.LOGIN:                
                baseFetch(HOST + '/api/v1/auth/login', "POST", JSON.stringify(action.$api.body)).then(data => {

                    SostaOnlineApiService.setToken(data.data.token);

                    store.dispatch(stopSubmit('login', {}));

                    next({
                        type: action.type + "/SUCCESS",
                        data: data,
                    });
                    
                    store.dispatch({
                        type: "FETCH_MIDDLEWARE/LOGIN_SUCCESS_REDIRECT",
                        $redirect: {
                            location: '/'
                        }
                    });

                    return next({
                        type: "FETCH_MIDDLEWARE/LOGIN_SUCCESS",
                    });

                })
                .catch(err => {
                    store.dispatch(stopSubmit('login', {_error: 'LOGIN'}));
                    return next({
                        type: action.type + "/FAILURE",
                        data: err
                    });
                });
                break;

            // ----------------------------------------------------------------
            //
            //
            case methods.LOGOUT:
                SostaOnlineApiService.setToken(null)                
                SostaOnlineApiService.setSpidToken(null)

                next(action);
                store.dispatch({
                    type: "FETCH_MIDDLEWARE/LOGOUT_SUCCESS_REDIRECT",
                    $redirect: {
                        location: '/'
                    }
                });

                return next({
                    type: "FETCH_MIDDLEWARE/LOGOUT_SUCCESS",
                });


            // ----------------------------------------------------------------
            //
            //
            default:
                break;

        }
    }
};