import React, { Component } from 'react'
import './BackOfficeNavMenuComponent.css'
import { Nav, NavDropdown, Navbar, MenuItem } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { BlockMapBuilder } from 'draft-js'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'


const mapStateToProps = (state) => {
    return {
        stateReport: state.report
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

class BackOfficeNavMenuComponent extends Component {


    getBadgeClass(playBadge) {
        if (playBadge)
            return "badge badge-list badgeActive"
        else
            return "badge badge-list badgeInactive"
    }

    render() {

        if (this.props) {
            const {
                stateReport,
                user,
                onMarksWaitingClick,
                onVehiclesWaitingClick,
                onNewMessagesClick,
                onResellerWaitingClick,
                onLogoutClick,
                onPasswordChangeClick,
                settingsMessages,
                resellers
            } = this.props


            var playMarksWaiting = (stateReport.waitingMarks && stateReport.waitingMarks > 0)
            var playMessagesNew = (stateReport.newMessages && stateReport.newMessages > 0)
            var playVehiclesWaiting = (stateReport.waitingVehicles && stateReport.waitingVehicles > 0)
            var playResellersWaiting = (resellers ? Object.keys(resellers).length : 0)
            var conto = stateReport.waitingMarks + stateReport.newMessages + stateReport.waitingVehicles
            if ( user.role == 'admin' ) { conto = (conto + playResellersWaiting) }
            return (
                <Navbar className="nav-user">
                    <Nav className="pull-right text-right row" style={{ margin: 0, padding: 0 }}>
                        <NavDropdown
                            id="DropDown-Permessi"
                            style={{ marginRight: "10px" }}
                            className="backoffice-dropdown"
                            title={
                                <React.Fragment>
                                    <FontAwesomeIcon size="1x" icon={solidIcons.faBell} className="badgeMain" />
                                    &nbsp;&nbsp;Notifiche
                                    <span className="badge badge-menu" style={{ marginLeft: 5 }}>{conto}</span>
                                </React.Fragment>
                            }>
                            <MenuItem onClick={() => { onMarksWaitingClick() }} style={{ background: "White" }}>
                                <span className={this.getBadgeClass(playMarksWaiting)}>
                                    {stateReport.waitingMarks ? stateReport.waitingMarks : 0}
                                </span>
                                <FontAwesomeIcon size="1x" icon={solidIcons.faWallet} />&nbsp;&nbsp;<strong>Permessi</strong> in attesa di appr.
                            </MenuItem>
                            <MenuItem onClick={() => { onVehiclesWaitingClick() }} style={{ background: "White" }}>
                                <span className={this.getBadgeClass(playVehiclesWaiting)}>
                                    {stateReport.waitingVehicles ? stateReport.waitingVehicles : 0}
                                </span>
                                <FontAwesomeIcon size="1x" icon={solidIcons.faCar} />&nbsp;&nbsp;<strong>Veicoli</strong> in attesa di appr.
                            </MenuItem>
                            {settingsMessages.enable &&
                                <MenuItem onClick={() => { onNewMessagesClick() }} style={{ background: "White" }}>
                                    <span className={this.getBadgeClass(playMessagesNew)}>
                                        {stateReport.newMessages ? stateReport.newMessages : 0}
                                    </span>
                                    <FontAwesomeIcon size="1x" icon={solidIcons.faEnvelope} />&nbsp;&nbsp;<strong>Nuovi Messaggi</strong>
                                </MenuItem>
                            }
                            {/* qui ci andranno le azioni per i nuovi rivenditori da approvare */}
                            {user.role == 'admin' &&
                                <MenuItem onClick={() => { onResellerWaitingClick() }} style={{ background: "White" }}>
                                    <span className={this.getBadgeClass(playResellersWaiting)}>
                                        {resellers ? Object.keys(resellers).length : 0}
                                    </span>
                                    <FontAwesomeIcon size="1x" icon={solidIcons.faUserTie} />&nbsp;&nbsp;<strong>Nuovi Rivenditori</strong>
                                </MenuItem>
                            }
                        </NavDropdown>

                        <NavDropdown
                            id="DropDown-Logout"
                            style={{ marginRight: "5em" }}
                            className="backoffice-dropdown"
                            title={
                                <React.Fragment>
                                    <FontAwesomeIcon size="1x" icon={solidIcons.faUser} className="badgeMain" />
                                    &nbsp;&nbsp;{user.firstName + " " + user.lastName}
                                </React.Fragment>
                            }
                        >

                            {/* <MenuItem><NavLink to={'/backoffice/operator-profile'}><FontAwesomeIcon  size="1x"  icon={solidIcons.faUser} />&nbsp;&nbsp;Profilo</NavLink></MenuItem> */}
                            <MenuItem onClick={onPasswordChangeClick} style={{ background: "White" }} ><FontAwesomeIcon size="1x" icon={solidIcons.faKey} />&nbsp;&nbsp;Cambio Password</MenuItem>
                            <MenuItem onClick={onLogoutClick} style={{ background: "White" }} ><FontAwesomeIcon size="1x" icon={solidIcons.faRightFromBracket} />&nbsp;&nbsp;Logout</MenuItem>
                        </NavDropdown>

                    </Nav>
                </Navbar>

            );

        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'BackOfficeNavMenuComponent' })(BackOfficeNavMenuComponent))