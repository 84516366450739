import React, {Component}                from 'react'
import PropTypes                         from 'prop-types'
import {bindActionCreators}              from 'redux'
import {connect}                         from 'react-redux'
import { Route, Switch, Redirect }       from 'react-router-dom'

import {actions as categoriesActions}    from '../../../redux/modules/Categories'
import RegistrationUserInfo              from './UserInfo/RegistrationUserInfo'
//import Breadcrumbs                       from '../../../components/BreadCrumbs/Breadcrumbs'
import Breadcrumbs                        from '../../../components/Reseller/BreadCrumbs/Breadcrumbs'

import './RegistrationContainerReseller.css'

import * as authActions                  from '../../../actions/auth'
import RegistrationCompleted             from './RegistrationCompleted/RegistrationCompleted'
import EnableSpidProfile                 from './EnableSpidProfile/EnableSpidProfile'
import RequestEnableSpidProfile          from './RequestEnableSpidProfile/RequestEnableSpidProfile'
import RegistrationCategoriesChooser     from './RegistrationCategoriesChooser'
import CategoryNameBreacrumb             from '../../../components/Reseller/BreadCrumbs/CategoryNameBreacrumb'
import { getWindowHeight } from 'libs/utils'

// ----------------------------------------------------------------
//
//
const mapStateToProps = (state) => {
  return {
    state: state.auth,
    categories: state.categories,
  }
}

// ----------------------------------------------------------------
//
//
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(authActions, dispatch),
    categoriesActions: bindActionCreators(categoriesActions, dispatch)
  }
}


// ================================================================
//
//
class ResellerRegistrationContainer extends Component {
  static propTypes = {
    match: PropTypes.object
  }

  // ----------------------------------------------------------------
  //
  //
  constructor(props) {
    super(props)

    this.state = {
      minHeight: 0
    }

    this.handleResize = this.handleResize.bind(this)
  }

  // ----------------------------------------------------------------
  //
  //
  componentDidMount () {
    this.props.categoriesActions.fetchCategories()
    window.addEventListener('resize', this.handleResize)
    this.setState({ minHeight : getWindowHeight()  })
  }

  // ----------------------------------------------------------------
  //
  //
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  // ----------------------------------------------------------------
  //
  //
  handleResize ( ) {        
    this.setState({ "minHeight" : getWindowHeight() })    
  }

  // ----------------------------------------------------------------
  //
  //
  render () {
    const { match } = this.props
    const { minHeight} = this.state

    console.log('MatchUrl:',match.url)
    if (this.props.categories.loading) {
      return (<div>Caricamento in Corso...</div>)
    }

    return (
      <div id="RegistrationContainer" style={{ minHeight: minHeight }}>
        <div className="row">
          <div
            className="col-md-8 col-md-push-2"
            style={{paddingTop: 10, paddingBottom: 10, margin: '0 auto', textAlign: 'center'}}>
              <Breadcrumbs routes={{
              '/': 'Home',
              '/register-reseller': 'Registrazione',
              '/register-reseller/choose-category': 'Seleziona Categoria',
              '/register-reseller/choose-category/:id': ( { id } ) => <CategoryNameBreacrumb id={parseInt(id)} />,
              '/register-reseller/user-info': 'Informazioni Utente',
              '/register-reseller/user-info/:id': ( { id } ) => <CategoryNameBreacrumb id={parseInt(id)} />,
              '/register-reseller/completed' : 'Registrazione Completata'
            }} />
            {/*<Breadcrumbs routes={{
              '/': 'Home',
              '/register': 'Registrazione',
              '/register/choose-category': 'Seleziona Categoria',
              '/register/choose-category/:id': ( { id } ) => <CategoryNameBreacrumb id={parseInt(id)} />,
              '/register/user-info': 'Informazioni Utente',
              '/register/user-info/:id': ( { id } ) => <CategoryNameBreacrumb id={parseInt(id)} />
            }} />*/}
          </div>
        </div>

        <Switch>
          <Route    path="/register-reseller/requestEnableProfile"       component={RequestEnableSpidProfile} />
          <Route    path="/register-reseller/enableSpidProfile"          component={EnableSpidProfile} />
          <Route    path="/register-reseller/completed"                  component={RegistrationCompleted} />          
          <Route    path={match.url + '/user-info/:categoryId'} component={RegistrationUserInfo} />
          <Route    path={match.url + '/choose-category/:id'}   component={RegistrationCategoriesChooser} />
          <Route    path={match.url + '/choose-category'}       component={RegistrationCategoriesChooser} />
          <Redirect to={match.url + '/choose-category'} />
        </Switch>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerRegistrationContainer)
