import React, {Component} from 'react'
import { connect } from 'react-redux'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'//
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Oval } from 'react-loader-spinner'
import {actions as authActions} from '../../../redux/modules/Auth'//
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import { MARK_MODIFY_CODE, MARK_MODIFY_PRICE, MARK_RENEW_NOTIFY_DAYS } from '../../../libs/settings'
import { getValidPlateNumberContent } from 'libs/vehicles'
import ResellerSettingsMenuComponent from 'components/Reseller/Navmenu/ResellerSettingsMenuComponent'//
import { RESELLER } from 'libs/roles'
import packageJson from '../../../../package.json'
import { Button } from 'react-bootstrap'
const thisRoute = 'reseller'


//--------------------------------------------------------------------------
const mapStateToProps = (state) => {
  return {
    resellerValues: state.form.resellerSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

//--------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

//--------------------------------------------------------------------------
class ResellerTests extends Component {

  //--------------------------------------------------------------------------
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      submitting: false,
      error: false,
      errorMsg: null,      
    }
    
    
  }

  //--------------------------------------------------------------------------
  async componentDidMount () {
    // this.setState({loading: true})
  }

  //--------------------------------------------------------------------------
  async runTest1() {
    SostaOnlineApiService.runTest1(thisRoute)
    .then(data => {
      console.log("RESULT",data.data)
    })
    .catch( error => {
      console.error("ERROR",error)
    })
    
  }
  
  //--------------------------------------------------------------------------
  render () {        

    const {      
      loading,
      error,
      errorMsg,
      submitting,
      success
    } = this.state


    if (loading) {
      return (
        <React.Fragment>
        <h3><strong>Caricamento in corso...</strong></h3>
        <div className="row" style={{padding: "2em", height: "40em"}}>
          <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />                  
        </div>
        </React.Fragment>
      )
    } 
    if ( error )    {
      return (<div className="text-primary">{errorMsg}</div>)
    }

    if ( this.props.auth.user.role != RESELLER ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    return (
      <React.Fragment>
      <ResellerSettingsMenuComponent {...this.props}  />
      
        <div className="row">
          <div className="pull-left" style={{ marginLeft: "1em" , marginRight: "1em" , marginTop: "1em", height: "2em" }}>
            
          </div>
        </div>        
        <div className="box" style={{textAlign: 'left'}}>                              

          <div className="form-group row">
            <label className="col-sm-8 col-form-label">Test1</label>
            <div className="col-sm-2">                
                <button
                  className="mainBtn"
                  onClick={(e) => { this.runTest1() }}
                  name="test1"                                                      
                >
                  <FontAwesomeIcon icon={regularIcons.faCheckCircle} />&nbsp;&nbsp;TEST
                </button>
            </div>
            <div className="col-sm-2">
               
            </div>
          </div>
        
          {submitting && <div className="text-primary">
            Salvataggio in corso
          </div>}

          {error && <div className="text-danger">
            Si sono verificati degli errori
          </div>}

          {success && <div className="text-success">
            Salvataggio completato correttamente
          </div>}
          
        </div>
      
      </React.Fragment>    
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerTests)
