import React, { Component } from 'react'
import PropTypes from 'prop-types'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import RequestConfirmModal from 'components/General/RequestConfirmModal';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import {actions as authActions} from 'redux/modules/Auth'
import { ADMIN } from 'libs/roles'


moment.locale('it')

/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    backofficeValues: state.form.backofficeSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}


class BackofficeEditVehiclePowerSupply extends Component {

  static propTypes = {    
    vehiclePowerSupplyId:  PropTypes.number,    
    onSave:                PropTypes.func.isRequired,
    onDelete:              PropTypes.func.isRequired,
    onCancelEdit:          PropTypes.func.isRequired,
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      vehiclePowerSupply: this.getNullVehiclePowerSupply(),
      checkErrors: null,
      sendErrors: null,
      displayColorPicker: false,
      showConfirmDelete: false,      
         
    }

    this.save                      = this.save.bind(this)
    this.checkFields               = this.checkFields.bind(this)        
    this.getNullVehiclePowerSupply = this.getNullVehiclePowerSupply.bind(this)
    this.delete                    = this.delete.bind(this)
       
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getNullVehiclePowerSupply() {
    var nullVehiclePowerSupply = { id: null, name: '', hybrid: false, electric: false }

    return nullVehiclePowerSupply    
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
      vehiclePowerSupply: this.getNullVehiclePowerSupply(),
      checkErrors: null,
      sendErrors: null,      
      displayColorPicker: false,
      showConfirmDelete: false
    })    

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    this.stateInit()
    this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
   componentDidUpdate(prevProps) {       

    if ( prevProps.vehiclePowerSupplyId != this.props.vehiclePowerSupplyId  ) {
      this.stateInit()
      this.fetchData()
    }
        
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchData() {
    
    if ( this.props.vehiclePowerSupplyId != null ) {
      var vehiclePowerSupply = await SostaOnlineApiService.fetchVehiclePowerSupply(this.props.vehiclePowerSupplyId)
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: "Errore nella richiesta dati" })
        this.setState({vehiclePowerSupply: this.getNullVehiclePowerSupply()})
      })

      if ( vehiclePowerSupply != null && vehiclePowerSupply.data != null  ) {        
        this.setState({vehiclePowerSupply: vehiclePowerSupply.data })
      }
      else {
        this.setState({vehiclePowerSupply: this.getNullVehiclePowerSupply()})
      }
    }
    else {
      this.setState({vehiclePowerSupply: this.getNullVehiclePowerSupply()})
    }

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []   

    if ( this.state.vehiclePowerSupply.name == null || this.state.vehiclePowerSupply.name.trim().length <= 0 ) {
      errors.push("Nome non specificato")
    }

    if ( this.state.vehiclePowerSupply.electric == null ) {
      errors.push("Elettrico errato")
    }

    if ( this.state.vehiclePowerSupply.hybrid == null ) {
      errors.push("Ibrido errato")
    }

    
    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  save() {
    
    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }    

    if ( this.state.vehiclePowerSupply.id == null  ) {

      SostaOnlineApiService.createVehiclePowerSupply(this.state.vehiclePowerSupply)
      .then( result => {    
        
        var vehiclePowerSupplyId  = null

        if ( result.data != null && result.data.id != null )
          vehiclePowerSupplyId =  result.data.id
        
        if ( this.props.onSave != null ) {
          this.props.onSave(vehiclePowerSupplyId)
        }           
  
      })
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
      })

    }
    else {
      SostaOnlineApiService.updateVehiclePowerSupply(this.state.vehiclePowerSupply)
      .then( result => {      
        
        if ( this.props.onSave != null ) {
          this.props.onSave(this.props.vehiclePowerSupplyId)
        }        
  
      })
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
      })

    }

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async delete() {    

    if ( this.props.vehiclePowerSupplyId != null  ) {

      await SostaOnlineApiService.deleteVehiclePowerSupply(this.props.vehiclePowerSupplyId)
        .then( result => {      
          
          if ( this.props.onDelete != null ) {
            this.props.onDelete()
          }                    

        })
        .catch( error => {
          console.log("ERROR",error)

          if ( error.error != null ) {
            this.setState({ sendErrors: error.error })
          }
          else {
            this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
          }
          
        })

      this.setState({ showConfirmDelete: false})

    }

  }


  /**
   * --------------------------------------------------------------
   * 
   */
  onCancelEdit() {
    this.stateInit()
    this.fetchData()

    if ( this.props.onCancelEdit != null )
      this.props.onCancelEdit()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} name 
   */
  setName(name) {
    var vehiclePowerSupply = this.state.vehiclePowerSupply
    vehiclePowerSupply.name=name
    this.setState({vehiclePowerSupply: vehiclePowerSupply})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} check 
   */
  checkElectric(check) {
    var vehiclePowerSupply = this.state.vehiclePowerSupply
    vehiclePowerSupply.electric=check
    this.setState({vehiclePowerSupply: vehiclePowerSupply})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} check 
   */
  checkHybrid(check) {
    var vehiclePowerSupply = this.state.vehiclePowerSupply
    vehiclePowerSupply.hybrid=check
    this.setState({vehiclePowerSupply: vehiclePowerSupply})
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {        
      vehiclePowerSupplyId 
    } = this.props

    const {
      sendErrors,
      checkErrors,
      vehiclePowerSupply,
      showConfirmDelete
    } = this.state

    if ( this.props.auth.user.role != ADMIN ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    return (
      <React.Fragment>
              
        { vehiclePowerSupply  == null &&
        <div className="col-md-12">
          <div className="row">          
            <div className="text-center" style={{ margin: "1em" }}>
              <div className="row"><h2>Caricamento in corso...</h2></div>
              <div className="row" style={{padding: "2em", height: "40em"}}>
                <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
              </div>
            </div>
          </div>          
        </div>
        }

        { vehiclePowerSupply != null &&
        <div className="col-md-12">
          <div className="app-modal-body">                                  
            
            <React.Fragment>

              { vehiclePowerSupply.id != null &&
              <div className="row" style={{ marginBottom: "1em" }}>
                  <div className="col-xs-3">
                    <h5 style={{ marginTop: 5 , marginBottom: 5, verticalAlign: "top" }}><strong>Id:</strong></h5>
                  </div>
                  
                  <div className="col-xs-9">
                    <h5 style={{ marginTop: 5 , marginBottom: 5, marginLeft: 15, verticalAlign: "top" }}><strong>{vehiclePowerSupply.id}</strong></h5>
                  </div>                                
              </div>
              }
              
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-3">
                  <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Nome:</strong></h5>
                </div>
                
                <div className="col-xs-9">
                  <input
                    name="name" 
                    className="form-control" 
                    type="text" 
                    onChange={ e => {  this.setName(e.target.value) }}
                    value={vehiclePowerSupply.name}
                    required />
                </div>                                
              </div>

              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-3">
                  <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Elettrico:</strong></h5>
                </div>
                
                <div className="col-xs-1" >
                  <input
                      name="electric" 
                      className="form-control" 
                      type="checkbox" 
                      onChange={(e) => this.checkElectric(e.target.checked) }
                      checked={vehiclePowerSupply.electric}
                      required />                  
                </div>                                
              </div>

              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-3">
                  <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Ibrido:</strong></h5>
                </div>
                
                <div className="col-xs-1" >
                  <input
                    name="hybrid" 
                    className="form-control" 
                    type="checkbox" 
                    onChange={(e) => this.checkHybrid(e.target.checked) }
                    checked={vehiclePowerSupply.hybrid}
                    required />  
                </div>                                
              </div>

              <div className="row" style={{ marginBottom: "1em" }}>
                { sendErrors && 
                  <h4 className="col-xs-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {sendErrors}
                  </h4>
                }
                { checkErrors && checkErrors.length > 0 && 
                  <h4 className="col-xs-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                  </h4>
                }
              </div>              
              
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  <Button className="btn alertBtn" onClick={() => { this.onCancelEdit() } }>Annulla</Button>{' '}
                  <Button className="btn mainBtn"  onClick={() => { this.save() }} >Salva</Button>
                  { vehiclePowerSupplyId &&
                  <Button className="btn alertBtn" onClick={() => { this.setState({ showConfirmDelete: true })} }><FontAwesomeIcon  size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina</Button>
                  }
                </div>
              </div>          

            </React.Fragment>            
          </div>
        </div>           
        }  

        <RequestConfirmModal
          requestStyle={2}
          requestTitle="Conferma Eliminazione Tipo di Alimentazione"
          requestSubTitle="ATTENZIONE"
          requestMsg={"Proseguendo con l'operazione si eliminerà il tipo di alimentazione "+ vehiclePowerSupply.name +".<br/><br/>L'operazione è irreversibile, si vuole procedere?"}
          show={showConfirmDelete}
          onConfirm={this.delete}
          onRefuse={ () => {this.setState({ showConfirmDelete: false }) }}
        />

       
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'BackofficeEditVehiclePowerSupply'})(BackofficeEditVehiclePowerSupply))
