import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';

moment.locale('it')

class RequestConfirmModal extends Component {

  static propTypes = {
    requestStyle:     PropTypes.number.isRequired, // 0 normal, 1 waring, 2 alert
    requestTitle:     PropTypes.string, 
    requestSubTitle: PropTypes.string, 
    requestMsg:       PropTypes.string.isRequired,    
    show:             PropTypes.bool.isRequired,    
    onConfirm:        PropTypes.func.isRequired,
    onRefuse:         PropTypes.func.isRequired,        
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      
    }    
        
    this.close     = this.close.bind(this)
    
    
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {

    this.setState({      
      
    })    

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {       
     
  }
 

  /**
   * --------------------------------------------------------------
   */
  close() {
    if ( this.props.onRefuse != null )
      this.props.onRefuse()     
        
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show, 
      requestStyle,      
      requestTitle,
      requestSubTitle, 
      requestMsg,
      onConfirm,
      onRefuse
    } = this.props

    /*
    const {
      
    } = this.state
    */

    var style = null

    switch( requestStyle ) {
      case 1:
        style="text-warning"
        break;
      case 2:
        style="text-danger"
        break;
      
      default:
        style = ''
        break;
    }
    

    return (
      <Modal
        open={show}
        onClose={this.close}
        closeOnEsc={true}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        //focusTrapped={true}
        center
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon  size="1x" icon={solidIcons.faQuestionCircle} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>  { requestTitle != null ?  requestTitle :  "RICHIESTA CONFERMA" }</strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-category-to-window"}>Chiudi finestra</Tooltip>}>
              <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
            </OverlayTrigger>
          </div>
        </h4> 
        
        <div className="col-md-12">
          <div className="app-modal-body">                                  
          <div className="col-md-12" style={{ padding: "0", paddingLeft: "2em", paddingRight: "2em" }}>                      
          <React.Fragment>       

            { requestSubTitle != null &&
            <div className="row">
              <div className="col-xs-12 text-center " style={{marginBottom: '1.5em', marginTop: '1em' }}>
                <h5 className={ style  } style={{ marginTop: 5 , marginBottom: 5, verticalAlign: "top" }}>
                    <strong>{requestSubTitle}</strong>
                </h5>
              </div>
            </div>  
            }       
                        
            <div className="row">       
                <div className="col-xs-12 text-center">
                <h5 className={ style  } style={{ marginTop: 5 , marginBottom: 5, verticalAlign: "top" }}>
                    <strong>{ HTMLReactParser(requestMsg,"text/html") }</strong>
                  </h5>
                </div>                                                
                <br/><br/>
            </div>
                                                                                                        
            <div className="row">
              <div className="col-md-12 text-center" style={{marginBottom: '1.5em', marginTop: '1em' }}>
                <Button className="btn alertBtn" onClick={() => { onRefuse()  }}>Annulla</Button>
                <Button className="btn mainBtn"  onClick={() => { onConfirm() }}>Conferma</Button>                
              </div>
            </div>          
                        
          </React.Fragment>                      
        </div>    
            
          </div>
        </div>           
        
      </Modal>
    )
  }
}

export default reduxForm({ form: 'RequestConfirmModal'})(RequestConfirmModal)
