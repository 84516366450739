import React, { Component } from 'react'
import { connect } from 'react-redux'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import Loading from '../../../components/LoadingComponent'
import MarkPayment from './MarkPayment'



/**
 * -------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {

  }
}

/**
 * -------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {

  }
}


/**
 * -------------------------------------------------------------------------
 * 
 */
class MarkPaymentContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      paymentData: {},
      mark: {},
      allOrders: {},
      loading: true,
      error: null,
      pendingOrders: null,
      settings: [],
      hostedPage: "",
      payment: null
    }

    if (this.state.payment == null) {
      SostaOnlineApiService.getPaymentConfig().then(data => {
        this.state.payment = data
      })
    }

    this.fetchMark = this.fetchMark.bind(this)
    this.onPayNexi = this.onPayNexi.bind(this)
    this.onPayXpay = this.onPayXpay.bind(this)
    this.onPaySella = this.onPaySella.bind(this)
    this.onPay = this.onPay.bind(this)
  }

  /**
   * -------------------------------------------------------------------------
   * 
   */
  async fetchOrders() {
    //console.log ( "containers/UserArea/Checkout/MarkPaymentContainer fetchOrders this.props.match.params.id:", this.props.match.params.id );
    await SostaOnlineApiService.fetchThisMarkOrdersHistory(this.props.match.params.id).then((MarkOrders) => {
      this.setState({ allOrders: MarkOrders.data, loading: false })
    }).catch((error) => {
      console.error('Errore durante il recupero dei dati Ordini:', error);
    });
  }

  /**
   * -------------------------------------------------------------------------
   * 
   */
  async fetchMark() {
    SostaOnlineApiService.fetchMarkMe(this.props.match.params.id).then((data) => {
      this.setState({ mark: data.data })
      SostaOnlineApiService.fetchMyMarksPendingOrders(this.props.match.params.id).then((data) => {
        // ricerca ordini in attesa di questo permesso
        this.setState({ pendingOrders: data.data })
        //this.setState({loading: false })
      })

      SostaOnlineApiService.fetchPublicSettings().then((settings) => {

        if (settings != null && settings.data != null && settings.data.length > 0) {
          this.setState({ settings: settings.data })
        }
      })

      SostaOnlineApiService.fetchThisMarkOrdersHistory(this.props.match.params.id).then((MarkOrders) => {
        this.setState({ allOrders: MarkOrders.data, loading: false })
      })

    })
  }

  /**
   * -------------------------------------------------------------------------
   * 
   */
  componentDidMount() {
    this.setState({ loading: true })
    this.fetchMark()
  }

  /**
   * -------------------------------------------------------------------------
   * 
   */
  async onPay(e) {

    switch (this.state.payment.module) {

      case "nexi":
        this.onPayNexi(e)
        break;

      case "xpay":
        this.onPayXpay(e)
        break;

      case "sella":
        this.onPaySella(e)
        break;

      case "pagopa":
        this.onPayPagopa(e)
        break;
      default:
        break;
    }
  }


  /**
   * -------------------------------------------------------------------------
   * 
   */

  async onPayNexi(e) {

    e.preventDefault();

    this.setState({ error: null })

    await SostaOnlineApiService.buyMark(this.props.match.params.id).then(data => {

      if (data.result == "ok") {
        this.setState({
          paymentData: data.data
        })

        console.log("containers/UserArea/Checkout/MarkPaymentContainer: onPayNexi: alias:", document.getElementById('alias').value);
        console.log("containers/UserArea/Checkout/MarkPaymentContainer: onPayNexi: importo:", document.getElementById('importo').value);
        console.log("containers/UserArea/Checkout/MarkPaymentContainer: onPayNexi: divisa:", document.getElementById('divisa').value);
        console.log("containers/UserArea/Checkout/MarkPaymentContainer: onPayNexi: codTrans:", document.getElementById('codTrans').value);
        console.log("containers/UserArea/Checkout/MarkPaymentContainer: onPayNexi: url:", document.getElementById('url').value);
        console.log("containers/UserArea/Checkout/MarkPaymentContainer: onPayNexi: url_back:", document.getElementById('url_back').value);
        console.log("containers/UserArea/Checkout/MarkPaymentContainer: onPayNexi: nexiTimeout:", document.getElementById('nexiTimeout').value);
        console.log("containers/UserArea/Checkout/MarkPaymentContainer: onPayNexi: mac:", document.getElementById('mac').value);

        document.getElementById('MarkPaymentForm').submit()
      }

    })
      .catch(err => {
        console.log("containers/UserArea/Checkout/MarkPaymentContainer onPayNexi: catch(err):", err)
        // altro bug e siamo a 7
        //this.setState({ 'error': err.error })
        this.setState({ error: err.error })
      })

  }

  /**
   * -------------------------------------------------------------------------
   * 
   */

  async onPayXpay(e) {
    console.log("containers/UserArea/Checkout/MarkPaymentContainer onPayXpay e:", e);
    e.preventDefault();

    this.setState({ error: null })
    // quindi ogni volta che si preme ACQUISTA viene generato un nuovo MarkOrder e relativo Order
    // indipendentemente dall'esito del precedente che è già stato portato a -1 (altrimenti non sarebbe stato possibile premere il pulsante PAGA)
    await SostaOnlineApiService.buyMark(this.props.match.params.id).then(data => {
      console.log("containers/UserArea/Checkout/MarkPaymentContainer onPayXpay data:", data);
      // qui non arriva mai, neppure con esito positivo, questo console.log non viene mai generato
      // giustamente in questo modo la richiesta non arriva a SostaOnlineApiService.buyMark
      // ma dalla pagina di XPay all'api diretta
      if (data.result == "ok") {
        this.setState({
          paymentData: data.data,
          hostedPage: data.data.hostedPage
        })

        window.location.href = data.data.hostedPage
      }

    })
      .catch(err => {
        console.log("containers/UserArea/Checkout/MarkPaymentContainer onPayXpay catch(err):", err)
        // altro bug e siamo a 7
        //this.setState({ 'error': err.error })
        this.setState({ error: err.error })
      })
  }

  /**
   * -------------------------------------------------------------------------
   * 
   */
  async onPaySella(e) {

    this.setState({ error: null })

    await SostaOnlineApiService.buyMark(this.props.match.params.id).then(data => {
      this.setState({
        paymentData: data.data
      })
      window.location.href = 'https://ecomm.sella.it/pagam/pagam.aspx?a=' + this.state.paymentData.shopLogin + '&b=' + this.state.paymentData.cryptedString

    }).catch(err => {
      console.log("ERROR SELLA", err.error)
      this.setState({ error: err.error })
    })

  }

  /**
 * -------------------------------------------------------------------------
 * 
 */
  async onPayPagopa(e) {

    this.setState({ error: null })

    await SostaOnlineApiService.buyMark(this.props.match.params.id).then(data => {
      this.setState({
        paymentData: data.data
      })
      //window.location.href = 'https://ecomm.sella.it/pagam/pagam.aspx?a=' + this.state.paymentData.shopLogin + '&b=' + this.state.paymentData.cryptedString

    }).catch(err => {
      console.log("ERROR PAGOPA", err.error)
      this.setState({ error: err.error })
    })

  }


  /**
   * -------------------------------------------------------------------------
   * 
   */
  render() {

    const {
      loading, allOrders, mark, pendingOrders, error, payment, paymentData, settings, hostedPage
    } = this.state
    if (!loading) {
      //console.log ( "containers/UserArea/Checkout/MarkPaymentContainer render props:", this.props );
      return (<MarkPayment
        mark={mark}
        onPay={this.onPay}
        allOrders={allOrders}
        pendingOrders={pendingOrders}
        errorMsg={error}
        payment={payment}
        initialValues={paymentData}
        settings={settings}
        hostedPage={hostedPage}
      />)
    } else {
      return (<Loading />)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkPaymentContainer)
