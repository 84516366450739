import React, {Component} from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import isEqual from 'lodash/isEqual'

export default class CategoriesTreeSelectFormComponent extends Component {

  constructor (props) {
    super(props)

    let options = props.options

    if (props.input.value && props.input.value.length > 0) {
      options = this.checkCategories(props.input.value, props.options)
    }

    this.state = {
      data: options
    }

    this.onChange = this.onChange.bind(this)
  }

  checkCategories (active, items) {   
    return items.map(item => {
      let checked = false
      let children = []
      let expanded = false

      if (  item.children && item.children.length > 0) {
        children = this.checkCategories(active, item.children)
      }

      if (active && active.filter(valueItem => item.value === valueItem.value).length > 0) {
        checked = true
      }

      if ( children.length > 0 && checked)
        expanded = true

      return {
        ...item,
        'children': children,
        'checked': checked,
        'disabled': false,
        'expanded': expanded
      }
    })
  }

  componentDidUpdate (prevProps) {
    var options = this.checkCategories(this.props.input.value, this.props.options)

    if (!isEqual(options, prevProps.data)) {
      this.setState({ data: options })
    }
  }

  shouldComponentUpdate (nextProps) {
    var options = this.checkCategories(this.props.input.value, nextProps.options)
    return !isEqual(options, this.state.data)
  }

  onChange (currentNode, selectedNodes) {
    const activeCategories = selectedNodes
      .filter(item => item.checked === true)
      .map(item => ({label: item.label, value: item.value, workerPerson: item.workerPerson, worker: item.worker, resident: item.resident, parkingInDifferentPlace: item.parkingInDifferentPlace, multipleParkingSpace: item.multipleParkingSpace}))
    this.props.input.onChange(activeCategories)
    if (this.props.onCategoriesChange) {
      this.props.onCategoriesChange(activeCategories)
    }
  }

  render () {
    return (<div>
      <DropdownTreeSelect
        id="Categories"
        required={this.props.required}
        disabled={this.props.disabled}
        data={this.state.data}
        onAction={({action, node}) => { /*console.log(`onAction:: [${action}]`, node)*/ }}
        onNodeToggle={(currentNode) => { /*console.log('onNodeToggle::', currentNode)*/ }}
        onChange={this.onChange}
        texts={{  placeholder:this.props.placeholder }}
      />
    </div>)
  }
}
