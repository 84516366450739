import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import { bindActionCreators } from 'redux'

import {actions as authActions} from 'redux/modules/Auth'
import ResellerUserDetailContainer from './ResellerUserDetailContainer'//
import ResellerUserTableContainer from './ResellerUserTableContainer'//
import ResellerUserCreateContainer from './ResellerUserCreateContainer'//
import ResellerUserEditContainer from './ResellerUserEditContainer'//
import ResellerUserWaitingListTableContainer from './ResellerUserWaitingListTableContainer'//
import ResellerUserDocumentsContainer from './ResellerUserDocumentsContainer'//

const mapStateToProps = (state) => {
  return {
    state: state.auth,
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  }
}

class ResellerUsersContainer extends Component {
  constructor (props) {
    super(props)    
  }

  render () {
    return (
      <Switch>
        <Route path="/reseller/users/create"        component={ResellerUserCreateContainer} />
        {/*<Route path="/reseller/users/waiting-list"  component={(props) => <ResellerUserWaitingListTableContainer {...props} />} />*/}
        <Route path="/reseller/users/:id/edit"      component={ResellerUserEditContainer} />
        {/*<Route path="/reseller/users/:id/documents" component={ResellerUserDocumentsContainer} />*/}
        <Route path="/reseller/users/:id"           component={(props) => <ResellerUserDetailContainer user={this.props.user} {...props} />} />        
        {/*<Route path="/reseller/users"               component={(props) => <ResellerUserTableContainer {...props} />} />*/}
      </Switch>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerUsersContainer)
