import { getWindowHeight } from 'libs/utils';
import React, { Component } from 'react'
import BackOfficeMenuComponent from '../components/BackOfficeMenuComponent'
import BackOfficeNavMenuComponent from '../components/BackOfficeNavMenuComponent'
import './BackOfficeNavigatorScreen.css'
import InputLogo from '../assets/LogoInput60x60.png'
import SostaOnlineApiService from '../services/SostaOnlineApiService'

export default class BackOfficeNavigatorScreen extends Component {

  // ----------------------------------------------------------------
  //
  //
  constructor(props) {
    super(props)

    this.state = {
      resellers: [],
      minHeight: 0
    }

    this.handleResize = this.handleResize.bind(this)
    this.onWaitingResellers = this.fetchWaitingResellers.bind(this)
  }


  // ----------------------------------------------------------------
  //
  //
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.setState({ minHeight: getWindowHeight() })
    this.fetchWaitingResellers()
  }

  // ----------------------------------------------------------------
  //
  //
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  // ----------------------------------------------------------------
  //
  //
  handleResize() {
    this.setState({ "minHeight": getWindowHeight() })
  }

  // ----------------------------------------------------------------
  //
  //
  fetchWaitingResellers() {
    var newResellers = this.state.resellers
    SostaOnlineApiService.fetchResellerWaitingList()
      .then(data => {
        //console.log("fetchWaitingResellers: ", data)
        newResellers = data.data
        // Store the result in state
        this.setState({ resellers: newResellers })
        //console.log ( newResellers)
      })
      .catch(error => {
        console.log("ERROR", error)
      })
  }

  render() {

    const { minHeight,resellers } = this.state
    //console.log("BackofficeNavigatorScreen: state:", this.state)
    //console.log("BackofficeNavigatorScreen: props:", this.props)
    
    return (
      <div style={{ minHeight: minHeight }}>
        <div className="backoffice">
          <div className="row header-canvas">
            <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-2">
              <a href="https://inputitalia.com/" target="_blank">
                <img style={{ margin: 10, marginLeft: 0, width: '50px', height: '50px' }} alt="Powered by Input s.r.l. P.IVA 01175100997" src={InputLogo} />
              </a>
            </div>
            <div className="col-xs-8 col-sm-9 col-md-10 col-lg-10 col-xl-10" style={{ marginTop: 10, marginBottom: 10, display: 'flex', alignItems: 'center', placeContent: 'flex-end' }}>
              <BackOfficeNavMenuComponent resellers={resellers} {...this.props} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-2 col-sm-3 col-md-2 col-lg-2 col-xl-2 main-content">
              <BackOfficeMenuComponent {...this.props} />
            </div>
            <div className="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 sidebar-right" style={{ paddingRight: '25px' }}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>

    );
  }
}