import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ResellerEditMarkType from './ResellerEditMarkType';//

moment.locale('it')

class ResellerEditMarkTypeModal extends Component {

  static propTypes = {
    show:                 PropTypes.bool.isRequired,    
    markTypeId:           PropTypes.number,    
    onSaveMarkType:       PropTypes.func.isRequired,
    onDeleteMarkType:     PropTypes.func.isRequired,
    onCancelEditMarkType: PropTypes.func.isRequired,
    onClose:              PropTypes.func.isRequired,
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      
    }    
    
    this.close                = this.close.bind(this)
    this.onDeleteMarkType     = this.onDeleteMarkType.bind(this)
    this.onSaveMarkType       = this.onSaveMarkType.bind(this)
    this.onCancelEditMarkType = this.onCancelEditMarkType.bind(this)
    
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {

    this.setState({      
      
    })    

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {       
     
  }

  /**
   * --------------------------------------------------------------
   */
  onDeleteMarkType() {
    if ( this.props.onDeleteMarkType != null ) 
      this.props.onDeleteMarkType()

    this.close()
  }

  /**
   * --------------------------------------------------------------
   */
  onSaveMarkType(markTypeId) {
    if ( this.props.onSaveMarkType != null ) {
      this.props.onSaveMarkType(markTypeId)
    }

    this.close()
  }

  /**
   * --------------------------------------------------------------
   */
  onCancelEditMarkType() {
    if ( this.props.onCancelEditMarkType != null )
      this.props.onCancelEditMarkType()

    //this.close()
  }

  /**
   * --------------------------------------------------------------
   */
  close() {
    if ( this.props.onClose != null )
      this.props.onClose()     
        
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,       
      markTypeId
    } = this.props

    /*
    const {
      
    } = this.state
    */
      
    return (
      <Modal
        open={show}
        onClose={this.close}
        closeOnEsc={true}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        //focusTrapped={true}
        center
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            { markTypeId != null 
              ? <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
              : <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faPlusCircle} />
            }
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>  { markTypeId != null ? "MODIFICA" : "CREA NUOVO" } TIPO PERMESSO </strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-category-to-window"}>Chiudi finestra</Tooltip>}>
              <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
            </OverlayTrigger>
          </div>
        </h4> 
        
        <div className="col-md-12">
          <div className="app-modal-body">                                  
            <ResellerEditMarkType
              markTypeId={markTypeId}
              onSaveMarkType={this.onSaveMarkType}      
              onDeleteMarkType={this.onDeleteMarkType} 
              onCancelEditMarkType={this.onCancelEditMarkType}
            />
          </div>
        </div>           
        
      </Modal>
    )
  }
}

export default reduxForm({ form: 'ResellerEditMarkTypeModal'})(ResellerEditMarkTypeModal)
