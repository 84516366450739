import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import SostaOnlineApiService from '../../services/SostaOnlineApiService'//
import ResellerUserDetailLogsTable from '../../screens/Reseller/ResellerUserDetailLogsTable'//
const thisRoute = 'reseller'

const mapStateToProps = (state) => {
  return {
    state: state.users
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

class ResellerUserDetailLogsTableContainer extends Component {
  static propTypes = {
    userId: PropTypes.number
  }

  constructor (props) {
    super(props)
    this.state = {
      logs: [],
      loading: false
    }

    this.fetchLogs = this.fetchLogs.bind(this)
  }

  componentDidMount () {
    this.fetchLogs()
  }

  fetchLogs () {
    this.setState({loading: true})

    console.log(this.props.userId)
    SostaOnlineApiService.fetchUserLogs(this.props.userId,thisRoute).then((data) => {
      this.setState({
        logs: data.data,
        loading: false
      })
    })
  }

  render () {
    return (
      <div>
        <ResellerUserDetailLogsTable
          loading={this.state.loading}
          logs={this.state.logs}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerUserDetailLogsTableContainer)
