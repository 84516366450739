import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import  * as brandsIcons  from '@fortawesome/free-brands-svg-icons'

export default class BackOfficeSettingsMenuComponent extends Component {
  render () {    

    //console.log("history",this.props.history.location.pathname)

    var pathname = this.props.history.location.pathname

    return (
      <div className="row">
        <div className="pull-left" style={{ marginLeft: "1em" , marginRight: "1em" , marginTop: "0.5em" }}>
          <NavLink to={'/backoffice/settings/'} title="Generali" className={ pathname == '/backoffice/settings' ? "selectedMainBtn" : "mainBtn" } >
            <FontAwesomeIcon  size="1x" icon={solidIcons.faGear} /><span className="hidden-xs hidden-sm">&nbsp;&nbsp;Generali</span>
          </NavLink>          
          <NavLink to={'/backoffice/settings/adminUsers'} title="Utenti admin" className={ pathname == '/backoffice/settings/adminUsers' ? "selectedMainBtn" : "mainBtn"  } >
            <FontAwesomeIcon  size="1x" icon={solidIcons.faUserAstronaut} /><span className="hidden-xs hidden-sm">&nbsp;&nbsp;Utenti admin</span>
          </NavLink>
          <NavLink to={'/backoffice/settings/areas'} title="Zone" className={ pathname == '/backoffice/settings/areas' ? "selectedMainBtn" : "mainBtn"  } >
            <FontAwesomeIcon  size="1x" icon={solidIcons.faTableCells} /><span className="hidden-xs hidden-sm">&nbsp;&nbsp;Zone</span>
          </NavLink>
          <NavLink to={'/backoffice/settings/categories'} title="Categorie" className={ pathname == '/backoffice/settings/categories' ? "selectedMainBtn" : "mainBtn"  } >
            <FontAwesomeIcon  size="1x" icon={solidIcons.faShapes} /><span className="hidden-xs hidden-sm">&nbsp;&nbsp;Categorie</span>
          </NavLink>          
          <NavLink to={'/backoffice/settings/documentTypes'} title="Documenti" className={ pathname == '/backoffice/settings/documentTypes' ? "selectedMainBtn" : "mainBtn"  } >
            <FontAwesomeIcon  size="1x" icon={solidIcons.faFileContract} /><span className="hidden-xs hidden-sm">&nbsp;&nbsp;Documenti</span>
          </NavLink>
          <NavLink to={'/backoffice/settings/vehicles'} title="Veicoli" className={ pathname == '/backoffice/settings/vehicles' ? "selectedMainBtn" : "mainBtn"  } >
            <FontAwesomeIcon  size="1x" icon={solidIcons.faCar} /><span className="hidden-xs hidden-sm">&nbsp;&nbsp;Veicoli</span>
          </NavLink>
          <NavLink to={'/backoffice/settings/markTypes'} title="Permessi" className={ pathname == '/backoffice/settings/markTypes' ? "selectedMainBtn" : "mainBtn"  }>
            <FontAwesomeIcon  size="1x" icon={solidIcons.faWallet} /><span className="hidden-xs hidden-sm">&nbsp;&nbsp;Permessi</span>
          </NavLink>
        </div>
      </div>
    )
  }
}
