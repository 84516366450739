import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import DatePickerFormComponent from '../components/redux-form/DatePickerFormComponent'
import VirtualizedSelectFormComponent from '../components/redux-form/VirtualizedSelectFormComponent'
import CategoriesTreeSelectFormComponent from '../components/redux-form/CategoriesTreeSelectFormComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { formatCityString, parseCap, parseCivicNumber, parseEmail, parseFiscalCode, parsePhoneNumber, parseVatNumber } from 'libs/utils'

/**
 * ----------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    userFormValues: state.form.backofficeUserCreate
  }
}

/**
 * ----------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}




/**
 * ================================================================
 * 
 */
class BackOfficeUserCreateScreen extends Component {

  static propTypes = {
    onFiscalCodeChange: PropTypes.func,
    onSearchFiscalCodeAndFormFilling: PropTypes.func,
    searchingFiscalCode: PropTypes.bool,
    foundFiscalCode: PropTypes.object,
    searchingFiscalCodeInitialized: PropTypes.bool,
    selectedParkSpaces: PropTypes.array,
    onAddParkingSpace: PropTypes.func,
    onRemoveParkSpace: PropTypes.func,
    onMultipleParkingSpaceAddressSelected: PropTypes.func
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)
    this.state = {
      residenceArea: null,
      workArea: null,
      companyLegalCity: null,
    }
  }

  /**
   * ----------------------------------------------------------------
   */
  componentDidMount() {
    console.log("PROPS", this.props)
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} categories 
   * @returns 
   */
  isResident(categories) {
    let filteredCategories = categories.filter(item => {
      return item.resident
    })

    return filteredCategories.length > 0
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} categories 
   * @returns 
   */
  isParkPlaceInDifferentAddress(categories) {
    let filteredCategories = categories.filter(item => {
      return item.parkingInDifferentPlace
    })

    return filteredCategories.length > 0
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} categories 
   * @returns 
   */
  isParkPlaceMultiple(categories) {
    let filteredCategories = categories.filter(item => {
      return item.multipleParkingSpace
    })

    //console.log(filteredCategories)
    return filteredCategories.length > 0
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} categories 
   * @returns 
   */
  isWorker(categories) {
    let filteredCategories = categories.filter(item => {
      return item.worker
    })

    return filteredCategories.length > 0
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} categories 
   * @returns 
   */
  isWorkerPerson(categories) {
    //console.log(categories)
    let filteredCategories = categories.filter(item => {
      return item.workerPerson
    })

    return filteredCategories.length > 0
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  getAreasSelectOptions() {
    var options = []

    if (this.props.areas) {
      options = this.props.areas.map(item => {
        return { label: item.name, value: item.id }
      })
    }

    return options
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} addressId 
   * @returns 
   */
  onResidentAddressSelected(addressId) {
    if (!addressId) {
      this.props.change('residenceAreaId', null)
      this.setState({ residenceArea: null })
      return
    }

    let address = this.props.addresses.data.find(item => { return item.id === addressId })
    this.setState({ residenceArea: address.Area })
    this.props.change('residenceAreaId', address.Area.id)
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} cityId 
   */
  onCompanyLegalCitySelected(city) {
    this.setState({ companyLegalCity: city })
    this.props.change('companyLegalCity', city)

    if (this.isPortalLegalCityEqualToCurrentCitySelected(city))
      this.props.change('companyLegalCap', this.props.city.cap)
    else
      this.props.change('companyLegalCap', null)

  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity) {
    var check = false

    if (formatCityString(this.props.city.longName, this.props.city.province) == companyLegalCity)
      check = true

    return check
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} areaId 
   * @returns 
   */
  getArea(areaId) {
    let area = this.props.areas.find(item => { return item.id === areaId })
    return area
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} areaId 
   * @returns 
   */
  getAreaAddresses(areaId) {
    var addresses = this.props.addresses.data

    addresses = addresses.filter(address => {
      if (areaId != null) {
        if (address.Area.id == areaId)
          return address
      }
      else
        return address
    })

    addresses = addresses.map(item => {
      return { label: item.name, value: item.id }
    })

    return addresses
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} areaId 
   */
  onWorkAreaSelected(areaId) {

    if (!areaId) {
      this.props.change('workAreaId', null)
      this.setState({ workArea: null })
      return
    }

    // let address = this.props.addresses.data.find(item => { return item.id === addressId })
    this.setState({ workArea: this.getArea(areaId) })
    this.props.change('workAreaId', areaId)

  }

  /**
   * ----------------------------------------------------------------
   * @param {*} addressId 
   * @returns 
   */
  onWorkAddressSelected(addressId) {
    if (!addressId) {
      this.props.change('workAreaId', null)
      this.setState({ workArea: null })
      return
    }

    let address = this.props.addresses.data.find(item => { return item.id === addressId })
    this.setState({ workArea: address.Area })
    this.props.change('workAreaId', address.Area.id)
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} e 
   */
  onCompanyLegalAddressChange(e) {
    this.props.change('companyLegalAddressId', null)
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  render() {
    const {
      handleSubmit,
      onFiscalCodeChange,
      onSearchFiscalCodeAndFormFilling,
      searchingFiscalCode,
      comuni,
      addresses,
      comuniWithoutCurrentCity,
      searchingFiscalCodeInitialized,
      foundFiscalCode,
      categoryOptions,
      onCategoriesChange,
      selectedCategories,
      onAddParkingSpace,
      selectedParkSpaces,
      onRemoveParkSpace,
      onMultipleParkingSpaceAddressSelected,
      onParkingSpaceAddressSelected,
      selectedParkingSpaceArea,
      loading,
      userCreateError,
      userCreateResult,
      userCreateSuccess,
      residenceDataForAllUsers
    } = this.props

    var {
      residenceArea,
      workArea,
      companyLegalCity
    } = this.state

    return (<div className="col-md-12">
      <div className="panel panel-default panel-border">
        <div className="panel-body">
          <div className="titolo-categoria">
            <h3 className="text-left sectionTitle">
              <FontAwesomeIcon size="1x" className="sectionIcons" icon={solidIcons.faUserPlus} />&nbsp;&nbsp;CREA UTENTE
            </h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="box">
              {/* Inizio informazioni generali utente */}
              <fieldset>
                <div className="titolo-categoria">
                  <legend className="text-left">Informazioni generali</legend>
                </div>
                <div className="row">
                  <div className="col-md-6 text-left">
                    <label>Codice Fiscale</label>
                    <Field
                      parse={(value, name) => parseFiscalCode(value)}
                      onBlur={onSearchFiscalCodeAndFormFilling}
                      onChange={onFiscalCodeChange}
                      component="input"
                      name="fiscalCode"
                      type="text"
                      className="mainField"
                      required
                    />
                  </div>
                  {searchingFiscalCodeInitialized && (
                    <div className="col-md-6">
                      <label />
                      <div className="text-left" style={{ marginTop: 12 }}>
                        {searchingFiscalCode ? (
                          <h4 className="text-info">Ricerca Utente in corso...</h4>
                        ) : (
                          (foundFiscalCode) ? (
                            <h4 className="text-success">Utente Residente</h4>
                          ) : (
                            <h4 className="text-danger">Utente non Residente</h4>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 text-left">
                    <label>Nome</label>
                    <Field component="input" name="firstName" type="text" className="mainField" required />
                  </div>
                  <div className="col-md-6 text-left">
                    <label>Cognome</label>
                    <Field component="input" name="lastName" type="text" className="mainField" required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-left">
                    <label>E-mail</label>
                    <Field
                      parse={(value, name) => parseEmail(value)}
                      component="input"
                      name="email"
                      type="text"
                      className="mainField"
                      required />
                  </div>
                  <div className="col-md-6 text-left">
                    <label>Telefono Mobile</label>
                    <Field
                      parse={(value, name) => parsePhoneNumber(value)}
                      component="input"
                      name="phone"
                      type="text"
                      placeholder="333 333 3333"
                      className="mainField" />
                  </div>
                </div>
              </fieldset>
              {/* Fine informazioni generali utente */}

              {/* Inizio Categorie Utente */}
              <div className="row">
                <div className="col-md-6 text-left">
                  <label>Seleziona Categorie Utente</label>
                  <Field
                    mode="multiSelect"
                    options={categoryOptions}
                    component={CategoriesTreeSelectFormComponent}
                    onCategoriesChange={(categories) => onCategoriesChange(categories)}
                    name="userCategories"
                    placeholder="Seleziona Categorie Utente"
                  />
                </div>
              </div>
              {/* Fine Categorie Utente */}

              {/* Inizio Informazioni Residente */}
              {this.isResident(selectedCategories) &&
                <fieldset>
                  <div className="titolo-categoria">
                    <legend className="text-left">Residente</legend>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-md-3 text-left">
                        <label>Data di Nascita</label>
                        <Field
                          placeholderText="GG/MM/AAAA"
                          component={DatePickerFormComponent}
                          name="birthDate"
                          className="mainField"
                          required />
                      </div>
                      <div className="col-md-3 text-left">
                        <label>Città di Nascita</label>
                        <Field
                          name="birthPlace" options={comuni}
                          component={VirtualizedSelectFormComponent} required />
                      </div>
                      <div className="col-md-3 text-left">
                        <label>Sesso</label><br />
                        <label className="sex">
                          <Field name="sex" component="input" type="radio" value="M" required /> {' '} Uomo
                        </label>
                        {' '}
                        <label>
                          <Field name="sex" component="input" type="radio" value="F" required /> {' '} Donna
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 text-left">
                        <label>Indirizzo di Residenza/Domicilio</label>
                        <Field
                          onChange={(event, data) => this.onResidentAddressSelected(data)}
                          name="residenceAddress"
                          options={addresses.data.map(item => {
                            return {
                              label: item.name,
                              value: item.id
                            }
                          })}
                          component={VirtualizedSelectFormComponent}
                          required
                        />
                      </div>
                      <div className="col-md-2 text-left">
                        <label>N°</label>
                        <Field
                          parse={(value, name) => parseCivicNumber(value)}
                          component="input"
                          name="residenceCivicNumber"
                          type="text"
                          placeholder="N°"
                          className="mainField"
                          required />
                      </div>
                      <div className="col-md-2 text-left">
                        <label>Interno</label>
                        <Field
                          parse={(value, name) => parseCivicNumber(value)}
                          component="input"
                          name="residenceInside"
                          type="text"
                          placeholder="Interno"
                          className="mainField"
                          required />
                      </div>
                      <div className="col-md-2 text-left">
                        <label>Area di Residenza</label>
                        {residenceArea && <h4>{residenceArea.name}</h4>}
                        <Field
                          hidden={true}
                          disabled={true}
                          component="input"
                          name="residenceAreaId"
                          type="text"
                          placeholder="AreaId"
                          className="mainField"
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-info text-left">
                        I campi a seguire sono da compilare solo nel caso l'indirizzo precedente rappresenti il domicilio
                      </div>
                      <div className="col-md-6 text-left">
                        <label>Indirizzo di Residenza</label>
                        <Field
                          component="input"
                          name="secondaryResidenceAddress"
                          type="text"
                          placeholder="Indirizzo di residenza"
                          className="mainField"
                        />
                      </div>
                      <div className="col-md-2 text-left">
                        <label>N°</label>
                        <Field
                          parse={(value, name) => parseCivicNumber(value)}
                          component="input"
                          name="secondaryResidenceCivicNumber"
                          type="text"
                          placeholder="N°"
                          className="mainField"
                        />
                      </div>
                      <div className="col-md-2 text-left">
                        <label>Interno</label>
                        <Field
                          parse={(value, name) => parseCivicNumber(value)}
                          component="input"
                          name="secondaryResidenceInside"
                          type="text"
                          placeholder="Interno"
                          className="mainField"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 text-left">
                        <label>CAP</label>
                        <Field
                          parse={(value, name) => parseCap(value)}
                          component="input"
                          name="secondaryResidenceCap"
                          type="text"
                          placeholder="CAP"
                          className="mainField"
                        />
                      </div>
                      <div className="col-md-3 text-left">
                        <label>Città</label>
                        <Field
                          name="secondaryResidenceCity"
                          options={comuniWithoutCurrentCity}
                          component={VirtualizedSelectFormComponent}
                        />
                      </div>
                    </div>

                    {this.isParkPlaceInDifferentAddress(selectedCategories) && (
                      <div className="row">
                        <div className="col-md-12 text-info text-left" style={{ marginTop: 10, marginBottom: 10 }}>
                          Questa categoria utente ha uno o più posti auto in un luogo diverso da quello di residenza/domicilio
                        </div>
                        {this.isParkPlaceMultiple(selectedCategories) ? (
                          <div>
                            <div className="col-md-12 text-left">
                              <a onClick={onAddParkingSpace} className="mainBtn">Aggiungi Posto Auto</a>
                            </div>
                            {selectedParkSpaces.map((item, index) => {
                              return (
                                <div className="col-md-12" key={`park-space-${index}`}>
                                  <div className="col-md-1">
                                    <a className="btn btn-xs btn-danger" onClick={() => onRemoveParkSpace(index)}>Rimuovi</a>
                                  </div>
                                  <div className="col-md-7 text-left">
                                    <label>
                                      Indirizzo del Posto Auto {index + 1}
                                    </label>
                                    <Field
                                      onChange={(event, data) => onMultipleParkingSpaceAddressSelected(data, index)}
                                      name={'parkingSpaceAddress' + index}
                                      options={
                                        addresses.data.map(item => {
                                          return { label: item.name, value: item.id }
                                        })} component={VirtualizedSelectFormComponent} required />
                                  </div>
                                  {item.area && <div className="col-md-3 text-left">
                                    <label>Area Selezionata</label>
                                    <div style={{ marginTop: 12 }}>
                                      <h4>{item.area.name}</h4>
                                    </div>
                                  </div>}
                                </div>
                              )
                            })}
                          </div>
                        ) : (
                          <React.Fragment>
                            <div className="col-md-7 text-left">
                              <label>
                                Indirizzo del Posto Auto
                              </label>
                              <Field
                                onChange={(event, data) => onParkingSpaceAddressSelected(data)}
                                name="parkingSpaceAddress"
                                options={
                                  addresses.data.map(item => {
                                    return { label: item.name, value: item.id }
                                  })} component={VirtualizedSelectFormComponent} required />
                            </div>
                            {selectedParkingSpaceArea && <div className="col-md-3 text-left">
                              <label>Area Selezionata</label>
                              <div style={{ marginTop: 12 }}>
                                <h4>{selectedParkingSpaceArea.name}</h4>
                              </div>
                            </div>}
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </div>
                </fieldset>}
              {/* Fine Informazioni Residente */}

              {/* dati di residenza per i non residenti nel comune */}
              {!this.isResident(this.props.selectedCategories) && residenceDataForAllUsers &&
                <fieldset>
                  <div className="titolo-categoria">
                    <legend className="text-left">Informazioni di Residenza</legend>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-md-6 text-left">
                        <label>Indirizzo di Residenza</label>
                        <Field
                          component="input"
                          name="residenceAddress"
                          type="text"
                          placeholder="Indirizzo di residenza"
                          className="mainField"
                          required
                        />
                      </div>
                      <div className="col-md-2 text-left">
                        <label>N°</label>
                        <Field
                          parse={(value, name) => parseCivicNumber(value)}
                          component="input"
                          name="residenceCivicNumber"
                          type="text"
                          placeholder="N°"
                          className="mainField"
                          required />
                      </div>
                      <div className="col-md-2 text-left">
                        <label>Interno</label>
                        <Field
                          parse={(value, name) => parseCivicNumber(value)}
                          component="input"
                          name="residenceInside"
                          type="text"
                          placeholder="Interno"
                          className="mainField"
                          required />
                      </div>
                      <div className="col-md-6 text-left">
                        <label>Città</label>
                        <Field
                          name="residenceCity"
                          options={comuniWithoutCurrentCity}
                          component={VirtualizedSelectFormComponent}
                          required />
                      </div>
                      <div className="col-md-2 text-left">
                        <label>CAP</label>
                        <Field
                          component="input"
                          name="residenceCap"
                          type="text"
                          placeholder="CAP"
                          className="mainField"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>}
              {/* fine dati di residenza per i non residenti nel comune */}

              {/* Inizio Informazioni Azienda */}
              {this.isWorker(selectedCategories) &&
                <fieldset>
                  <div className="titolo-categoria">
                    <legend className="text-left">Informazioni Azienda</legend>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-md-6 text-left">
                        <label>Nome Azienda</label>
                        <Field
                          component="input"
                          name="companyName"
                          type="text"
                          placeholder="Nome Azienda..."
                          className="mainField"
                          required />
                      </div>

                      <div className="col-md-4 text-left">
                        <label>Partita IVA</label>
                        <Field
                          parse={(value, name) => parseVatNumber(value)}
                          component="input"
                          name="vatNumber"
                          type="text"
                          placeholder="0123456789"
                          className="mainField"
                          required />
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "1em" }}>
                      <label className="col-md-12 text-left">SEDE LEGALE</label>
                    </div>

                    <div className="row" style={{ marginTop: "1em" }}>

                      <div className="col-md-3 text-left">
                        <label>Città</label>
                        <Field
                          onChange={(event, data) => this.onCompanyLegalCitySelected(data)}
                          name="companyLegalCity"
                          options={comuni}
                          component={VirtualizedSelectFormComponent}
                          required />
                      </div>

                      <div className="col-md-5 text-left">
                        <label>Indirizzo</label>
                        {/*!this.isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity) &&*/
                          <Field
                            onChange={(event) => this.onCompanyLegalAddressChange(event)}
                            component="input"
                            name="companyLegalAddress"
                            type="text"
                            placeholder="Indirizzo ..."
                            className="mainField"
                            required />
                        }

                        {/*this.isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity) &&
                          <Field
                            name="companyLegalAddressId"
                            options={
                              addresses.data.map(item => {
                                return { label: item.name, value: item.id }
                              }
                              )}
                            component={VirtualizedSelectFormComponent}
                            required
                          />
                        */}
                      </div>

                      <div className="col-md-2 text-left">
                        <label>N°</label>
                        <Field
                          parse={(value, name) => parseCivicNumber(value)}
                          component="input"
                          name="companyLegalCivicNumber"
                          type="text"
                          placeholder="N°"
                          className="mainField"
                          required />
                      </div>

                      <div className="col-md-2 text-left">
                        <label>Interno</label>
                        <Field
                          parse={(value, name) => parseCivicNumber(value)}
                          component="input"
                          name="companyLegalInside"
                          type="text"
                          placeholder="Interno"
                          className="mainField"
                          required />
                      </div>

                      <div className="col-md-2 text-left">
                        <label>CAP</label>
                        <Field
                          parse={(value, name) => parseCap(value)}
                          component="input"
                          name="companyLegalCap"
                          disabled={this.isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity)}
                          type="text"
                          placeholder="CAP"
                          className="mainField"
                          required />
                      </div>

                    </div>

                    <div className="row" style={{ marginTop: "1em" }}>
                      <label className="col-md-12 text-left">AREA DI LAVORO</label>
                    </div>

                    <div className="row" style={{ marginTop: "1em" }}>

                      <div className="col-md-3 text-left">
                        <label>Seleziona Area</label>
                        <Field
                          name="workAreaId"
                          options={this.getAreasSelectOptions()}
                          component={VirtualizedSelectFormComponent}
                          placeholder="Seleziona Area di Lavoro"
                          onChange={(event, data) => this.onWorkAreaSelected(data)}
                          required
                        />
                      </div>

                      <div className="col-md-5 text-left">
                        <label>Indirizzo</label>
                        <Field
                          onChange={(event, data) => this.onWorkAddressSelected(data)}
                          name="workAddressId"
                          options={this.getAreaAddresses(workArea != null ? workArea.id : null)}
                          component={VirtualizedSelectFormComponent}
                          required
                        />
                      </div>

                      <div className="col-md-2 text-left">
                        <label>N°</label>
                        <Field
                          component="input"
                          name="workCivicNumber"
                          type="text"
                          placeholder="N°"
                          className="mainField"
                          required />
                      </div>

                      <div className="col-md-2 text-left">
                        <label>Interno</label>
                        <Field
                          parse={(value, name) => parseCivicNumber(value)}
                          component="input"
                          name="workInside"
                          type="text"
                          placeholder="Interno"
                          className="mainField"
                          required />
                      </div>

                    </div>

                  </div>

                </fieldset>}
              {/* Fine Informazioni Azienda */}

              {/* Inizio Informazioni Lavoratore/Titolare di Attività Economica */}
              {!this.isWorker(selectedCategories) && this.isWorkerPerson(selectedCategories) &&
                <fieldset>
                  <div className="titolo-categoria">
                    <legend className="text-left">Informazioni Dipendente</legend>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-md-6 text-left">
                        <label>Nome Azienda</label>
                        <Field
                          component="input"
                          name="companyName"
                          type="text"
                          placeholder="Nome Organizzazione..."
                          className="mainField"
                        />
                      </div>

                      <div className="col-md-6 text-left">
                        <label>Partita IVA</label>
                        <Field
                          parse={(value, name) => parseVatNumber(value)}
                          component="input"
                          name="vatNumber"
                          type="text"
                          placeholder="0123456789"
                          className="mainField"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 text-left">
                        <label>Indirizzo di Lavoro</label>
                        <Field
                          onChange={(event, data) => this.onWorkAddressSelected(data)}
                          name="workAddressId" options={
                            addresses.data.map(item => {
                              return { label: item.name, value: item.id }
                            }
                            )}
                          component={VirtualizedSelectFormComponent}
                          required
                        />
                      </div>
                      <div className="col-md-2 text-left">
                        <label>N°</label>
                        <Field
                          parse={(value, name) => parseCivicNumber(value)}
                          component="input"
                          name="workCivicNumber"
                          type="text"
                          placeholder="N°"
                          className="mainField"
                        />
                      </div>
                      <div className="col-md-2 text-left">
                        <label>Interno</label>
                        <Field
                          parse={(value, name) => parseCivicNumber(value)}
                          component="input"
                          name="workInside"
                          type="text"
                          placeholder="Interno"
                          className="mainField"
                        />
                      </div>
                      <div className="col-md-3 text-left">
                        <label>Area di Lavoro</label>
                        {workArea && <h4>{workArea.name}</h4>}
                        <Field
                          hidden={true}
                          disabled={true}
                          component="input"
                          name="workAreaId"
                          type="text"
                          placeholder="AreaId"
                          className="mainField"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>}
              {/* Fine Informazioni Lavoratore/Titolare di Attività Economica */}

              {loading && <div className="well">Creazione Utente in Corso...</div>}

              {userCreateError && <div className="panel panel-danger text-danger">
                <strong>
                  <br />
                  Errore nella creazione utente, verificare i dati e riprovare!
                  {userCreateResult.error === 'User already exists' &&
                    <div>Indirizzo eMail o Codice Fiscale già inserito in database</div>}
                  {userCreateResult === 'Missing Category' &&
                    <div>Selezionare la categoria di appartenza</div>}
                  {userCreateResult === 'Wrong email' &&
                    <div>Email specificata non valida</div>
                  }
                </strong>
              </div>}

              {userCreateSuccess && <div>
                <div className="row">
                  <div className="col-md-4 col-md-offset-4 ">
                    <div className="contrassegno">
                      <h4 className="contrassegno-h">Utente creato correttamente!</h4>
                      <p>
                        <NavLink
                          to={'/backoffice/users'}
                          className="mainBtn"
                          activeClassName={'active'}>Indietro</NavLink>
                        <NavLink
                          to={'/backoffice/users/' + userCreateResult.data.id}
                          className="mainBtn"
                          activeClassName={'active'}>Vai Al Profilo</NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>}
              <div className="row">
                <button type="submit" className="btn mainBtn">
                  Crea Utente
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'backofficeUserCreate'
})(BackOfficeUserCreateScreen))
