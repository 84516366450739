import React, {Component}     from 'react'
import {connect}              from 'react-redux'
import {NavLink}              from 'react-router-dom'
import BackOfficeUserScreen   from '../screens/BackOfficeUserScreen'
import SostaOnlineApiService  from '../services/SostaOnlineApiService'
import { FontAwesomeIcon }    from '@fortawesome/react-fontawesome'
import  * as solidIcons       from '@fortawesome/free-solid-svg-icons'
import { bindActionCreators } from 'redux'
import { reportActions }      from 'redux/modules/Report' 

import {actions as authActions} from '../redux/modules/Auth'


const mapStateToProps = (state) => {
  return {
    state: state.users,
    stateAuth: state.auth,
    stateReport : state.report
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class BackOfficeUserWaitingListTableContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      users: [],
      pagination: {
        total: 0,
        limit: 100,
        offset: 0
      },
      filters: {}
    }

    this.onAllClick        = this.onAllClick.bind(this)    
    this.onCreateUserClick = this.onCreateUserClick.bind(this)
    this.fetchUsers        = this.fetchUsers.bind(this)
    
  }

  componentDidMount () {
    this.setState({loading: true})    
    this.fetchUsers()
    this.fetchThingsToDo()
  }

  onCreateUserClick () {
    this.props.history.push('/backoffice/users/create')
  }

  fetchUsers (filterObj, limit, offset) {
    this.setState({loading: true})
    let string = ''
    for (let key in filterObj) {
      string = string + key + '=' + filterObj[key] + '&'
    }

    SostaOnlineApiService.fetchUsersWaitingList(string, limit || 100, offset || 0).then((data) => {      
      this.setState({
        users: data.data,
        pagination: data.pagination,
        loading: false
      })
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * --------------------------------------------------------------
   */
  fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo().then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  handleFilterChange (filter) {
    let newFilters = Object.assign({}, this.state.filters)
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter
    } else {
      delete newFilters[filter.column.key]
    }
    this.setState({ filters: newFilters })
  }

  onRowClick (rowIdx, row) {
    if (rowIdx <= -1 || !row) {
      return
    }

    var goTo=null

    if ( row.Marks && row.Marks.length > 0 )
      goTo = '?scrollTo=mark-' + row.Marks[0].id
    
    this.props.history.push('/backoffice/users/' + row.id+goTo)
  }

  onClearFilters () {
    // all filters removed
    this.setState({filters: {} })
  }

  onAllClick () {
    this.props.history.push('/backoffice/users')
  }  

  render () {
    return (
      <div>
        <div className="btn-group">
          <div className="btn-group" role="group">
            <NavLink exact className="tableBtn" activeClassName="active" to={'/backoffice/users'}>
              <FontAwesomeIcon size="1x" icon={solidIcons.faUsers} />&nbsp;&nbsp;Tutti
            </NavLink>
            <NavLink className="tableBtn" activeClassName="active" to={'/backoffice/users/waiting-list'}>
              <FontAwesomeIcon size="1x" icon={solidIcons.faClock} />&nbsp;&nbsp;In Attesa ({this.props.stateReport.waitingMarks ? this.props.stateReport.waitingMarks : 0})
            </NavLink>
            <button type="button" className="tableBtn" onClick={this.onCreateUserClick}>
            <FontAwesomeIcon size="1x" icon={solidIcons.faUserPlus} />&nbsp;&nbsp;Utente
            </button>
          </div>
        </div>
        <BackOfficeUserScreen
          mode={'waitingList'}
          loading={this.state.loading}
          fetchUsers={this.fetchUsers}
          onCreateUserClick={this.onCreateUserClick.bind(this)}
          users={this.state.users}
          rows={this.state.users}
          pagination={this.state.pagination}
          onRowClick={this.onRowClick.bind(this)}
          handleFilterChange={this.handleFilterChange.bind(this)}
          onClearFilters={this.onClearFilters.bind(this)} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeUserWaitingListTableContainer)
