import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'

export default class CategoriesFilter extends Component {
  static propTypes = {
    filter: PropTypes.any,
    onChange: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      Categories: []
    }
  }

  async componentDidMount () {
    this.setState({loading: true})
    const Categories = await SostaOnlineApiService.fetchFlatCategories()
    this.setState({
      Categories: Categories.data,
      loading: false
    })
  }

  render () {
    const {filter,  onChange}   = this.props
    const {loading, Categories} = this.state

    if (loading) {
      return (
        <div className="text-info">Caricamento in corso...</div>
      )
    }

    return (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : ''}
      >
        <option value="">Tutti</option>
        {Categories.sort((a, b) => (a.name > b.name ) ? 1 : -1).map((item, index) => {
          return (
            <option
              key={item.id + '-' + index}
              value={item.id}>
              {item.name}
            </option>
          )
        })}
      </select>
    )
  }
}
