import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import SostaOnlineApiService from '../services/SostaOnlineApiService'
import BackOfficeUserCreateScreen from '../screens/BackOfficeUserCreateScreen'
import {actions as addressActions} from '../redux/modules/Address'

import {actions as authActions} from '../redux/modules/Auth'
import { formatCityString, randomPassword, validateEmail } from 'libs/utils'
import { getComuni, getComuniWithoutCurrentCity } from 'libs/geo'


/**
 * ----------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    state: state.users,
    addresses: state.address,
    stateAuth: state.auth
  }
}

/**
 * ----------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    authActions:    bindActionCreators(authActions,   dispatch),
    addressActions: bindActionCreators(addressActions, dispatch)
  }
}


/**
 * ================================================================
 * 
 */
class BackOfficeUserCreateContainer extends Component {

  /**
   * ----------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      error: null,
      userTypes: [],
      categories: [],
      userCreateResult: {},
      areas: [],
      selectedCategories: [],
      selectedArea: null,
      selectedParkingSpaceArea: null,
      searchingFiscalCodeInitialized: false,
      currentFiscalCode: null,
      searchingFiscalCode: false,
      foundFiscalCode: null,
      selectedParkingSpaces: [],
      cities: {},
      province: {},
      city: {},
      residenceDataForAllUsers: false
    }   

    this.onSubmit                              = this.onSubmit.bind(this)
    this.onCategoriesChange                    = this.onCategoriesChange.bind(this)
    this.onAddressSelected                     = this.onAddressSelected.bind(this)
    this.onParkingSpaceAddressSelected         = this.onParkingSpaceAddressSelected.bind(this)
    this.onFiscalCodeChange                    = this.onFiscalCodeChange.bind(this)
    this.onSearchFiscalCodeAndFormFilling      = this.onSearchFiscalCodeAndFormFilling.bind(this)
    this.onAddParkingSpace                     = this.onAddParkingSpace.bind(this)
    this.onRemoveParkSpace                     = this.onRemoveParkSpace.bind(this)
    this.onMultipleParkingSpaceAddressSelected = this.onMultipleParkingSpaceAddressSelected.bind(this)
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} items 
   * @returns 
   */
  mapCategories (items) {
    return items.map(item => {
      let children = null
      if (item.Children.length > 0) {
        children = this.mapCategories(item.Children)
      }

      return {
        value: item.id,
        label: item.label.charAt(0).toUpperCase() + item.label.slice(1),
        children: children,
        resident: item.resident,
        worker: item.worker,
        workerPerson: item.workerPerson,
        parkingInDifferentPlace: item.parkingInDifferentPlace,
        multipleParkingSpace: item.multipleParkingSpace
      }
    })
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  async componentDidMount () {
    var publicConfigs = await SostaOnlineApiService.fetchPublicConfigs()    
      .catch( error => {
        console.log("ERROR",error)
      }) 

    var residenceDataForAllUsers = false;

    if ( publicConfigs && publicConfigs.data != null  ) {
      this.setState({
        city: publicConfigs.data.city,
        residenceDataForAllUsers:  publicConfigs.data.residenceDataForAllUsers 
      })

      residenceDataForAllUsers = publicConfigs.data.residenceDataForAllUsers 
    }
    
    var cities = await SostaOnlineApiService.fetchGeoCities()
      .catch( error => {
        console.error("ERROR",error)
      })
        
    if ( cities != null && cities.data != null ) {                          
      this.setState({ cities: cities.data })
    }

    var province = await SostaOnlineApiService.fetchGeoProvince()
      .catch( error => {
        console.error("ERROR",error)
      })
        
    if ( province != null && province.data != null ) {                          
      this.setState({ province: province.data })
    }   

    this.fetchUserCategories()
    this.fetchAreas()
    this.props.addressActions.fetchAddresses()
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  fetchUserTypes () {
    SostaOnlineApiService.fetchUserTypes().then((data) => {
      this.setState({userTypes: data.data})
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  fetchAreas () {
    SostaOnlineApiService.fetchAreas().then(data => {
      this.setState({areas: data.data})
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  fetchUserCategories () {
    SostaOnlineApiService.fetchUserCategories().then((data) => {
      this.setState({categories: this.mapCategories(data.data)})
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} addressId 
   * @returns 
   */
  getAddressById (addressId) {
    let address = this.props.addresses.data.filter(item => item.id === addressId)
    return address[0]
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} data 
   */
  onSubmit (data) {
    this.setState({loading: true, error: null, userCreateSuccess: false, userCreateError: false})
                
    let isResident     = this.state.selectedCategories.filter(item => item.resident ).length > 0
    let isWorker       = this.state.selectedCategories.filter(item => item.worker   ).length > 0
    let isWorkerPerson = this.state.selectedCategories.filter(item => item.workerPerson ).length > 0

    let password = randomPassword(8)

    let residenceCap                  = null
    let residenceCity                 = null
    let residenceCivicNumber          = null
    let residenceInside               = null
    let secondaryResidenceCity        = null
    let secondaryResidenceAddress     = null
    let secondaryResidenceCivicNumber = null
    let secondaryResidenceInside      = null
    let secondaryResidenceCap         = null
    let residenceAddress              = null
    let residenceAreaId               = null

    let workCity            = null
    let workCap             = null
    let workAddress         = null    
    let workAreaId          = null        
    let workCivicNumber     = null
    let workInside          = null

    let companyName             = null
    let vatNumber               = null
    let companyLegalAddress     = null
    let companyLegalCap         = null
    let companyLegalCity        = null
    let companyLegalCivicNumber = null
    let companyLegalInside      = null
    
    let sex                 = null
    let birthDate           = null
    let birthPlace          = null
    
    //console.log("DATA",data)

    // Aziende
    if ( isWorker ) {
      companyName             = data.companyName
      vatNumber               = data.vatNumber
      workCity                = formatCityString(this.state.city.longName , this.state.city.province )
      workCap                 = this.state.city.cap
      workCivicNumber         = data.workCivicNumber
      workInside              = data.workInside
      companyLegalCap         = data.companyLegalCap
      companyLegalCity        = data.companyLegalCity
      companyLegalCivicNumber = data.companyLegalCivicNumber
      companyLegalInside      = data.companyLegalInside

      // Indirizzo legale Azienda
      if ( data.companyLegalAddressId  ) {
        let address = this.props.addresses.data.find(item => { return item.id === data.companyLegalAddressId })

        if ( address != null ) {
          companyLegalAddress = address.name
        }
      }
      else {
        companyLegalAddress = data.companyLegalAddress
      }

      // indirizzo di lavoro aziendale
      if ( data.workAddressId  ) {
        let address = this.props.addresses.data.find(item => { return item.id === data.workAddressId })

        if ( address != null ) {
          workAddress = address.name          
        }
      }
      else if ( data.workAddress != null  ) {
        workAddress = data.workAddress
      }      

      workAreaId = data.workAreaId != null ? data.workAreaId : data.companyLegalAreaId
    }
    // Lavorratori dipendenti/titolari
    else if ( isWorkerPerson ) {
      workAreaId          = data.workAreaId
      workCivicNumber     = data.workCivicNumber
      workInside          = data.workInside
      companyName         = data.companyName
      vatNumber           = data.vatNumber
      workCity            = formatCityString(this.state.city.longName , this.state.city.province )
      workCap             = this.state.city.cap      

      if ( data.workAddressId ) {
        let address = this.props.addresses.data.find(item => { return item.id === data.workAddressId })

        if ( address ) {
          workAddress         = address.name          
        }
      }

    }
    
    // Residenti
    if ( isResident && data.residenceAddress ) {
      let address = this.props.addresses.data.find(item => { return item.id === data.residenceAddress })

      if ( address )
        residenceAddress = address.name

      residenceAreaId               = data.residenceAreaId
      residenceCap                  = this.state.city.cap 
      residenceCity                 = formatCityString(this.state.city.longName , this.state.city.province )
      residenceCivicNumber          = data.residenceCivicNumber
      residenceInside               = data.residenceInside
      secondaryResidenceCity        = data.secondaryResidenceCity
      secondaryResidenceAddress     = data.secondaryResidenceAddress
      secondaryResidenceCivicNumber = data.secondaryResidenceCivicNumber
      secondaryResidenceInside      = data.secondaryResidenceInside
      secondaryResidenceCap         = data.secondaryResidenceCap
      sex                           = data.sex
      birthDate                     = data.birthDate
      birthPlace                    = data.birthPlace
    }
    else if ( this.state.residenceDataForAllUsers ) {
      residenceAddress          = data.residenceAddress
      residenceCap              = data.residenceCap
      residenceCity             = data.residenceCity
      residenceCivicNumber      = data.residenceCivicNumber
      residenceInside           = data.residenceInside  
    }

    

    let obj = {
      password: password,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      fiscalCode: data.fiscalCode,

      residenceCity: residenceCity,
      residenceAddress: residenceAddress,
      residenceCivicNumber: residenceCivicNumber,
      residenceInside: residenceInside,
      residenceCap: residenceCap,
      residenceAreaId: residenceAreaId,
      secondaryResidenceCity: secondaryResidenceCity,
      secondaryResidenceAddress: secondaryResidenceAddress,
      secondaryResidenceCivicNumber: secondaryResidenceCivicNumber,
      secondaryResidenceInside: secondaryResidenceInside,
      secondaryResidenceCap: secondaryResidenceCap,

      sex:             sex,
      birthDate:       birthDate,
      birthPlace:      birthPlace,

      workCity:            workCity,
      workAddress:         workAddress,
      workCivicNumber:     workCivicNumber,
      workInside:          workInside,
      workCap:             workCap,
      workAreaId:          workAreaId,

      vatNumber:               vatNumber,
      companyName:             companyName,
      companyLegalAddress:     companyLegalAddress,
      companyLegalCivicNumber: companyLegalCivicNumber,
      companyLegalInside:      companyLegalInside,
      companyLegalCap:         companyLegalCap,
      companyLegalCity:        companyLegalCity,      

      userCategories: this.state.selectedCategories.map(item => item.value),
            
      parkSpaceAddressId: data.parkingSpaceAddress
    }

    if ( !validateEmail(data.email) )  {
      this.setState({
        error: 'Wrong email',
        userCreateResult: 'Wrong email',
        loading: false,
        userCreateSuccess: false,
        userCreateError: true
      })
    }    

    if ( ! this.state.selectedCategories || this.state.selectedCategories.length <= 0 ) {
      this.setState({
        error: 'Missing Category',
        userCreateResult: 'Missing Category',
        loading: false,
        success: false
      })
    }
    else {      

      SostaOnlineApiService.createUser(obj)
        .then(result => {
          this.setState({
            error: null,
            loading: false,
            userCreateSuccess: true,
            userCreateError: false,
            userCreateResult: result
          })
        })
        .catch(err => {
          
          console.log("ERROR",err)
          
          this.setState({
            error: 'Errore nella creazione utente, verificare i dati o riprovare più tardi',
            userCreateResult: err,
            loading: false,
            userCreateSuccess: false,
            userCreateError: true
          })
        })

    }

  }

  /**
   * ----------------------------------------------------------------
   * @param {*} selectedNodes 
   */
  onCategoriesChange (selectedNodes) {
    this.setState({
      selectedCategories: selectedNodes.map(item => ({
        label: item.label,
        value: item.value,
        resident: item.resident,
        worker: item.worker,
        workerPerson: item.workerPerson,
        parkingInDifferentPlace: item.parkingInDifferentPlace,
        multipleParkingSpace: item.multipleParkingSpace
      }))
    })
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} addressId 
   * @returns 
   */
  onAddressSelected (addressId) {
    if (!addressId) {
      this.setState({selectedArea: null})
      return
    }

    let address = this.props.addresses.data.filter(item => {
      return item.id === addressId
    })
    this.setState({selectedArea: address[0].Area})
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} addressId 
   * @returns 
   */
  onParkingSpaceAddressSelected (addressId) {
    if (!addressId) {
      this.setState({selectedParkingSpaceArea: null})
      return
    }

    let address = this.props.addresses.data.filter(item => {
      return item.id === addressId
    })
    this.setState({selectedParkingSpaceArea: address[0].Area})
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} addressId 
   * @param {*} index 
   * @returns 
   */
  onMultipleParkingSpaceAddressSelected (addressId, index) {
    const {selectedParkingSpaces} = this.state
    if (!addressId) {
      this.setState({selectedParkingSpaceArea: null})
      return
    }

    let address = this.props.addresses.data.filter(item => {
      return item.id === addressId
    })
    this.setState({selectedParkingSpaces: selectedParkingSpaces.map((item, i) => {
      if (i === index) {
        return ({
          ...item,
          area: address[0].Area
        })
      } else {
        return item
      }
    })})
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} e 
   */
  onFiscalCodeChange (e) {
    this.setState({
      currentFiscalCode: e.target.value
    })
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  async onSearchFiscalCodeAndFormFilling () {

    this.setState({
      foundFiscalCode: null,
      searchingFiscalCode: true,
      searchingFiscalCodeInitialized: true
    })

    let result

    try {
      result = await SostaOnlineApiService.fetchRegistry(this.state.currentFiscalCode)
    } 
    catch (err) {

      console.log("ERROR",err)
      
      this.setState({
        searchingFiscalCode: false,
        foundFiscalCode: null
      })
      return
    }

    this.setState({
      searchingFiscalCode: false,
      foundFiscalCode: result.data
    })
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  onAddParkingSpace () {
    const {selectedParkingSpaces} = this.state
    this.setState({selectedParkingSpaces: [...selectedParkingSpaces, {}]})
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} index 
   */
  onRemoveParkSpace (index) {
    const {selectedParkingSpaces} = this.state
    this.setState({
      selectedParkingSpaces: selectedParkingSpaces.filter((item, i) => i !== index)
    })
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  render () {

    const {foundFiscalCode} = this.state
    let residenceAddressValue = null
    let user = null

    if (foundFiscalCode != null ) {
      foundFiscalCode.category = null

      user = foundFiscalCode
    } 


    return (
      <BackOfficeUserCreateScreen
        initialValues={user ? {
          cid: foundFiscalCode.CategoryId,
          fiscalCode: user.fiscalCode,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          birthDate: user.birthDate,
          birthPlace: user.birthPlace,
          residenceAddress: residenceAddressValue,
          residenceCap: user.residenceCap,
          residenceCity: user.residenceCity,
          residenceCivicNumber: user.residenceCivicNumber,
          residenceInside: user.residenceInside,
          secondaryResidenceCity: user.secondResidenceCity,
          secondaryResidenceCap: user.secondResidenceCap,
          secondaryResidenceAddress: user.secondResidenceAddress,
          secondaryResidenceCivicNumber: user.secondaryResidenceCivicNumber,
          secondaryResidenceInside: user.secondaryResidenceInside,
          sex: user.sex,
          userCategories: (foundFiscalCode.category != null) ? [{
            label: foundFiscalCode.category.label,
            resident: foundFiscalCode.category.resident,
            worker: foundFiscalCode.category.worker,
            workerPerson: foundFiscalCode.category.workerPerson,
            parkingInDifferentPlace: foundFiscalCode.category.parkingInDifferentPlace,
            value: foundFiscalCode.category.value
          }] : null
        } : null}
        city={this.state.city}
        residenceDataForAllUsers={this.state.residenceDataForAllUsers}
        areas={this.state.areas}
        addresses={this.props.addresses}
        categoryOptions={this.state.categories}
        loading={this.state.loading}
        userCreateError={this.state.error}
        userCreateSuccess={this.state.userCreateSuccess}
        userCreateResult={this.state.userCreateResult}
        selectedParkingSpaceArea={this.state.selectedParkingSpaceArea}
        onCategoriesChange={this.onCategoriesChange}
        selectedCategories={this.state.selectedCategories}
        comuni={getComuni(this.state.cities)}
        comuniWithoutCurrentCity={getComuniWithoutCurrentCity(this.state.cities,this.state.city)}
        selectedArea={this.state.selectedArea}
        onAddressSelected={this.onAddressSelected}
        onParkingSpaceAddressSelected={this.onParkingSpaceAddressSelected}
        onFiscalCodeChange={this.onFiscalCodeChange}
        onSearchFiscalCodeAndFormFilling={this.onSearchFiscalCodeAndFormFilling}
        searchingFiscalCode={this.state.searchingFiscalCode}
        foundFiscalCode={this.state.foundFiscalCode}
        searchingFiscalCodeInitialized={this.state.searchingFiscalCodeInitialized}
        selectedParkSpaces={this.state.selectedParkingSpaces}
        onAddParkingSpace={this.onAddParkingSpace}
        onMultipleParkingSpaceAddressSelected={this.onMultipleParkingSpaceAddressSelected}
        onRemoveParkSpace={this.onRemoveParkSpace}
        onSubmit={this.onSubmit} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeUserCreateContainer)
