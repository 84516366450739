import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './BackOfficeMenuComponent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { ADMIN } from 'libs/roles'

export default class BackOfficeMenuComponent extends Component {

  render() {
    const {
      user,
      settingsMessages,
      resellers
    } = this.props

    var waitingResellers = (resellers ? Object.keys(resellers).length : 0)
    var conto = 0
    if ( user.role == 'admin' ) { conto = (conto + waitingResellers) }

    if (user == null) {
      return (<div className="panel panel-menu">loading</div>)
    }

    return (
      <div className="panel panel-menu">
        <ul className="list-group">
          <NavLink to={'/backoffice/users'} title="Utenti" className="list-group-item menu-item" activeClassName={'active'}>
            <FontAwesomeIcon size="1x" icon={solidIcons.faUsers} /><span className="hidden-xs ">&nbsp;&nbsp;Utenti</span>
          </NavLink>
          <NavLink to={'/backoffice/vehicles'} title="Veicoli" className="list-group-item menu-item" activeClassName={'active'}>
            <FontAwesomeIcon size="1x" icon={solidIcons.faCar} /><span className="hidden-xs ">&nbsp;&nbsp;Veicoli</span>
          </NavLink>
          <NavLink to={'/backoffice/marks'} title="Permessi" className="list-group-item menu-item" activeClassName={'active'}>
            <FontAwesomeIcon size="1x" icon={solidIcons.faWallet} /><span className="hidden-xs ">&nbsp;&nbsp;Permessi</span>
          </NavLink>
          <NavLink to={'/backoffice/receipts'} title="Ricevute" className="list-group-item menu-item" activeClassName={'active'}>
            <FontAwesomeIcon size="1x" icon={solidIcons.faReceipt} /><span className="hidden-xs ">&nbsp;&nbsp;Ricevute</span>
          </NavLink>
          {settingsMessages.enable &&
            <NavLink to={'/backoffice/messages'} title="Messaggi" className="list-group-item menu-item" activeClassName={'active'}>
              <FontAwesomeIcon size="1x" icon={solidIcons.faEnvelope} /><span className="hidden-xs ">&nbsp;&nbsp;Messaggi</span>
            </NavLink>
          }
          <NavLink to={'/backoffice/news'} title="News" className="list-group-item menu-item" activeClassName={'active'}>
            <FontAwesomeIcon size="1x" icon={solidIcons.faNewspaper} /><span className="hidden-xs ">&nbsp;&nbsp;News</span>
          </NavLink>
          <NavLink to={'/backoffice/reports'} title="Reports" className="list-group-item menu-item" activeClassName={'active'}>
            <FontAwesomeIcon size="1x" icon={solidIcons.faChartLine} /><span className="hidden-xs ">&nbsp;&nbsp;Reports</span>
          </NavLink>
          {user.role === ADMIN && <NavLink
            to={'/backoffice/settings'}
            title="Impostazioni"
            className="list-group-item menu-item"
            activeClassName={'active'}
          //{...this.props} // Pass props to NavLink
          >
            <FontAwesomeIcon size="1x" icon={solidIcons.faGears} />
            <span className="hidden-xs">&nbsp;&nbsp;Impostazioni</span>
          </NavLink>}
        </ul>
      </div>
    )
  }
}
