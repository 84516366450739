import React, {Component} from 'react'
import {NavLink} from "react-router-dom";

export default class RequestEnableSpidProfile extends Component {
    render() {
        return (<div className="main-container container">
            <div className="col-md-6 col-md-offset-3">
                <div className="panel panel-default registration-panel-default panel-border">
                    <div className="panel-body text-center">
                        <h3 className="title3" style={{marginBottom:"15"}}>Richiesta di Attivazione del Profilo Spid Inoltrata</h3>
                        <div className="panel-body flex-grow panel-2">Ti è stata inviata una mail all'indirizzo specificato in fase di registrazione per confermare l'attivazione </div>
                        <NavLink to={"/"} className="btn mainBtn">Torna alla Home</NavLink>
                    </div>
                </div>
            </div>
        </div>);
    }
}