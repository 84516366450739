import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SostaOnlineApiService from '../../services/SostaOnlineApiService'//
import moment from 'moment'
const thisRoute = 'reseller'

moment.locale('it')


export default class ResellerUserDetailMarkLogsContainer extends Component {
  static propTypes = {
    markId: PropTypes.number
  }

  constructor (props) {
    super(props)
    this.state = {
      logs: [],
      loading: false
    }

    this.fetchLogs = this.fetchLogs.bind(this)
  }

  componentDidMount () {
    this.fetchLogs()
  }

  fetchLogs () {
    this.setState({loading: true})

    SostaOnlineApiService.fetchMarkLogs(this.props.markId,thisRoute).then((data) => {
      this.setState({
        logs: data.data,
        loading: false
      })
    })
  }

  render () {
    const {logs} = this.state
    return (
      <div>
        {logs.map((item, index) => {
          return (
            <div key={index}>
              <strong>{moment(item.createdAt).format('L LT')}</strong>:{' '}
              {item.Operator.firstName + ' ' + item.Operator.lastName}{' '}
              <strong>{item.message}</strong> - {item.description}
              <hr style={{marginBottom: '5px', marginTop: '5px'}} />
            </div>
          )
        })}
      </div>
    )
  }
}


