import React, {Component} from 'react'
import { connect } from 'react-redux'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Oval } from 'react-loader-spinner'
import {actions as authActions} from '../../../redux/modules/Auth'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 

import { MARK_ENABLE_START_DATE, MARK_MODIFY_CODE, MARK_MODIFY_PRICE, MARK_RENEW_NOTIFY_DAYS } from '../../../libs/settings'
import { getValidPlateNumberContent } from 'libs/vehicles'
import BackOfficeSettingsMenuComponent from 'components/BackOfficeSettingsMenuComponent'
import { ADMIN } from 'libs/roles'
import packageJson from '../../../../package.json'


//--------------------------------------------------------------------------
const mapStateToProps = (state) => {
  return {
    backofficeValues: state.form.backofficeSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

//--------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

//--------------------------------------------------------------------------
class BackofficeSettings extends Component {

  //--------------------------------------------------------------------------
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      submitting: false,
      error: false,
      errorMsg: null,
      markModifyPrice: 0,
      markModifyCode: '',
      markRenewNotifyDays: 0,            
      settings: []
    }
    
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  //--------------------------------------------------------------------------
  async componentDidMount () {
    this.setState({loading: true})
    
    
    SostaOnlineApiService.fetchSettings()
    .then( settings => {       

      const markModifyPrice     = settings.data.filter( element => element.name === MARK_MODIFY_PRICE  ) 
      const markRenewNotifyDays = settings.data.filter( element => element.name === MARK_RENEW_NOTIFY_DAYS  )    
      const markModifyCode      = settings.data.filter( element => element.name === MARK_MODIFY_CODE  )
      const markEnableStartDate = settings.data.filter( element => element.name === MARK_ENABLE_START_DATE  )
      
      this.setState({
        settings: settings.data,        
        markModifyPrice: markModifyPrice.length > 0 ? markModifyPrice[0].value : 0,
        markModifyCode: markModifyCode.length > 0 ? markModifyCode[0].value : 0,
        markRenewNotifyDays: markRenewNotifyDays.length > 0 ? markRenewNotifyDays[0].value : 0,        
        markEnableStartDate: markEnableStartDate.length > 0 && markEnableStartDate[0].value == "1" ? true : false,
        loading: false,
        error: false,
        errorMsg: null
      })

    })
    .catch(
      error => {
        console.log("ERR",error)
        this.setState({
          loading: false,
          submitting: false,
          error: true,
          errorMsg: error.error,
          markModifyPrice: 0,
          markModifyCode: '',
          markRenewNotifyDays: 0,                    
          settings: []
        })

        console.log("ERROR",error)

      }      
    )

    this.fetchThingsToDo()
    

  }

  //--------------------------------------------------------------------------
  fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo().then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }   

  //--------------------------------------------------------------------------
  async onSubmit (e) {
    e.preventDefault()
    this.setState({submitting: true, error: null, success: false , errorMsg: null })
    var settings = []    

    if ( this.state.markModifyCode != null ) {
      settings.push({ 
        name:  MARK_MODIFY_CODE,
        value: this.state.markModifyCode
      })
    }

    if ( this.state.markModifyPrice != null ) {
      settings.push({ 
        name:  MARK_MODIFY_PRICE,
        value: this.state.markModifyPrice
      })
    }

    if ( this.state.markRenewNotifyDays != null ) {      
      settings.push({ 
        name:  MARK_RENEW_NOTIFY_DAYS,
        value: this.state.markRenewNotifyDays
      })
    }

    if ( this.state.markEnableStartDate != null ) {      
      settings.push({ 
        name:  MARK_ENABLE_START_DATE,
        value: this.state.markEnableStartDate
      })
    }

    const result = await SostaOnlineApiService.updateSettings(settings).catch( error => {       
      this.setState({submitting: false, error: true, success: false, errorMsg: error.data.error })
    })    
    
    if ( result && result.result === 'ok') {
      this.setState({submitting: false, error: false, success: true, errorMsg: null})
    } else {
      this.setState({submitting: false, error: true, success: false})
    }
    
  }

  //--------------------------------------------------------------------------
  handleChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  //--------------------------------------------------------------------------
  checkMarkRenewNotifyDays(value) {
    if ( value >= 1 && value <= 15 ) 
      this.setState({ 'markRenewNotifyDays' : value}) 
    else 
      this.setState({ 'markRenewNotifyDays' : 15})  
  }

  //--------------------------------------------------------------------------
  checkMarkEnableStartDate(value) {
    this.setState({ 'markEnableStartDate' : value})           
  }

  //--------------------------------------------------------------------------
  render () {        

    const {      
      loading,
      error,
      errorMsg,
      markModifyPrice,
      markModifyCode,
      markRenewNotifyDays,      
      markEnableStartDate,
      submitting,
      success
    } = this.state


    if (loading) {
      return (
        <React.Fragment>
        <h3><strong>Caricamento in corso...</strong></h3>
        <div className="row" style={{padding: "2em", height: "40em"}}>
          <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />                  
        </div>
        </React.Fragment>
      )
    } 
    if ( error )    {
      return (<div className="text-primary">{errorMsg}</div>)
    }

    if ( this.props.auth.user.role != ADMIN ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }    

    return (
      <React.Fragment>
      <BackOfficeSettingsMenuComponent {...this.props}  />
      <form onSubmit={this.onSubmit}>
        <div className="row">
          <div className="pull-left" style={{ marginLeft: "1em" , marginRight: "1em" , marginTop: "1em", height: "2em" }}>
            
          </div>
        </div>        
        <div className="box" style={{textAlign: 'left'}}>                              

          <div className="form-group row">
            <label className="col-sm-8 col-form-label">Versione</label>
            <div className="col-sm-2">                
                <strong>{packageJson.version}</strong>
            </div>
            <div className="col-sm-2">
                
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-8 col-form-label">Costo dell'operazione di modifica VEICOLO ( per ogni permesso )</label>
            <div className="col-sm-2">                
                <input
                  onChange={(e) => { this.setState({ 'markModifyPrice' : e.target.value}) }}
                  value={markModifyPrice}
                  name="markModifyPrice"
                  type="number"
                  min={0}
                  step={0.01}
                  className="form-control"
                />               
            </div>
            <div className="col-sm-2">
                ( € )
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-8 col-form-label">Codice dell'operazione di modifica VEICOLO</label>
            <div className="col-sm-2">                
                <input
                  onChange={(e) => { this.setState({ 'markModifyCode' : getValidPlateNumberContent(e.target.value)}) }}
                  value={markModifyCode}
                  name="markModifyCode"
                  type="string"
                  className="form-control"
                />               
            </div>
            <div className="col-sm-2">
                ( Alfanumerico )
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-8 col-form-label">Giorni prima della SCADENZA dei permessi in cui inviare la notifica</label>
            <div className="col-sm-2">                
                <input  onChange={(e) => this.checkMarkRenewNotifyDays(e.target.value) }
                  value={markRenewNotifyDays}
                  name="markRenewNotifyDays"
                  type="number"                    
                  className="form-control"
                />               
            </div>
            <div className="col-sm-2">
                ( Numero giorni )
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-8 col-form-label"> Abilitazione scelta data inizio validità permesso</label>
            <div className="col-sm-2">                
                <input  onChange={(e) => this.checkMarkEnableStartDate(e.target.checked) }
                  checked={markEnableStartDate}                  
                  name="markEnableStartDate"
                  type="checkbox"                    
                  className="form-control"
                />               
            </div>
            <div className="col-sm-2">

            </div>
          </div>
        
          {submitting && <div className="text-primary">
            Salvataggio in corso
          </div>}

          {error && <div className="text-danger">
            Si sono verificati degli errori
          </div>}

          {success && <div className="text-success">
            Salvataggio completato correttamente
          </div>}

          <p className="margin-btn-p">
            <button type="submit" className="btn mainBtn">
              <FontAwesomeIcon icon={regularIcons.faSave} />&nbsp;&nbsp;Salva
            </button>
          </p>

        </div>
      </form>
      </React.Fragment>    
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackofficeSettings)
