import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { getSubjectDescription, getSubMessageType } from 'libs/messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import Parser from 'html-react-parser';
import { getHtmlMsgFromString } from 'libs/news';

moment.locale('it')

class MessageModal extends Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    msg: PropTypes.object,
    onClose: PropTypes.func.isRequired,    
    onReplyMessage: PropTypes.func.isRequired,    
    user: PropTypes.object.isRequired
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      subject: null,      
      content: null,
      checkErrors: null,
      sendErrors: null 
    }

    this.sendMessage = this.sendMessage.bind(this)
    this.checkFields = this.checkFields.bind(this)
   
  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []   

    if ( this.state.content == null || this.state.content.length <= 10 ) {
      errors.push("Il messaggio da inviare è vuoto")
    }

    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  sendMessage() {
    //console.log("SEND MESSAGE")

    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }
    
    var msg = {      
      content: this.state.content
    }

    SostaOnlineApiService.replyMessageMe(msg,this.props.msg.id)
    .then( result => {
      //console.log("RESULT",result)

      if ( this.props.onReplyMessage != null ) {
        this.props.onReplyMessage(msg)
      }

      if ( this.props.onClose )
        this.props.onClose()
    })
    .catch( error => {
      console.log("ERROR",error)
      this.setState({ sendErrors: "Errore durante l'invio del messagggio, riprovare pù tardi!" })
    })

    
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSelectedMessageType() {
    return getSubMessageType(this.state.messageType,this.state.subMessageType)    
  }


  /**
   * --------------------------------------------------------------
   */
  close() {
    this.props.onClose()

    this.props.initialize({       
      content: null
    });
  }
  

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,
      onClose,
      msg,
      user
    } = this.props

    const {
      sendErrors,
      checkErrors      
    } = this.state

    if ( msg == null  ) 
    return (
      <Modal
        open={show}
        onClose={onClose}
        closeOnEsc={true}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        center
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faEnvelope} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>MESSAGGIO</strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4> 

        <div className="col-md-12">
          <div className="app-modal-body">
            <div className="row">          
              Loading ...
            </div>
          </div>
        </div>
      </Modal>
    )
      
    return (
      <Modal
        open={show}
        onClose={onClose}
        closeOnEsc={true}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        center
      >
        <h5 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faEnvelopeOpen} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>MESSAGGIO</strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ () => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h5> 

        <div className="col-md-12">
          <div className="app-modal-body">

            <div className="row">
                <h5 style={{ marginTop: 0 , marginBottom: 10 }}><strong>Da:&nbsp;&nbsp;</strong> { msg.fromId !== user.id ? 'ASSISTENZA' : 'ME' }</h5>
            </div>
            <div className="row">
                <h5 style={{ marginTop: 0 , marginBottom: 10 }}><strong>A:&nbsp;&nbsp;</strong> { msg.toId !== user.id ? 'ASSISTENZA' : 'ME'  }</h5>
            </div>
            <div className="row">
                <h5 style={{ marginTop: 0 , marginBottom: 10  }}><strong>Soggetto:&nbsp;&nbsp;</strong> { getSubjectDescription(msg.subject) }</h5>
            </div>                  
            <div className="row">
                <h5 style={{ marginTop: 0 , marginBottom: 10 }} ><strong>Data:&nbsp;&nbsp;</strong>{ moment(msg.createdAt).format('LLLL') }</h5>
            </div>
            <div className="row">
                <h5 style={{ marginTop: 10 , marginBottom: 5 }}><strong>Messaggio:</strong></h5>              
                <h5 style={{ 
                  marginTop: 0 , 
                  marginBottom: 10, 
                  color: 'grey', 
                  fontWeight: 'bold' , 
                  borderStyle: "solid" , 
                  borderWidth: "1px",
                  borderColor: "grey",
                  padding: "5px",
                  borderRadius: "5px"
                  }}>{getHtmlMsgFromString(msg.content)}</h5>              
            </div>     

            { user.id === msg.toId && 
            <React.Fragment>
              <div className="row">              
                  <h5 style={{zIndex: 1, marginTop: 10 , marginBottom: 5 }}><strong>Rispondi:</strong></h5>              
                  <Field 
                    style={{                       
                      color: "grey", 
                      fontWeight: "bold" ,
                      height: "200px"
                      }}
                    name="content" 
                    className="form-control" 
                    component="textarea" 
                    onChange={ e => { this.setState({content: e.target.value}) }}
                    required />                              
                  <br/>
              </div>             

              <div className="row">
                { sendErrors && 
                  <h5 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {sendErrors}
                  </h5>
                }
                { checkErrors && checkErrors.length > 0 && 
                  <h5 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                  </h5>
                }
              </div>

              <div className="row">
                <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  <Button className="btn alertBtn" onClick={() => this.close() }>Annulla</Button>{' '}
                  <Button className="btn mainBtn"  onClick={() => this.sendMessage()} >Invia</Button>
                </div>
              </div>          
            </React.Fragment>
            }

            { user.id !== msg.toId &&  
              <div className="row">
                <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  <Button className="btn alertBtn" onClick={() => this.close() }>Esci</Button>{' '}                  
                </div>
              </div>  
            }
          </div>
        </div>             
      </Modal>
    )
  }
}

export default reduxForm({ form: 'MessageModal'})(MessageModal)
