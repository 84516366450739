/**
 *  LIBRERIA DI FUNZIONI PER I COMUNI E LE PROVINCE
 */

const { formatCityString } = require("./utils")

const GEO_FOREIGN_COUNTRY     = "Nazione Estera (Estero)"


 /**
   * ----------------------------------------------------------------
   * @param {*} cities 
   * @param {*} cityName 
   * @param {*} provinceLabel 
   * @returns 
   */
 function getCity(cities,cityName,provinceLabel) {

    var city = null

    var foundCities = cities.filter( city => city.name == cityName && city.Province.label == provinceLabel )

    if ( foundCities != null && foundCities.length > 0 )
      city = foundCities[0]

    return city

  }

  /**
   * ----------------------------------------------------------------
   * @param {*} cities 
   * @returns 
   */
  function getComuni(cities) {
    
    var comuni       = []
    var foreignCountry = GEO_FOREIGN_COUNTRY

    if ( cities != null && cities.length > 0 ) {
            
      comuni.push({
        label: foreignCountry,
        value: foreignCountry
      })
      
      cities.map( comune => {
        const label = formatCityString(comune.name,comune.Province.label)        
        comuni.push({
          label: label,
          value: label,
        })
      })      

    }       

    return comuni

  }

  /**
   * ----------------------------------------------------------------
   * @param {*} cities 
   * @returns 
   */
  function getComuniWithoutCurrentCity(cities,city) {

    var comuniWithoutCurrentCity = []    
    var foreignCountry             = GEO_FOREIGN_COUNTRY

    if ( cities != null && cities.length > 0 ) {
      
      comuniWithoutCurrentCity.push({
        label: foreignCountry,
        value: foreignCountry
      })      
      
      cities.map( comune => {
        const label = formatCityString(comune.name,comune.Province.label)

        if ( comune.name !== city.longName ) {
          comuniWithoutCurrentCity.push({
            label: label,
            value: label,        
          })
        }       
      })      

    }       
    
    return comuniWithoutCurrentCity

  }


module.exports = {   
    GEO_FOREIGN_COUNTRY,
    getCity,
    getComuni,
    getComuniWithoutCurrentCity
}
