import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import Notifications from 'react-notify-toast'
import MainNavigatorScreen from './MainNavigatorScreen'
import UserMainContainer from '../containers/UserMainContainer'
import BackOfficeContainer from '../containers/BackOfficeContainer'
import SplashContainer from '../containers/Splash/SplashContainer'
import SplashContainerReseller from '../containers/Reseller/Splash/SplashContainerReseller'
import RegistrationContainer from '../containers/Registration/RegistrationContainer'
import ResellerRegistrationContainer from 'containers/Reseller/Registration/ResellerRegistrationContainer'
import PasswordRecoveryContainer from '../containers/PasswordRecoveryContainer'
import CookiePolice from '../containers/Cookie/CookiePolice'
import Privacy from '../containers/Privacy/Privacy'
import MainRedirectContainer from 'containers/MainRedirectContainer'
import { bindActionCreators } from 'redux'
import { actions as authActions } from '../redux/modules/Auth'
import { ADMIN, OPERATOR, RESELLER } from 'libs/roles'
import RegistrationEmailConfirmed from '../containers/Registration/RegistrationEmailConfirmed/RegistrationEmailConfirmed'
import RegistrationSendConfirmEmail from '../containers/Registration/RegistrationSendConfirmEmail/RegistrationSendConfirmEmail';
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import ResellerMainContainer from 'containers/ResellerMainContainer'

/**
 * --------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}

/**
 * --------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  }
}

/**
 * --------------------------------------------------------------
 * @param {*} param0 
 * @returns 
 */
const OnlyPublicRoute = ({ component: Component, auth: auth, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      !auth.token
        ? (<Component {...props} />)
        : (<Redirect to={{ pathname: '/', state: { from: props.location } }} />))
    }
  />
)

/**
 * --------------------------------------------------------------
 * 
 */
class MainContainer extends Component {

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  componentDidMount() {
    SostaOnlineApiService.setListenerLogout(this.props.authActions.logout)
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {
    if (this.props) {
      const {
        stateAuth
      } = this.props
      //console.log('MainContainer Props:', this.props);
      //console.log ( 'MainContainer stateAuth.user.budget:', this.props.stateAuth.user.budget)

      return (
        <MainNavigatorScreen auth={stateAuth}>
          <Notifications />
          <Switch>
            <Route path="/privacy" component={Privacy} />
            <Route path="/cookie-police" component={CookiePolice} />
            <Route path="/register/confirmMail" component={RegistrationEmailConfirmed} />
            <Route path="/register/sendConfirmMail" component={RegistrationSendConfirmEmail} />
            <OnlyPublicRoute path="/splash" auth={stateAuth} component={SplashContainer} />
            <OnlyPublicRoute path="/splash-reseller" auth={stateAuth} component={SplashContainerReseller} />
            <OnlyPublicRoute path="/register" auth={stateAuth} component={RegistrationContainer} />
            <OnlyPublicRoute path="/register-reseller" auth={stateAuth} component={ResellerRegistrationContainer} />
            <Route path="/forgot-password" component={PasswordRecoveryContainer} />
            {stateAuth && stateAuth.user && (stateAuth.user.role === RESELLER) &&
              <Route path="/reseller" component={ResellerMainContainer} forceRefresh={true} />
            }
            {stateAuth && stateAuth.user && (stateAuth.user.role === ADMIN || stateAuth.user.role === OPERATOR) &&
              <Route path="/backoffice" component={BackOfficeContainer} forceRefresh={true} />
            }
            {stateAuth && stateAuth.user && (stateAuth.user.role !== ADMIN && stateAuth.user.role !== OPERATOR && stateAuth.user.role !== RESELLER) &&
              <Route path="/user-area" component={UserMainContainer} forceRefresh={true} />
            }
            <MainRedirectContainer />
          </Switch>
        </MainNavigatorScreen>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer)
