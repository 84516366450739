import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import "react-responsive-modal/styles.css";
import classNames from 'classnames'
import SostaOnlineApiService from '../../services/SostaOnlineApiService'
const fileDownload = require('js-file-download')
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { Oval } from 'react-loader-spinner'
import NewDocumentModal from './NewDocumentModal';
import { getWindowWidth } from 'libs/utils';

export default class FileManager extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    files: PropTypes.array.isRequired,
    showUploadForm: PropTypes.bool.isRequired,
    toggleShowUploadForm: PropTypes.func.isRequired,
    onNewDocumentSubmit: PropTypes.func.isRequired,
    onRowSelected: PropTypes.func.isRequired,
    selectedRows: PropTypes.array.isRequired,
    onFileSubmitSelected: PropTypes.func,
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.handleResize = this.handleResize.bind(this)

    this.state = {
      maxWidth: 0,
      columns: [
        {
          Header: (
            <div className="documents-browser-header-list">
              Nome
            </div>
          ),
          className: 'no-horizontal-border',
          accessor: 'name',
          filterable: true,
          sortable: true,
          filterMethod: (filter, row) => (row[filter.id].toLowerCase().includes(filter.value.toLowerCase())),
          Cell: row => {
            const isActive = row && this.props.selectedRows.indexOf(row.index) !== -1
            return (
              <div
                className={classNames('documents-browser-file-cell', { 'white-text': isActive })}
                onClick={async (e) => {
                  if (!row) {
                    return
                  }
                  await this.props.onRowSelected(row.index, row.value)
                  await this.props.onFileSubmitSelected(e)
                }}
              >
                {row.value}
              </div>
            )
          }
        },
        {
          Header: (
            <div className="documents-browser-header-list">
              Tipo
            </div>
          ),
          className: 'no-horizontal-border',
          accessor: 'DocumentType.label',
          filterable: false,
          sortable: true,
          Cell: row => {
            const isActive = row && this.props.selectedRows.indexOf(row.index) !== -1
            return (
              <div
                className={classNames('documents-browser-file-cell', { 'white-text': isActive })}
                onClick={async (e) => {
                  if (!row) {
                    return
                  }
                  await this.props.onRowSelected(row.index, row.value)
                  await this.props.onFileSubmitSelected(e)
                }}
              >
                {row.value}
              </div>
            )
          }
        },
        {
          Header: (
            <div className="documents-browser-header-list">
            </div>
          ),
          className: 'no-horizontal-border',
          accessor: '',
          filterable: false,
          sortable: true,
          Cell: row => {
            const isActive = row && this.props.selectedRows.indexOf(row.index) !== -1
            return (
              <div className={classNames('documents-browser-file-cell-nopad', { 'white-text': isActive })}>
                <a onClick={() => this.onDocumentDownload(row.value)} className="btn mainBtn">
                  <span className="visible-md visible-lg visible-xl hidden-xs  visible-sm"><FontAwesomeIcon size="1x" icon={solidIcons.faDownload} />&nbsp;&nbsp;Download</span>
                  <span className="hidden-md  hidden-lg  hidden-xl  visible-xs hidden-sm" ><FontAwesomeIcon size="1x" icon={solidIcons.faDownload} /></span>
                </a>
              </div>
            )
          }
        }
      ]
    }
  }

  // ----------------------------------------------------------------
  //
  //
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  // ----------------------------------------------------------------
  //
  //
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  // ----------------------------------------------------------------
  //
  //
  handleResize() {
    var maxWidth = Math.floor(document.getElementById("filesTable").getBoundingClientRect().width)
    var columns = this.state.columns

    if (maxWidth > 0) {

      var thirdColWidth = maxWidth > 740 ? 150 : 60

      if (maxWidth > thirdColWidth) {
        var width = maxWidth - thirdColWidth
        var colsWidth = Math.floor(width / 2)
        columns[0].maxWidth = colsWidth
        columns[1].maxWidth = colsWidth
        columns[2].maxWidth = thirdColWidth
      }

    }

    this.setState({ columns: [...columns] })
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} doc 
   */
  onDocumentDownload(doc) {
    if (doc != null) {
      SostaOnlineApiService.downloadDocumentMe(doc.id).then(data => {
        fileDownload(data, doc.path, doc.mimeType)
      })
    }
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  render() {
    const {
      files,
      loading,
      error,
      showUploadForm,
      toggleShowUploadForm,
      onNewDocumentSubmit,
      selectedRows
    } = this.props

    const {
      columns
    } = this.state


    if (loading) {
      return (
        <div>
          <div className="row"><h4>Caricamento in corso...</h4></div>
          <div className="row" style={{ padding: "2em", height: "40em" }}>
            <Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />
          </div>
        </div>
      )
    }

    if (error) {
      return (
        <div>
          <h5 className="text-danger">{error}</h5>
        </div>
      )
    }

    return (
      <div className="col-md-12" style={{ minHeight: '350px', padding: '0px', margin: '0px' }}>

        <div className="col-md-12" style={{ margin: '1em', marginLeft: "0", paddingLeft: "0" }}>
          <a onClick={toggleShowUploadForm} className="mainBtn">
            {!showUploadForm ? 'Carica Nuovo Documento' : 'Annulla Caricamento'}
          </a>
        </div>

        <div id="filesTable" className="col-md-12" style={{ padding: '0px', margin: '0px' }}>
          <ReactTable
            minRows={6}
            loading={loading}
            noDataText={'Nessun Documento Presente'}
            className="-highlight no-horizontal-border"
            data={files}
            showPagination={false}
            style={{ maxHeight: '500px' }}
            defaultPageSize={files.length}
            loadingText={<Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />}
            getTrGroupProps={(state, rowInfo, column) => {
              const isActive = rowInfo && selectedRows.indexOf(rowInfo.index) !== -1
              const activeStyle = {}

              if (isActive) {
                activeStyle.backgroundColor = '#2196F3'
              }
              return {
                style: {
                  borderBottom: 0,
                  ...activeStyle
                }
              }
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              const isActive = rowInfo && selectedRows.indexOf(rowInfo.index) !== -1
              const activeStyle = {}
              if (isActive) {
                activeStyle.color = '#FFFFF'
              }
              return {}
            }}
            columns={columns} />
        </div>

        <NewDocumentModal
          show={showUploadForm}
          onClose={toggleShowUploadForm}
          onUploadDocument={onNewDocumentSubmit}
        />

      </div>
    )
  }
}
