import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import "react-responsive-modal/styles.css";
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { USER, RESELLER } from 'libs/roles';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report'
import { actions as authActions } from 'redux/modules/Auth'
import { Field, reduxForm } from 'redux-form';
import VirtualizedSelectFormComponent from 'components/redux-form/VirtualizedSelectFormComponent';
import './ResellerUserContainer.css'



moment.locale('it')

/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    stateReport: state.report
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions: bindActionCreators(authActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}


class ResellerUserContainer extends Component {

  static propTypes = {
    userId: PropTypes.number,
    users: PropTypes.array.isRequired
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {
      userId: null,
      user: this.getNullAdminUser(),
      checkErrors: null,
      sendErrors: null,
    }

    this.getNullAdminUser = this.getNullAdminUser.bind(this)
    this.getResellersToSelect = this.getResellersToSelect.bind(this)
  }
  /**
     * --------------------------------------------------------------
     * @returns 
     */
  getNullAdminUser() {
    var nullUser = { id: null, firstName: '', lastName: '', email: '', role: USER }

    return nullUser
  }

  stateInit() {
    this.setState({
      userId: null,
      user: this.getNullAdminUser(),
      checkErrors: null,
      sendErrors: null,
    })

  }

  /**
   * --------------------------------------------------------------
   */
  async componentDidMount() {
    this.stateInit()

    // Chiama la funzione per prendere la lista USERS
    try {
      //const response = await SostaOnlineApiService.fetchPureUsersListReseller();
      const response = await SostaOnlineApiService.fetchActiveUsers();
      const userList = response.data;
      this.setState({ users: userList }); // Atualiza lo state con la lista dei USERS
    } catch (error) {
      console.error("Errore nella ricerca utenti:", error);
    }

    this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {

    if (prevProps.userId != this.props.userId) {
      this.stateInit()
      this.fetchData()
    }

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchData() {

    var userId = null

    if (this.props.userId != null) {
      userId = this.props.userId
    }
    else if (this.state.userId != null) {
      userId = this.state.userId
    }
    else {
      this.setState({ user: this.getNullAdminUser() })
    }

    if (userId != null) {
      var user = await SostaOnlineApiService.fetchUserReseller(userId)

      if (user != null && user.data != null) {
        this.setState({ user: user.data })
      }
      else {
        this.setState({ user: this.getNullAdminUser() })
      }
    }

  }

  /**
   * --------------------------------------------------------------
   * 
   */

  getResellersToSelect() {
    const { users } = this.state; // Ottiene la lista dello state dei USER

    // Verifica se la lista users esiste e se è un array
    if (users && Array.isArray(users)) {
      return users
        .filter(u => u.role == USER)
        .map(user => ({
          label: `${user.firstName} ${user.lastName} ${user.fiscalCode}`,
          value: user.id
        }));
    } else {
      return []; // Ha il return vuoto nel caso in cui non ci sono USERS
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} id 
   */
  async setUser(id) {
    await this.setState({ userId: id })
    this.fetchData()
  }

  backToHome(evt) {
    if (this.props.history) {
      this.props.history.push("/reseller/home")
    } else {
      window.location = "/reseller/home"
    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {

    const {
      userId
    } = this.props

    //console.log("AREA VALUES",user)

    if (this.props.auth.user.role != RESELLER) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    if (this.props.auth.user.role == RESELLER) {
      const firstName = this.props.auth.user.firstName
      const lastName = this.props.auth.user.lastName
      //console.log(`Il Nome dell user reseller é: ${firstName} ${lastName}`)
    }

    return (
      <React.Fragment>
        {this.props.auth.user.role != RESELLER &&
          <div className="col-md-12">
            <div className="row">
              <div className="text-center" style={{ margin: "1em" }}>
                <div className="row"><h2>Caricamento in corso...</h2></div>
              </div>
            </div>
          </div>
        }

        {this.props.auth.user.role == RESELLER &&
          <div className="col-md-12 container-modal-reseller-user">
            <div className="app-modal-body">
              {userId == null &&
                <div className="modal-active col-md-12" tabindex="-1" role="dialog">
                  <div className="modal-dialog modal-lg" role="document" style={{ backgroundColor: "#174C88", borderRadius: "5px" }}>
                    <div className="modal-content">
                      <div className="modal-header input-filter" style={{ backgroundColor: "#174C88" }}>
                        <FontAwesomeIcon style={{ color: "white" }} size="2x" icon={solidIcons.faUsers} /><span className="span-title-modal-utente">&nbsp;&nbsp;CERCA/CREA UTENTE</span>
                      </div>
                      <div className="modal-body input-filter">
                        <div className="row">
                          <div className="col-xs-12 field-container-reseller">
                            <Field
                              name="userId"
                              options={this.getResellersToSelect()}
                              component={VirtualizedSelectFormComponent}
                              onChange={value => { this.setUser(value) }}
                              placeholder={<div><FontAwesomeIcon size="1x" icon={solidIcons.faFilter} />&nbsp;&nbsp;Filtra per utente...</div>}
                              required />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="col-md-offset-1 col-md-10 col-md-offset-1 button">
                          <Button onClick={(evt) => { this.backToHome(evt) }} className="btn-modal-utente btn-home"> Torna alla Home </Button>

                          {this.state.userId === null ?
                            <NavLink to={'/reseller/users/create'} className="btn-modal-utente" activeClassName={'active'}>
                              <FontAwesomeIcon style={{ color: "white" }} size="1x" icon={solidIcons.faUserPlus} />&nbsp;&nbsp;CREA UTENTE
                            </NavLink>
                            :
                            <NavLink to={`/reseller/users/${this.state.userId}`} className="btn-modal-utente" activeClassName={'active'}>
                              <FontAwesomeIcon style={{ color: "white" }} size="1x" icon={solidIcons.faPassport} />&nbsp;&nbsp;CREA/RINNOVA PERMESSO
                            </NavLink>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'BackofficeEditAdminUser' })(ResellerUserContainer))
