import React, {Component}     from 'react'
import {connect}              from 'react-redux'
import moment                 from 'moment'
import { scroller }           from 'react-scroll'
import SostaOnlineApiService  from '../../services/SostaOnlineApiService'
import UserArea               from './UserArea'
import { bindActionCreators } from 'redux'
import {actions as authActions} from '../../redux/modules/Auth'
import { reportActions } from '../../redux/modules/Report' 
import { Oval } from 'react-loader-spinner'

const fileDownload = require('js-file-download')

const mapStateToProps = (state) => {
  return {
    state: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {    
    authActions: bindActionCreators(authActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class UserAreaContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      // userId: props.match.params.id,
      userType: {},
      userTypes: [],
      user: {},
      areas: [],      
      markTypes: [],      
      vehicleLoading: false,
      vehicleError: null,
      vehicleSuccess: false,
      markLoading: false,
      markError: null,
      markSuccess: false,      
      receiptLoading: false,
      receiptError: null,
      receiptSuccess: false,
      vehiclesEditsResults: {},
      vehiclesDeleteResults: {},
      marksDeleteResults: {},
      marksCeaseResults: {},
      renewErrors: null,
      showRenewMarkModal: false,
      renewingMark: null,            
      showChooseMarksVehicleModal: false,
      showMarksVehicleModalNumber: null,
      showActiveNumberPlate: null,
      chooseMarkVehicleError: null
    }

    this.onOpenRenewMarkModal          = this.onOpenRenewMarkModal.bind(this)
    this.onCloseRenewMarkModal         = this.onCloseRenewMarkModal.bind(this)
    this.onRenewMark                   = this.onRenewMark.bind(this)    
    this.onShowChooseMarksVehicleModal = this.onShowChooseMarksVehicleModal.bind(this)
    this.onHideChooseMarksVehicleModal = this.onHideChooseMarksVehicleModal.bind(this)
    this.onVehicleChoosen              = this.onVehicleChoosen.bind(this)
    this.fetchThingsToDo               = this.fetchThingsToDo.bind(this)
    this.onMarkReceiptDownload         = this.onMarkReceiptDownload.bind(this)
  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount () {
    this.fetchUser()
    this.fetchAreas()        
    this.fetchThingsToDo()
  }

  /**
   * --------------------------------------------------------------
   */
  fetchThingsToDo () {    
    SostaOnlineApiService.fetchThingsToDoMe().then(data => {            
        this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error=> {
      console.log("fetchThingsToDo ERROR",error)
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onVehicleSubmit (data) {
    this.setState({vehicleLoading: true, vehicleError: null, vehicleSuccess: false})
    let obj = {
      plateNumber: data.plateNumber,
      type: data.type
    }
    SostaOnlineApiService.createVehicleMe(obj).then(result => {
      this.setState({vehicleLoading: false, vehicleError: null, vehicleSuccess: true})
      this.fetchUser()
    }).catch(err => {
      let error = 'Errore nella creazione del veicolo'
      if (err.error === 'Plate already exists') {
        error = 'Errore! Targa già esistente!'
      }
      this.setState({vehicleLoading: false, vehicleError: error, vehicleSuccess: false})
    })
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseVehicleEdit () {
    this.fetchUser()
    this.setState({vehiclesEditsResults: {}})
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseMarkCease () {
    this.setState({marksCeaseResults: {}})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onDeleteVehicle (data) {
    let vehiclesDeletes = {}
    vehiclesDeletes[data.id] = 'loading'
    this.setState({vehiclesDeleteResults: vehiclesDeletes})
    // questa quando viene usata?
    SostaOnlineApiService.deleteVehicle(data).then(result => {
      this.setState({vehiclesDeleteResults: {}})
      this.fetchUser()
    }).catch(err => {
      let vehiclesDeletes = {}
      vehiclesDeletes[data.id] = 'error'
      this.setState({vehiclesDeleteResults: vehiclesDeletes})
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onEditVehicleSubmit (data) {
    let vehiclesEdits = {}
    vehiclesEdits[data.id] = 'loading'
    this.setState({vehiclesEditsResults: vehiclesEdits})
    let obj = {
      id: data.id,
      plateNumber: data.plate_number,
      type: data.type
    }
    // questa quando viene usata?
    SostaOnlineApiService.updateVehicle(obj).then(result => {
      let vehiclesEdits = {}
      vehiclesEdits[data.id] = 'success'
      this.setState({vehiclesEditsResults: vehiclesEdits})      
    }).catch(err => {
      let vehiclesEdits = {}
      vehiclesEdits[data.id] = 'error'
      this.setState({vehiclesEditsResults: vehiclesEdits})
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onMarkSubmit (data) {
    let nowMoment = new Date() //moment()
    let expiration = new Date("2018-07-31") //moment().date(31).month(7).year(2018)
    this.setState({markLoading: true, markSuccess: false, markError: null})
    let obj = {
      issuedDate: nowMoment.toDateString(), //nowMoment.format(),
      startDate: nowMoment.toDateString(), //nowMoment.format(),
      endDate: expiration.toDateString(), //expiration.format(),
      active: 1,
      userId: parseInt(this.state.userId,10),
      firstVehicleId: parseInt(data.firstVehicleId,10),
      secondVehicleId: parseInt(data.secondVehicleId,10),
      note: data.note,

      markTypeId: parseInt(data.markTypeId,10),
      categoryId: parseInt(data.categoryId.split('-')[1],10)
    }
    // questa quando viene usata?
    SostaOnlineApiService.createMark(obj).then(result => {
      this.setState({markLoading: false, markSuccess: true, markError: null})
      this.fetchUser()
    }).catch(err => {
      let error = 'Errore nella creazione del permesso'
      if (err.error === 'Mark already exists') {
        error = 'Errore! Permesso già esistente!'
      }
      this.setState({markLoading: false, markSuccess: false, markError: error})
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onDeleteMark (data) {
    let marksDeletes = {}
    marksDeletes[data.id] = 'loading'
    this.setState({marksDeleteResults: marksDeletes})
    // questa quando viene usata?
    SostaOnlineApiService.deleteMark(data).then(result => {
      this.setState({marksDeleteResults: {}})
      this.fetchUser()
    }).catch(err => {
      let marksDeletes = {}
      marksDeletes[data.id] = 'error'
      this.setState({marksDeleteResults: marksDeletes})
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onCeaseMark (data) {
    let marksCease = {}
    marksCease[data.id] = 'loading'
    this.setState({marksCeaseResults: marksCease})
    // questa quando viene usata?
    SostaOnlineApiService.ceaseMark(data).then(result => {
      let marksCease = {}
      marksCease[data.id] = 'success'
      this.setState({marksCeaseResults: marksCease})
      this.fetchUser()
    }).catch(err => {
      let marksCease = {}
      marksCease[data.id] = 'error'
      this.setState({marksCeaseResults: marksCease})
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} category 
   */
  onCreateMarkUserCategorySelected (category) {
    let cat = category.target.value.split('-')[0]
    if (cat) {
      let area

      if (cat === 'resident') {
        area = this.state.user.residenceArea.code
      }

      if (cat === 'employees' ||
                cat === 'economy_activity_holder' ||
                cat === 'company_vehicles' ||
                cat === 'support_organizations' ||
                cat === 'accomodations_company') {
        area = this.state.user.workArea.code
      }

      SostaOnlineApiService
        .fetchMarkTypesByCategoryAndArea(cat.split('-')[0], area)
        .then(data => {
          this.setState({
            markTypes: data.data,
            loading: false
          })
        })
    } else {
      this.setState({
        markTypes: [],
        loading: false
      })
    }
  }

  /**
   * --------------------------------------------------------------
   */
  onEditProfileClick () {
    this.props.history.push('/backoffice/users/' + this.state.userId + '/edit')
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onDownloadMark (mark) {
    SostaOnlineApiService.downloadMarkMe(mark.id).then(data => {
      fileDownload(data, 'permesso-' + mark.issuedDate + '.pdf', 'application/pdf')
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onDownloadMarkRelease (mark) {
    SostaOnlineApiService.downloadMarkReleaseMe(mark.id).then(data => {
      fileDownload(data, 'modulo-rilascio-permesso-' + mark.id + '.pdf', 'application/pdf')
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} user 
   */
  onDownloadUserCredentials (user) {
    SostaOnlineApiService.downloadUserCredentials(user.id).then(data => {
      fileDownload(data, 'credenziali-utente-' + user.id + '.pdf', 'application/pdf')
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} markId 
   */
  async onMarkReceiptDownload (markId) {
    await SostaOnlineApiService.downloadMarkReceiptMe(markId).then(data => {
      fileDownload(data, 'ricevuta-permesso-' + markId + '.pdf', 'application/pdf')
    })
  }

  /**
   * --------------------------------------------------------------
   */
  async fetchUser () {
    this.setState({...this.state, loading: true})
    SostaOnlineApiService.fetchUserMe().then((data) => {     
        this.setState({        
          user: data.data,
          loading: false
        }, () => { 
          //TODO aggiungere fetchRegistry
        })
      })
      .catch((err) => {      
        console.log("ERROR",err)
      });
  }

  /**
   * --------------------------------------------------------------
   */
  fetchAreas () {
    this.setState({loading: true})
    SostaOnlineApiService.fetchAreas().then((data) => {
      this.setState({
        areas: data.data,
        loading: false
      })
    })
    .catch((err) => {      
      console.log("ERROR",err)
    });
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  async onRenewMark (mark) {
    console.log(mark)
    try {
      var result = await SostaOnlineApiService.renewMark({
        ...mark,
        action: 'RENEW'
      })
      .catch( error => {
        console.log("ERROR",error)
      })

      //console.log("RESULT",result)

      await this.fetchUser()
      this.setState({showRenewMarkModal: false})
    } 
    catch (error) {
      console.log("ERROR",error)
      this.setState({
        renewErrors: 'Errore nel rinnovo del permesso, riprova più tardi.'
      })
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onOpenRenewMarkModal (mark) {
    this.setState({
      renewingMark: mark,
      showRenewMarkModal: true
    })
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseRenewMarkModal () {
    this.setState({
      showRenewMarkModal: false,
      renewingMark: null
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onShowChooseMarksVehicleModal (mark) {
    this.setState({
      showMarksVehicleModalNumber: mark,
      showChooseMarksVehicleModal: true
    });
  }

  /**
   * --------------------------------------------------------------
   */
  onHideChooseMarksVehicleModal  () {
    this.setState({
      showChooseMarksVehicleModal: false,
      showMarksVehicleModalNumber: null,
      chooseMarkVehicleError: null
    });
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   */
  async onVehicleChoosen (vehicle) {
    try {
      await SostaOnlineApiService.updateUserMarkActiveVehicle(this.state.showMarksVehicleModalNumber.id, vehicle.id)
      await this.fetchUser()
      const markId = this.state.showMarksVehicleModalNumber.id
      this.onHideChooseMarksVehicleModal()
      setTimeout(() => {
        console.log("SCROLL TO user-mark-"+markId)
        scroller.scrollTo('user-mark-'+markId, {
          duration: 500,
          delay: 100,
          smooth: true
        })
      }, 300)
    } catch (e) {
      this.setState({
        chooseMarkVehicleError: e.error
      })
      console.log(e)
    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {
    const {
      renewErrors,
      renewingMark,
      showRenewMarkModal,
      user,
      loading
    } = this.state

    if ( loading || user == null || !user.id ) {
      return (
        
          <div className="row col-md-12 text-center" >
            <div className="col-md-8 col-md-offset-2">              
              <div className="row"><h2>Caricamento in corso...</h2></div>
              <div className="row" style={{padding: "2em", height: "40em"}}>
                <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
              </div>
            </div>    
          </div>
        
      )    
    } 
    else {
      return (<UserArea      
        onDeleteVehicle={this.onDeleteVehicle.bind(this)}
        onVehicleSubmit={this.onVehicleSubmit.bind(this)}
        onCloseVehicleEdit={this.onCloseVehicleEdit.bind(this)}
        onEditVehicleSubmit={this.onEditVehicleSubmit.bind(this)}
        onMarkSubmit={this.onMarkSubmit.bind(this)}
        onDeleteMark={this.onDeleteMark.bind(this)}
        onCeaseMark={this.onCeaseMark.bind(this)}
        onCloseMarkCease={this.onCloseMarkCease.bind(this)}      
        onCreateMarkUserCategorySelected={this.onCreateMarkUserCategorySelected.bind(this)}
        onDownloadMark={this.onDownloadMark.bind(this)}
        onDownloadMarkRelease={this.onDownloadMarkRelease.bind(this)}
        onDownloadUserCredentials={this.onDownloadUserCredentials.bind(this)}      
        onMarkReceiptDownload={this.onMarkReceiptDownload.bind(this)}      
        onEditProfileClick={this.onEditProfileClick.bind(this)}
        onOpenRenewMarkModal={this.onOpenRenewMarkModal}
        onCloseRenewMarkModal={this.onCloseRenewMarkModal}
        onCreateMarkClick={() => this.props.history.push('/user-area/marks/create')}
        onRenewMark={this.onRenewMark}            
        user={this.state.user}
        areas={this.state.areas}
        userTypes={this.state.userTypes}
        markTypes={this.state.markTypes}
        userType={this.state.userType}
        vehicleLoading={this.state.vehicleLoading}
        vehicleSuccess={this.state.vehicleSuccess}
        vehicleError={this.state.vehicleError}
        markLoading={this.state.markLoading}
        markSuccess={this.state.markSuccess}
        markError={this.state.markError}      
        receiptLoading={this.state.receiptLoading}
        receiptSuccess={this.state.receiptSuccess}
        receiptError={this.state.receiptError}
        vehiclesEditsResults={this.state.vehiclesEditsResults}
        vehiclesDeleteResults={this.state.vehiclesDeleteResults}
        markDeleteResults={this.state.marksDeleteResults}
        markCeaseResults={this.state.marksCeaseResults}
        renewErrors={renewErrors}
        renewingMark={renewingMark}
        showRenewMarkModal={showRenewMarkModal}              
        onShowChooseMarksVehicleModal={this.onShowChooseMarksVehicleModal}
        onHideChooseMarksVehicleModal={this.onHideChooseMarksVehicleModal}
        showChooseMarksVehicleModal={this.state.showChooseMarksVehicleModal}
        showMarksVehicleModalNumber={this.state.showMarksVehicleModalNumber}
        onVehicleChoosen={this.onVehicleChoosen}
        chooseMarkVehicleError={this.state.chooseMarkVehicleError}
        settingsMessages={this.props.settingsMessages}
      />)     
    }    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAreaContainer)
