import React, {Component} from 'react'
import './BackOfficeUserScreen.css'
import ReactTable from 'react-table'
import { Oval } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { getGridHeight } from 'libs/utils'
import Parser from 'html-react-parser';
import { getMarkZone } from 'libs/marks' 
import CategoriesFilter from 'components/ReactTable/Filters/CategoriesFilter'

export default class BackOfficeOperatorProfile extends Component {
    constructor (props) {
        super(props)
    
        this.state = {
        }
    }

    render () {
        const {
            columns,
            maxHeight
            } = this.state
            console.log(this.state);
            return ( 
            <div>                             
                Dentro
            </div>
    
        )
    }

}