import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { actions as authActions} from 'redux/modules/Auth'
import moment from 'moment';

moment.locale("it")

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions:   bindActionCreators(authActions,   dispatch)    
  }
}


class DeleteVehicleModal extends Component {

  static propTypes = {
    show:            PropTypes.bool.isRequired,
    onClose:         PropTypes.func.isRequired,    
    onDeleteVehicle: PropTypes.func.isRequired,    
    vehicle:         PropTypes.object
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {            
      checkErrors: null,
      sendErrors: null ,                  
    }

    this.deleteVehicle    = this.deleteVehicle.bind(this)
    this.checkFields      = this.checkFields.bind(this)
    this.fetchInitialData = this.fetchInitialData.bind(this)
   
  }  

  /**
   * --------------------------------------------------------------
   */
  async fetchInitialData () {
                        
    this.setState({loading: true})
  
    
      
    this.setState({loading: false})
    
 
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  checkAlert() {

    var alertsActive   = []
    var alertsAssigned = []

    const {
      vehicle
    } = this.props

    if ( vehicle && vehicle.Marks && vehicle.Marks.length > 0 ) {
      vehicle.Marks.map( mark => {        
        if( !mark.ceased && moment(mark.endDate).isAfter(moment() ) ){
          if ( mark.activeVehicleId === vehicle.id ){
            alertsActive.push(mark.id) 
          }
          else {
            alertsAssigned.push(mark.id) 
          }
        }
      })      
    }


    return (  
      
      alertsAssigned.length > 0 || alertsActive.length > 0 
      ? (
      <React.Fragment>                
        <div>
          <div>
            Tale veicolo risulta&nbsp;           
            { alertsAssigned.length > 1 && <span>assegnato ai permessi N°&nbsp;</span> }
            { alertsAssigned.length > 0 && alertsAssigned.length < 2 && <span>assegnato al permesso N°&nbsp;</span> }
            <strong>{ alertsAssigned.map( (alert,index) => { return  index > 0 ? ", " + alert  :  alert } ) }</strong>
            { alertsAssigned.length > 0 && alertsActive.length  > 0 ? " ed " : " " }
            { alertsActive.length   > 1 && <span>attivo sui permessi N°&nbsp;</span> }
            { alertsActive.length   > 0 &&  alertsActive.length < 2 && <span>attivo sul permesso N°&nbsp;</span> }
            <strong>{ alertsActive.map( (alert,index) => { return  index > 0 ? ", " + alert  :  alert } ) }</strong>.
          </div>
          <div>
            { alertsActive.length > 0 && <span><br/>Se si procede i permessi su cui risulta attivo il veicolo verranno automaticamente cessati.</span> }
          </div>
        </div>
      </React.Fragment>
      ) 
      :  null 
      
    )

  }



  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {    
    this.stateInit()
    this.fetchInitialData ()    
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []        
    
    //  errors.push("Non hai specificato una targa")

    
    return errors;

  }

  /**
   * --------------------------------------------------------------
   */
  async deleteVehicle() {       
    
    var errors  = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }              
        
    SostaOnlineApiService.deleteVehicleMe(this.props.vehicle)
      .then( deletetVehicle =>{
        if ( deletetVehicle.result === 'ok' ) {
          if ( this.props.onDeleteVehicle ) 
            this.props.onDeleteVehicle(deletetVehicle)

          console.log('deleteVehicle',deletetVehicle)

          this.close()
        }
        else {
            this.setState({sendErrors: deletetVehicle.error})
        }
      })
      .catch( error => {        
        this.setState({sendErrors: error.error})
      })  

    this.close()
            
  }


  /**
   * --------------------------------------------------------------
   */
  close() {
    this.props.onClose()
    this.stateInit()
  }

  /**
   * --------------------------------------------------------------
   */
  stateInit() {
    this.setState({                  
      checkErrors: null,
      sendErrors: null,      
    })

    this.props.initialize({ 
      
    })

  }
  

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,
      onClose,
      vehicle
    } = this.props

    const {
      sendErrors,
      checkErrors,             
    } = this.state


    var alerts = this.checkAlert()

    return (
      <Modal
        open={show}
        onClose={onClose}
        closeOnEsc={true}
        showCloseIcon={false}       
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        center               
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faCarBurst} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>ELIMINAZIONE VEICOLO</strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4>        

        { vehicle == null &&
        <div className="col-md-12" >
          <div className="app-modal-body">
            
            <div className="row">
                <h4>
                  Veicolo Non trovato!
                </h4>
            </div>
                                        
            <div className="row">
              <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                <Button className="btn alertBtn"   onClick={ () => this.close() }>Annulla</Button>{' '}            
              </div>
            </div>

          </div>
        </div>
        }
       
        { vehicle != null  && 
        <div className="col-md-12" >
          <div className="app-modal-body">
            
            <div className="row">
                <h3 className="col-md-12 col-xs-12 text-center">
                  ATTENZIONE
                </h3>
                <h4 className="col-md-12 col-xs-12 text-center">
                  Si sta richiedendo l'eliminazione del Veicolo&nbsp;<strong>{vehicle.plate_number}</strong>.<br/><br/>
                  { alerts && alerts }<br/>
                  L'operazione è irreversibile, si vuole procedere?
                </h4>
            </div>
                            

            <div className="row">
              { sendErrors && 
                <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {sendErrors}
                </h4>
              }
              { checkErrors && checkErrors.length > 0 && 
                <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                </h4>
              }
            </div>

            <div className="row">
              <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                <Button className="btn alertBtn"   onClick={ () => this.close() }>
                  <FontAwesomeIcon  size="1x" icon={solidIcons.faCircleXmark} />&nbsp;&nbsp;Annulla</Button>{' '}
                <Button className="btn mainBtn" onClick={() => this.deleteVehicle()} >
                  <FontAwesomeIcon  size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina
                </Button>
              </div>
            </div>
          </div>
        </div>
        }

      </Modal>
    )
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'DeleteVehicleModal'})(DeleteVehicleModal))
