import React, { Component } from 'react'
import { reduxForm, Field, change } from 'redux-form'
import SostaOnlineApiService from '../services/SostaOnlineApiService'
import { connect } from 'react-redux'
import { Oval } from 'react-loader-spinner'
import { MARK_TYPE_ELECTRIC, MARK_TYPE_GREEN, MARK_TYPE_HYBRID } from 'libs/marks'

//--------------------------------------------------------------------------
const mapStateToProps = (state) => {
  return {}
}

//--------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

//--------------------------------------------------------------------------
class BackOfficeUserDetailEditMarkForm extends Component {

  //--------------------------------------------------------------------------
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      allMarkTypes: [],
      filteredMarkTypes: [],
      markRules: {},
      errorFetchingRules: false,
      enableCash: false,
      enablePagoPa: false,
      enableReclaim: false,
      enableReseller: false,
      paymentDefault: false,
      paymentSelected: false,
      vehicles: props.mark.Vehicles.map(item => item.id + ''),
      price: {},
      errorFetchingPrice: false,
      error: false,
      selectedTotalPrice: 0
    }

    this.onVehicleChange = this.onVehicleChange.bind(this)
    this.countMarks = this.countMarks.bind(this)
  }

  //--------------------------------------------------------------------------
  onVehicleChange(e, index) {
    console.log(e.target.value)
    let newVehicles = [...this.state.vehicles]
    newVehicles[index] = e.target.value
    this.setState({ vehicles: newVehicles }, () => this.updatePrice())
  }

  //--------------------------------------------------------------------------

  setPayment(e) {
    var stringa = e.target.value.split("_");
    this.setState({ paymentSelected: stringa[1] })
    //console.log(this.state.paymentSelected)
    this.props.change('method', stringa)
  }

  //--------------------------------------------------------------------------

  verifyChecked(whose) {
    if (this.state.paymentSelected == whose) {
      //console.log ( "BackOfficeUserDetailEditMarkForm - this.state.paymentSelected == whose", this.state.paymentSelected, "=", whose)
      //console.log ( "BackOfficeUserDetailEditMarkForm - this.state.paymentSelected == whose", this.state.paymentSelected, "=", whose)
      return this.state.paymentSelected == whose
    } else if (this.state.paymentSelected == false && whose == this.state.paymentDefault) {
      //console.log ( "BackOfficeUserDetailEditMarkForm - this.state.paymentSelected == false", this.state.paymentSelected)
      return true
    } else {
      //console.log ( "BackOfficeUserDetailEditMarkForm - this.state.paymentSelected ", this.state.paymentSelected)
      return false
    }
  }

  //--------------------------------------------------------------------------
  async componentDidMount() {

    try {
      const payments = await SostaOnlineApiService.fetchPaymentSettings()
      //console.log("BackOfficeUserDetailEditMarkForm - payments", payments.data)
      if (payments.data.length === 0) {
        this.setState({ enableCash: false })
        this.setState({ enablePagoPa: false })
        this.setState({ enableReclaim: false })
        this.setState({ enableReseller: false })
        this.setState({ paymentDefault: false })
        this.props.change('method', "SPORTELLO_POS")
      }
      else {
        this.setState({ enableCash: payments.data.enableCash })
        this.setState({ enablePagoPa: payments.data.enablePagoPa })
        this.setState({ enableReclaim: payments.data.enableReclaim })
        this.setState({ enableReseller: payments.data.enableReseller })
        this.setState({ paymentDefault: payments.data.paymentDefault })
        this.props.change('method', "SPORTELLO_" + payments.data.paymentDefault)
      }

      const rules = await SostaOnlineApiService.fetchMarkRulesByMarkType(this.props.markTypeId)
      if (rules.data.length === 0) {
        this.setState({ errorFetchingRules: true })
        return
      }

      this.setState({ markRules: rules.data[0] }, () => {
        if (this.state.vehicles.filter(item => item !== '').length > 0) {
          this.updatePrice()
        }
      })

      // aggiorno gli initval nel caso acceda al box più volte, altrimenti
      // this.props.initialValues viene accettato al primo rendering ed ignorato da li in poi
      Object.entries(this.props.initialValues).map(item => {
        //console.log("ITEM",item) 
        this.props.change(item[0], item[1])
      })


    } catch (ex) {
      console.log("ERROR", ex)
      this.setState({ error: true })
    }
  }

  //--------------------------------------------------------------------------
  async updatePrice() {
    try {
      const price = await SostaOnlineApiService.fetchMarkPrice(
        this.props.user.id,
        this.props.markTypeId,
        this.props.mark.Category.id,
        this.printVehicles(this.state.vehicles.filter(item => item !== '')),
        null,
        this.props.mark.selectedStartDate
      )
      if (price.result !== 'ok') {
        this.setState({ errorFetchingPrice: true })
        return
      }
      this.setState({ price: price.data })
      this.setState({ selectedTotalPrice: this.props.mark.price > 0 ? this.props.mark.price : price.data.price })
      this.props.change('total', this.props.mark.price > 0 ? this.props.mark.price : price.data.price)
    }
    catch (ex) {
      this.setState({ errorFetchingPrice: true })
    }
  }

  //--------------------------------------------------------------------------
  printAreas(areas) {
    let string = ''

    areas.map((item, index) => {
      string = string + item.code

      if (index < areas.length - 1) {
        string = string + ', '
      }

      console.log("printAreas string", string)
    })

    console.log("printAreas return ", string)

    return string
  }

  //--------------------------------------------------------------------------
  printVehicles(vehicles) {
    let string = ''
    vehicles.map((item, index) => {
      string = string + item

      if (index < vehicles.length - 1) {
        string = string + ','
      }
    })
    return string
  }

  //--------------------------------------------------------------------------
  countMarks(markTypeId) {
    return this.props.user.marks.filter(item => item.id === markTypeId).length
  }

  //--------------------------------------------------------------------------
  render() {
    const { handleSubmit } = this.props

    if (this.state.loading) {
      return (
        <div>
          <div className="row"><h2>Caricamento in corso...</h2></div>
          <div className="row" style={{ padding: "2em", height: "40em" }}>
            <Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />
          </div>
        </div>
      )
    }

    if (this.state.error) {
      return (<div className="text-danger">Errore di ricezione dati</div>)
    }

    var vehicles = this.props.user.vehicles

    if (this.props.mark.MarkType && this.props.mark.MarkType.type === MARK_TYPE_HYBRID) {
      vehicles = this.props.user.vehicles.filter(item => item.VehiclePowerSupply.hybrid)
    }
    else if (this.props.mark.MarkType && this.props.mark.MarkType.type === MARK_TYPE_ELECTRIC) {
      vehicles = this.props.user.vehicles.filter(item => item.VehiclePowerSupply.electric)
    }
    else if (this.props.mark.MarkType && this.props.mark.MarkType.type === MARK_TYPE_GREEN) {
      vehicles = this.props.user.vehicles.filter(item => (item.VehiclePowerSupply.hybrid || item.VehiclePowerSupply.electric))
    }

    return (
      <form onSubmit={handleSubmit(values => {
        this.props.onSubmit({
          id: this.props.mark.id,
          price: this.state.selectedTotalPrice,
          ...values,
          vehicles: this.state.vehicles
        })
      })}>
        {this.state.errorFetchingRules && <div className="row">
          <div className="col-12 text-danger">
            Errore nella ricezione delle regole del permesso
          </div>
        </div>}

        <div className="form-group">
          <div className="col-md-4 text-left">
            <label>Circolazione</label>
            <Field
              name="circolazione"
              className="form-control"
              component="input"
            />
          </div>
          <div className="col-md-4 text-left">
            <label>Periodo</label>
            <Field
              name="periodo"
              className="form-control"
              component="input"
            />
          </div>
          <div className="col-md-4 text-left">
            <label>Sosta</label>
            <Field
              name="sosta"
              className="form-control"
              component="input"
            />
          </div>
        </div>

        {this.state.markRules.id &&
          <div className="row form-group">
            <div className="col-md-12 text-left">
              <span
                className={
                  this.state.vehicles.length > this.state.markRules.vehicleLimit
                    ? 'text-danger'
                    : 'text-primary'
                }>
                {this.state.vehicles.length} / {this.state.markRules.vehicleLimit}
              </span>
              {' '}
              <button
                type="button"
                className="btn btn-xs btn-update btn-add-card"
                onClick={() => {
                  this.setState(
                    { vehicles: [...this.state.vehicles, ''] },
                    () => {
                      this.updatePrice()
                      this.props.dispatch(change('backofficeUserDetailEditMark', `vehicle${this.state.vehicles.length - 1}`, ''))
                    }
                  )
                }}>
                Aggiungi Veicolo
              </button>
            </div>
          </div>
        }

        {this.state.markRules.id ? this.state.vehicles.map((item, index) => (
          <div key={index} className="row form-group">
            <div className="col-md-12 text-left">
              <label>Seleziona {index + 1}° Veicolo</label>
              <Field
                onChange={(e) => this.onVehicleChange(e, index)}
                name={'vehicle' + index}
                className="form-control"
                component="select"
                required
              >
                {(!vehicles) && <option value={''}>Questo Utente non ha Veicoli</option>}
                {(vehicles && vehicles.length === 0) && <option value={''}>
                  Questo Utente non ha Veicoli
                </option>}
                {(vehicles && vehicles.length > 0) &&
                  <option value={''}>Seleziona un veicolo</option>}
                {(vehicles && vehicles.length > 0) &&
                  vehicles.map(item =>
                    <option key={item.id} value={item.id}>
                      {item.plate_number + ' - ' + item.VehiclePowerSupply.name}
                    </option>)}
              </Field>
              <button
                onClick={() => this.setState({ vehicles: this.state.vehicles.filter((_, i) => i !== index) }, () => this.updatePrice())}
                type="button"
                className="btn btn-xs btn-danger">
                Rimuovi Veicolo
              </button>
            </div>
          </div>
        )) : null}

        <div className="row">
          <div className="col-md-6 text-left">
            <label>Prezzo</label>
            <div className="input-group" style={{ marginTop: 12, marginBottom: 12 }}>
              <div className="input-group-addon">€</div>
              <Field
                name="total"
                component="input"
                type="number"
                className="form-control"
                onChange={e => {
                  if (!e.target.value) {
                    this.setState({ selectedTotalPrice: 0 })
                    this.props.change('total', 0)
                  } else {
                    this.setState({ selectedTotalPrice: e.target.value })
                    this.props.change('total', e.target.value)
                  }
                }}
              />
            </div>
          </div>
        </div>

        {(this.state.selectedTotalPrice > 0) && (
          <div className="row">
            {(this.props.markTypeId) && <div className="col-md-6 text-left">
              <label>Totale</label>
              <div style={{ marginTop: 12 }}>
                <h4>€ {Number(this.state.selectedTotalPrice).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
              </div>
            </div>}
          </div>
        )}
        {this.state.selectedTotalPrice > 0 && <div className="row">
          <div className="col-md-6 text-left">
            <label>Metodo di Pagamento</label>
            {this.state.enableCash ?
              <div className="radio">
                <label><Field component="input" type="radio" name="method" value="SPORTELLO_CONTANTI" required checked={this.verifyChecked("CONTANTI")} onChange={e => this.setPayment(e)} /> CONTANTI</label>
              </div> :
              <div className="radio">
                <label><Field component="input" type="radio" name="method" value="SPORTELLO_CONTANTI" disabled /> CONTANTI</label>
              </div>
            }
            {this.state.enablePagoPa ?
              <div className="radio">
                <label><Field component="input" type="radio" name="method" value="SPORTELLO_PAGOPA" required checked={this.verifyChecked("PAGOPA")} onChange={e => this.setPayment(e)} /> PAGOPA</label>
              </div> :
              <div className="radio">
                <label><Field component="input" type="radio" name="method" value="SPORTELLO_PAGOPA" disabled /> PAGOPA</label>
              </div>
            }
            {this.state.enableReclaim ?
              <div className="radio">
                <label><Field component="input" type="radio" name="method" value="SPORTELLO_BONIFICO" required checked={this.verifyChecked("BONIFICO")} onChange={e => this.setPayment(e)} /> BONIFICO</label>
              </div> :
              <div className="radio">
                <label><Field component="input" type="radio" name="method" value="SPORTELLO_BONIFICO" disabled /> BONIFICO</label>
              </div>
            }
            {this.state.enableReseller ?
              <div className="radio">
                <label><Field component="input" type="radio" name="method" value="RIVENDITORE" required checked={this.verifyChecked("RIVENDITORE")} onChange={e => this.setPayment(e)} /> RIVENDITORE</label>
              </div> :
              <div className="radio">
                <label><Field component="input" type="radio" name="method" value="RIVENDITORE" disabled /> RIVENDITORE</label>
              </div>
            }
            <div className="radio">
              <label><Field component="input" type="radio" name="method" value="SPORTELLO_POS" required checked={this.verifyChecked("POS")} onChange={e => this.setPayment(e)} /> POS</label>
            </div>
          </div>
        </div>}

        {this.props.editMarkError &&
          <div className="row form-group">
            <br /><br />
            <div className="col-md-12 text-danger text-center">
              ERRORE: <br />{this.props.editMarkError}
            </div>
            <br /><br />
          </div>
        }

        <div style={{ marginTop: '10px' }}>
          <button onClick={() => this.props.onCancel(this.props.mark)} type="button" className="btnBack btn btn-danger btn-xs btn-update btn-add-card">
            Annulla Modifiche
          </button>
          {this.verifyChecked("PAGOPA") || this.verifyChecked("BONIFICO") || this.verifyChecked("RIVENDITORE") || this.verifyChecked("POS") || this.verifyChecked("CONTANTI") ?
            <button type="submit" className="btnSubmit btn btn-xs btn-update btn-add-card">
              Conferma Modifiche con pagamento {this.state.paymentSelected != false ? this.state.paymentSelected : this.verifyChecked("PAGOPA") ? "PAGOPA" : this.verifyChecked("BONIFICO") ? "BONIFICO" : this.verifyChecked("RIVENDITORE") ? "RIVENDITORE" : this.verifyChecked("POS") ? "POS" : this.verifyChecked("CONTANTI") ? "CONTANTI" : "IGNOTO"}
            </button>
            :
            <button title="Selezionare un metodo di pagamento" type="submit" className="btn btn-xs btn-update btn-add-card" disabled={true}>
              Conferma Modifiche con pagamento ?
            </button>
          }
        </div>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'backofficeUserDetailEditMark',
  enableReinitialize: true
})(BackOfficeUserDetailEditMarkForm))
