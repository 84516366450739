import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NavLink, Redirect } from 'react-router-dom'

import { actions as authActions } from '../redux/modules/Auth'
import { ADMIN, OPERATOR, RESELLER } from 'libs/roles'

const mapStateToProps = (state) => {
  return {
    state: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  }
}

class MainRedirectContainer extends Component {


  redirectTo() {
    var user = this.props.state.user

    if (!user) {
      return '/splash'
    }

    if (user.role === ADMIN || user.role === OPERATOR) {
      return '/backoffice'
    }

    if (user.role === RESELLER && user.budget !== null) {
      return '/reseller'
    }

    return '/user-area'
  }

  render() {
    return (
      <Redirect forceRefresh={true} to={this.redirectTo()} exact />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainRedirectContainer)
