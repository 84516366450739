import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import ReactTable from 'react-table';
import { Oval } from 'react-loader-spinner';
import Parser from 'html-react-parser';


class BackofficePassagesModal extends Component {

  static propTypes = {
    show:             PropTypes.bool.isRequired,
    onClose:          PropTypes.func.isRequired,    
    onUpdatePassages: PropTypes.func.isRequired,
    areaId:           PropTypes.number.isRequired,
    addresses:        PropTypes.array.isRequired    
  }
  
  stateInitValues = {
    loading: true,   
      area: null,        
      selectedAddresses: [],
      checkErrors: null,
      sendErrors: null,
      sendMsg: null,
      selectedRow: null,  
      passageName: null,
      passageId: null,
      passages: [],
      editAddressRoute: { id: null },
      confirmDelete: false
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = { 
      
      ... this.stateInitValues,

      columns: [
        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Id</strong></div>,
          accessor: 'id',
          filterable: false,
          sortable: true,
          width: 60,
          Cell: (row) => {
            return (
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-address'}>Doppio click per selezionare l'indirizzo</Tooltip>}>
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {row.value}                
              </div>
              </OverlayTrigger>
            )
          }
        },
        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Nome</strong></div>,
          accessor: 'name',
          filterable: false,
          sortable: true,
          width: 300,
          Cell: (row) => {
            return (
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-address'}>Doppio click per selezionare l'indirizzo</Tooltip>}>
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {row.value}                
              </div>
              </OverlayTrigger>
            )
          }
        },
      ],

      columns1: [
        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Id</strong></div>,
          accessor: 'id',
          filterable: false,
          sortable: true,
          width: 60,
          Cell: (row) => {
            return (
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-address'}>Doppio click per selezionare l'indirizzo</Tooltip>}>
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {row.value}                
              </div>
              </OverlayTrigger>
            )
          }
        },
        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Nome</strong></div>,
          accessor: 'name',
          filterable: false,
          sortable: true,
          minWidth: 300,
          Cell: (row) => {
            return (
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-address'}>Doppio click per selezionare l'indirizzo</Tooltip>}>
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {row.value}                
              </div>
              </OverlayTrigger>
            )
          }
        },

        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Percorso</strong></div>,
          accessor: 'AddressesPassages',
          filterable: false,
          sortable: true,
          minWidth: 250,
          Cell: (row) => {
            return (
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-address'}>Click per selezionare l'indirizzo</Tooltip>}>
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {row.value != null ? row.value.route : ""}                
              </div>
              </OverlayTrigger>
            )
          }
        },
      ]
    }

    this.savePassage = this.savePassage.bind(this)
    this.checkFields = this.checkFields.bind(this)
   
  }  

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {    
    this.stateInit()
    this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {
    if ( this.props.areaId != prevProps.areaId || this.props.addresses != prevProps.addresses ) {
      this.stateInit()
      this.fetchData()
    }
  }

  /**
   * --------------------------------------------------------------
   */
  close() {
    if ( this.props.onClose != null  )
      this.props.onClose()
    
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState(this.stateInitValues)    
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchData() {    

    this.setState({loading: true})

    if ( this.props.areaId != null ) {
      var area = await SostaOnlineApiService.fetchArea(this.props.areaId)

      if ( area != null ) {
        this.setState({area: area.data})
      }

      var passages = await SostaOnlineApiService.fetchPassages(this.props.areaId)

      if ( passages != null ) {
        this.setState({passages: passages.data})
      }
           
    }    

    this.setState({loading: false})

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSortedAddresses() {    
    var addresses         = this.props.addresses
    var selectedAddresses = this.state.selectedAddresses
    var sortedAddresses   = []
  
    if ( addresses != null ) {

      for ( let addrKey in addresses ) {
        var addr       = addresses[addrKey] 
        var isselected = selectedAddresses.find( a => a.id == addr.id )

        if ( isselected == null ) {
          sortedAddresses.push(addr)
        }
      }

      sortedAddresses = sortedAddresses.sort( ( a, b ) => ( a.name.toUpperCase() > b.name.toUpperCase()  ? 1 : -1 )  )    
    }

    return sortedAddresses
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSelectedSortedAddresses() {    
    var selectedAddresses       = this.state.selectedAddresses
    var selectedSortedAddresses = []

    if ( selectedAddresses != null ) {
      selectedSortedAddresses = selectedAddresses.sort( ( a, b ) => ( a.name.toUpperCase() > b.name.toUpperCase()  ? 1 : -1 )  )    
    }

    return selectedSortedAddresses
  }

  /**
   * --------------------------------------------------------------
   * @param {*} value 
   */
   async onAddresSelected(rowIndex, rowInfo){
    var selectedAddresses = this.state.selectedAddresses
    var newSelectedAddress = [... selectedAddresses]    

    if ( rowInfo != null ) {
      var alreadySelected = await selectedAddresses.find( address => address.id == rowInfo.id )

      if ( alreadySelected == null ) {        
        newSelectedAddress.push( rowInfo )
      }
    }

    this.setState({ selectedAddresses: newSelectedAddress  })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} rowIndex 
   * @param {*} rowInfo 
   */
  async onAddresDeselected(rowIndex, rowInfo) {
    var selectedAddresses = this.state.selectedAddresses
    var newSelectedAddress = [... selectedAddresses]    

    if ( rowInfo != null ) {
      newSelectedAddress = selectedAddresses.filter( address => address.id != rowInfo.id )      
    }

    this.setState({ selectedAddresses: newSelectedAddress  })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} rowIndex 
   * @param {*} rowInfo 
   */
  onShowEditRoute(rowIndex, rowInfo) {
    
    var selectedAddresses = this.state.selectedAddresses
    var editAddressRoute = { id: null }

    if ( rowInfo != null ) {
      editAddressRoute = selectedAddresses.find( address => address.id == rowInfo.id )            
    }

    this.setState({ editAddressRoute: editAddressRoute  })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} name 
   */
  setPassageName(name) {

    if ( name != null )
      name = name.toUpperCase()

    this.setState({ passageName: name })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} value 
   */
  setPassageId(value) {

    if ( value == "#" )
      value = null
         
    var selectedPassage = this.state.passages.find( p => p.id == value )

    this.setState({ 
      passageId:         value,
      passageName:       selectedPassage && selectedPassage.name ? selectedPassage.name.toUpperCase() : null ,
      selectedAddresses: selectedPassage && selectedPassage.Addresses ? selectedPassage.Addresses : [],
      editAddressRoute: { id: null },
      checkErrors: null,
      sendErrors: null,
      sendMsg: null
    })
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} addressId 
   * @param {*} route 
   */
  setAddressRoute(addressId,  route) {

    var selectedAddresses = this.state.selectedAddresses

    selectedAddresses = selectedAddresses.map( address => {

      var updatedAddress = address

      if ( updatedAddress.id == addressId ) {
        if ( updatedAddress.AddressesPassages != null ) {
          updatedAddress.AddressesPassages.route = route
        }
        else {
          updatedAddress.AddressesPassages = {
            AddressId: addressId,
            PassageId: this.state.passageId,
            route: route
          }
        }
      }

      return  updatedAddress
    })

    this.setState({ selectedAddresses: selectedAddresses })

  }

   /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []   

    if ( this.state.passageName == null || this.state.passageName.trim().length <= 0 ) {
      errors.push("Nome non specificato")
    }

    return errors;

  }
 
  /**
   * --------------------------------------------------------------
   * 
   */
  savePassage() {

    this.setState({ sendErrors: null , sendMsg: null, checkErrors: null })
    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }    

    // Crea nuovo Varco
    if ( this.state.passageId == null ) {

      let passage = {
        name :     this.state.passageName,
        areaId:    this.props.areaId,   
        addresses: this.state.selectedAddresses     
      }

      SostaOnlineApiService.createPassage(passage)
      .then( result => {    
        
        var passageId  = null

        if ( result.data != null && result.data.id != null ) {
          passageId =  result.data.id
        }
        
        if ( this.props.onSavePassage != null ) {
          this.props.onSavePassage(passageId)          
        }    
                
        this.setState({
          passageId: passageId,
          sendMsg: 'Varco salvato' 
        })

        this.fetchData()
  
      })
      .catch( error => {        

        var errMsg = ""

        if ( error.error != null ) 
          errMsg = error.error
        else 
          errMsg = "Errore durante Il salvataggio, riprovare più tardi!"

        console.log("ERROR",error)        
        this.setState({ sendErrors: errMsg })
      })
      
    }
    else {

      let passage = {
        id: this.state.passageId,
        name : this.state.passageName,
        areaId: this.props.areaId,
        addresses: this.state.selectedAddresses     
      }

      SostaOnlineApiService.updatePassage(passage)
      .then( result => {    
                              
        if ( this.props.onSavePassage != null ) {
          this.props.onSavePassage(passage.id)
        }    
                
        this.setState({ sendMsg: 'Varco salvato' })
        this.fetchData()
  
      })
      .catch( error => {        

        var errMsg = ""

        if ( error.error != null ) 
          errMsg = error.error
        else 
          errMsg = "Errore durante Il salvataggio, riprovare più tardi!"

        console.log("ERROR",error)        
        this.setState({ sendErrors: errMsg })
      })
    }
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  deletePassage() {
    SostaOnlineApiService.deletePassage(this.state.passageId)
      .then( result => {    
                
        if ( this.props.onSavePassage != null ) {
          this.props.onSavePassage()          
        }    
                
        this.setState({
          passageId: null,
          passageName: null,
          selectedAddresses: [],
          sendMsg: 'Varco cancellato',
          confirmDelete: false
        })

        this.fetchData()
  
      })
      .catch( error => {        

        var errMsg = ""

        if ( error.error != null ) 
          errMsg = error.error
        else 
          errMsg = "Errore durante la cancellazione, riprovare più tardi!"

        console.log("ERROR",error)        
        this.setState({ sendErrors: errMsg })
      })
  }

  /**
   * --------------------------------------------------------------
   */
  showConfirmDeletePassage( confirm=true ) {
    this.setState({ 
      confirmDelete: confirm,
      sendMsg: ''
    })

  }
 
  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,
      onClose,
      areaId
    } = this.props

    const {
      sendMsg,
      sendErrors,
      checkErrors,
      area,            
      columns,
      columns1,
      loading,      
      selectedRow,
      passageName,
      passageId,
      passages,
      editAddressRoute,
      confirmDelete
    } = this.state

    const sortedAddresses         = this.getSortedAddresses()
    const selectedSortedAddresses = this.getSelectedSortedAddresses()

    return (
      <Modal
        open={show}
        onClose={onClose}
        closeOnEsc={true}
        showCloseIcon={false}       
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container-large'}}        
        animationDuration={500}
        center               
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faFileUpload} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>VARCHI ZONA {areaId} { area!= null ? area.name : '' }</strong></div>
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4>   

        { area  == null &&
        <div className="col-md-12">
          <div className="app-modal-body">
            <div className="row">          
              Loading ...
            </div>
          </div>
        </div>
        }     

        { area != null && 
        <div className="col-md-12" >
          <div className="app-modal-body">

            <div className="row" style={{ paddingLeft: "10px" , paddingRight: "10px" }}>
                
                <div className="col-md-5" >
                  
                  <select 
                    name="passageName" 
                    className="form-control"
                    value={passageId != null ? passageId : "#"}
                    onChange={ e => { this.setPassageId(e.target.value) } }
                  >
                    <option value="#"> NUOVO VARCO </option>
                    { passages.map( passage =>                       
                      <option  key={passage.id} value={passage.id} {...{}} > {passage.name.toUpperCase()} </option>
                    )}
                  </select>
                </div>
                  
                <div className="col-md-7" >
                  { passageId == null && 
                  <input
                    name="passageName" 
                    className="form-control" 
                    placeholder="Nome Varco..."
                    type="text" 
                    onChange={ e => {  this.setPassageName(e.target.value) }}
                    value={ passageName!= null ? passageName : '' }
                    required />
                  }
                  { passageId != null && 
                  <input
                    name="newPassageName" 
                    className="form-control" 
                    placeholder="Rinomina Varco..."
                    type="text" 
                    onChange={ e => {  this.setPassageName(e.target.value) }}
                    value={ passageName != null ? passageName : '' }
                    required />
                  }
                </div>
            </div>                           

            <div className="row" style={{marginBottom: '1.5em', marginTop: '1em' }}>
              
              <div className= "col-md-5 text-center"  style={{marginBottom: '1em' }}>
                
                <label style={{marginBottom: '0.5em' , marginTop: '0.5em' }}>INDIRIZZI DELLA ZONA</label>
                <ReactTable       
                  getTdProps={(state, rowInfo, column, instance) => {

                    const isActive = rowInfo && selectedRow == rowInfo.index
                    const activeStyle = {}

                    if (isActive) {
                      activeStyle.background = '#f5f5f5'
                      activeStyle.color = 'black'                      
                    }

                    return {
                      style: { ...activeStyle },
                      onDoubleClick: (e, handleOriginal) => {                
                        if (rowInfo != null ) {                                                
                          this.onAddresSelected(rowInfo.index, rowInfo.original)
                        }
                      }
                    }
                  }}              
                  manual
                  filterable
                  loading={loading}
                  noDataText={'Nessun Indirizzo Presente'}
                  //onFetchData={null}  
                  className="-highlight"
                  data={ sortedAddresses }
                  style={{ maxHeight: "25em" }}                                    
                  loadingText={<Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />}
                  showPaginationBottom={false}                  
                  columns={columns} />
              </div>

              <div className= "col-md-7 text-center"  style={{marginBottom: '1em' }}>
                
                <label style={{marginBottom: '0.5em' , marginTop: '0.5em' }}> INDIRIZZI ACCESSIBILI DAL VARCO</label>

                <ReactTable       
                  getTdProps={(state, rowInfo, column, instance) => {

                    var isActive = false

                    if ( rowInfo != null ) {                        
                      isActive = editAddressRoute.id == rowInfo.row.id
                    }

                    const activeStyle = {}

                    if (isActive) {
                      activeStyle.background = '#f5f5f5'
                      activeStyle.color = 'black'                      
                    }

                    return {
                      style: { ...activeStyle },
                      onClick: (e, handleOriginal) => {                
                        if (rowInfo != null ) {
                          this.onShowEditRoute(rowInfo.index, rowInfo.original)
                        }
                      },
                      onDoubleClick: (e, handleOriginal) => {                
                        if (rowInfo != null ) {
                          this.onAddresDeselected(rowInfo.index, rowInfo.original)
                        }
                      }
                    }
                    
                  }}              
                  manual
                  filterable
                  loading={loading}
                  noDataText={'Nessun Indirizzo Presente'}
                  //onFetchData={null}  
                  className="-highlight"
                  data={ selectedSortedAddresses }
                  style={{ maxHeight: "25em" }}                                    
                  loadingText={<Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />}
                  showPaginationBottom={false}                  
                  columns={columns1} 
                />

                { editAddressRoute.id != null  &&
                  <div className="row" style={{marginBottom: '0.5em' , marginTop: '0.5em' }}>
                    <div className="col-xs-3 text-left">
                      <label >Modifica Percorso:</label>
                    </div>
                    <div className="col-xs-9 text-left">
                      <input                         
                        name="addressRoute"
                        className="form-control"
                        onChange={ e => {  this.setAddressRoute( editAddressRoute.id,  e.target.value) }}
                        value={ editAddressRoute.AddressesPassages && editAddressRoute.AddressesPassages.route != null ?  editAddressRoute.AddressesPassages.route : '' }
                      />
                    </div>
                  </div>
                }

              </div>  

              <div className="col-md-12">
                { sendMsg &&<h4 className="col-md-12 text-success text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {Parser(sendMsg)}
                  </h4> }
                { sendErrors && 
                  <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {sendErrors}
                  </h4>
                }
                { checkErrors && checkErrors.length > 0 && 
                  <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                  </h4>
                }
              </div>

              { !confirmDelete &&
              <div className="col-md-12 text-center">                      
                <Button className="btn mainBtn" onClick={ () => this.savePassage() }>
                  <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faSave} />&nbsp;&nbsp;Salva Varco
                </Button>

                { passageId != null  && 
                <Button className="btn alertBtn" onClick={ () => this.showConfirmDeletePassage() }>
                  <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina Varco
                </Button>
                }

                <Button className="btn mainBtn" onClick={ () => this.close() }>
                  <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faXmark} />&nbsp;&nbsp;Esci
                </Button>
              </div>                              
              }

              { confirmDelete &&
              <div className="col-md-12 text-center">                      
                <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>                    
                  L'eliminazione del varco è una operazione irreversibile si vuole procedere?
                </h4>
                { passageId != null  && 
                <Button className="btn alertBtn" onClick={ () => this.deletePassage() }>
                  <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina Varco
                </Button>
                }

                <Button className="btn mainBtn" onClick={ () => this.showConfirmDeletePassage(false) }>
                  <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faXmark} />&nbsp;&nbsp;Annulla
                </Button>
              </div>                              
              }

            </div>            
          </div>
        </div>
        }
        
      </Modal>
    )
  }
}

export default reduxForm({ form: 'BackofficePassagesModal'})(BackofficePassagesModal)
