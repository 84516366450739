import React, { Component } from 'react'
import propTypes, { bool, string } from 'prop-types'
import { Field, reduxForm, submit } from 'redux-form'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { Element } from 'react-scroll'
import FileInputField from '../../../components/FileInputField'
import DatePickerFormComponent from '../../../components/redux-form/DatePickerFormComponent'
import { MenuItem, DropdownButton } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { getVehicleIcon } from 'libs/vehicles'
import NewVehicleModal from 'components/Vehicles/NewVehicleModal'
import { areObligatoryDocsSelected, getDocumentColor, getMarkExpirationDate, MARK_TYPE_ELECTRIC, MARK_TYPE_FAMILY, MARK_TYPE_GREEN, MARK_TYPE_HYBRID } from 'libs/marks'
import { MARK_ENABLE_START_DATE } from 'libs/settings'
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import { format } from 'date-fns';
import * as html2pdf from 'html2pdf.js';
import App from 'App'

class DisableForm extends Component {
    static propTypes = {
        user: propTypes.object,
        disableTitle: propTypes.string
    }

    constructor(props) {
        super(props)
        this.GeneratePdf = this.GeneratePdf.bind(this)

    }

    GeneratePdf() {
        const btnGenerate = document.getElementById('btnGenerate')
        btnGenerate.style.display = "none";
        var element = document.getElementById('form-print');
        element.style.width = '793.7px';
        element.style.height = 'auto';
        element.style.scroll = 'auto';
        const opt = {
            margin: 0,
            pagesplit: true,
            filename: 'domanda-contrassegno-permante.pdf',
            image: { type: 'jpeg' },
            html2canvas: { scale: 2 },
            jsPDF: { format: 'A4', unit: 'in', orientation: 'p' }
        };

        html2pdf.default(element, opt);

        setTimeout(
            function () {
                element.style.height = '1122.52px';
            }, 1);

    }

    getMomentDate() {

        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${date}/${month}/${year}`;
    }

    render() {
        let {
            user,
            disableTitle,

        } = this.props

        return (

            /*<form
                id="form-print"
                encType="text/plain"
                className="form-control"
                style={{ width: '793.7px', height: '1122.52px', backgroundColor: 'white', padding: '40px 10px', borderRadius: '20px', boxShadow: '5px 5px 5px 5px grey', position: 'relative', overflowY: 'scroll', zIndex: 100 }}
            >*/

                <div style={{ textAlign: 'left', width: '100% !important', padding: '0 10px', overflowX: 'hidden' }}>
                    <div className="row" style={{ borderBottom: '1px solid black', paddingBottom: 20 }}>
                        <div className="col-md-4" style={{ height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                            <img src="/cities/pistoia/logo-city.png" alt="logo città" style={{ height: '100%' }}></img>
                        </div>

                        <div className="col-md-4" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <span>Amministrazione destinataria</span>
                            <strong>Comune di Pistoia</strong>
                            <span>Ufficio destinatario</span>
                            <strong style={{ textAlign: 'center' }}>U.O. Procedimenti sanzionatori, permessi front-office</strong>
                        </div>

                        {disableTitle == "Domanda di rilascio o rinnovo del contrassegno temporaneo per veicoli a servizio dei disabili" ? (
                            <div className="col-md-4" style={{ display: 'flex', justifyContent: 'center' }} >
                                <div style={{ width: '210px', height: '155px', border: '1px solid black' }}>MARCA DA BOLLO</div>
                            </div>
                        ) : ('')}

                    </div>

                    <div style={{ margin: '50px 0' }}>
                        <h2 style={{ textAlign: 'center', marginTop: 20, marginBottom: 15, fontWeight: 'bold' }}>{disableTitle}</h2>
                        <h5 style={{ textAlign: 'center' }}>Ai sensi dell'articolo 381 del Decreto del Presidente della Repubblica 16/12/1992, n.495</h5>
                    </div>

                    <div className="sottoscritto paragrafo">
                        <h4 style={{ marginBottom: '10px' }}>Il sottoscritto</h4>
                        <div className="row pb10">
                            <div className="col-md-4" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedName" className="form-label">Nome</label>
                                <input type="text" className="form-control" id="subscribedName" value={user.firstName} name="subscribedName" readOnly></input>
                            </div>

                            <div className="col-md-4" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedLastname" className="form-label">Cognome</label>
                                <input type="text" className="form-control" id="subscribedLastname" value={user.lastName} name="subscribedLastname" readOnly></input>
                            </div>
                            <div className="col-md-4" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedFiscalcode" className="form-label">Codice Fiscale</label>
                                <input type="text" className="form-control" style={{ textTransform: 'uppercase' }} id="subscribedFiscalcode" value={user.fiscalCode} name="subscribedFiscalcode" readOnly></input>
                            </div>
                        </div>

                        <div className="row pb10">
                            <div className="col-md-3" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscibedBirthdate" className="form-label">Data di nascita</label>
                                <input type="text" className="form-control" id="subscibedBirthdate" ></input>
                            </div>
                            <div className="col-md-2" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedSex" className="form-label">Sesso</label>
                                <input type="text" className="form-control" id="subscribedSex" value={user.sex} readOnly></input>
                            </div>
                            <div className="col-md-4" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedBirthplace" className="form-label">Luogo di nascita</label>
                                <input type="text" className="form-control" id="subscribedBirthplace" value={user.birthPlace} readOnly></input>
                            </div>
                            <div className="col-md-3" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedCittadinanza" className="form-label">Cittadinanza</label>
                                <input type="text" className="form-control" id="subscribedCittadinanza"></input>
                            </div>
                        </div>

                        <div className="row pb10">
                            <label style={{ display: 'block' }}>Residenza</label>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedProvince" className="form-label">Prov.</label>
                                <input type="text" className="form-control" id="subscribedProvince" style={{ textTransform: 'uppercase' }}></input>
                            </div>
                            <div className="col-md-2" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedComune" className="form-label">Comune</label>
                                <input type="text" className="form-control" id="subscribedComune" ></input>
                            </div>
                            <div className="col-md-3" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedAddress" className="form-label">Indirizzo</label>
                                <input type="text" className="form-control" id="subscribedAddress" value={user.residenceAddress} readOnly></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedCivicAddress" className="form-label">Civico</label>
                                <input type="text" className="form-control" id="subscribedCivicAddress" value={user.residenceCivicNumber} readOnly></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedInternalAddress" className="form-label">Barrato</label>
                                <input type="text" className="form-control" id="subscribedInternalAddress"></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedStairAddress" className="form-label">Scala</label>
                                <input type="text" className="form-control" id="subscribedStairAddress"></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedFloorAddress" className="form-label">Piano</label>
                                <input type="text" className="form-control" id="subscribedFloorAddress"></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px', textAlign: 'center' }}>
                                <label htmlFor="subscribedNoCivicAddress" className="form-label">SNC</label>
                                <input style={{ marginTop: 0 }} type="checkbox" className="form-control" id="subscribedNoCivicAddress"></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedCapAddress" className="form-label">CAP</label>
                                <input style={{ padding: '5px' }} type="text" className="form-control" id="subscribedCapAddress" value={user.residenceCap} readOnly></input>
                            </div>
                        </div>

                        <div className="row pb10">
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedPhone" className="form-label">Cell.</label>
                                <input type="text" className="form-control" id="subscribedPhone" value={user.phone != null ? user.phone : ''} readOnly></input>
                            </div>
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedLandlinePhone" className="form-label">Tel. fisso</label>
                                <input type="text" className="form-control" id="subscribedLandlinePhone" ></input>
                            </div>
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedEmail" className="form-label">Email ordinaria</label>
                                <input type="email" className="form-control" id="subscribedEmail" value={user.email} readOnly></input>
                            </div>
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="subscribedCertifiedEmail" className="form-label">Email certificata</label>
                                <input type="email" className="form-control" id="subscribedCertifiedEmail"></input>
                            </div>
                        </div>
                    </div>

                    <div className="row paragrafo" style={{ marginBottom: '120px' }}>
                        <h5 style={{ marginBottom: 0 }}><strong>domiciliazione delle comunicazioni relative al procedimento</strong></h5>
                        <span>(articolo 3-bis, comma 4-quinquies del Decreto Legislativo 07/03/2005, n.82)</span>
                        <div className="col-md-12" style={{ padding: '0 ' }}>
                            <label htmlFor="exampleInputPassword1" className="form-label">Le comunicazioni relative al procedimento dovranno essere inviate al seguente indirizzo di posta elettronica</label>
                            <input type="text" className="form-control" id="exampleInputPassword1"></input>
                        </div>
                    </div>

                    <div className="richiedente paragrafo" style={{ paddingBottom: 10 }}>
                        <h5 style={{ marginBottom: '10px' }}>Soggetto interessato</h5>

                        <div className="form-check">
                            <input style={{ marginRight: 10 }} className="form-check-input" type="radio" name="flexRadioDefault" id="persestesso"></input>
                            <label className="form-check-label" htmlFor="persestesso">per conto della propria persona</label>
                        </div>

                        <div className="form-check">
                            <input style={{ marginRight: 10 }} className="form-check-input" type="radio" name="flexRadioDefault" id="percontodi" defaultChecked></input>
                            <label className="form-check-label" htmlFor="percontodi">per conto di</label>
                        </div>

                        <div className="row pb10">
                            <div className="col-md-4" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantName" className="form-label">Nome</label>
                                <input type="text" className="form-control" id="applicantName" ></input>
                            </div>
                            <div className="col-md-4" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantLastname" className="form-label">Cognome</label>
                                <input type="text" className="form-control" id="applicantLastname" ></input>
                            </div>
                            <div className="col-md-4" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantFiscalcode" className="form-label">Codice Fiscale</label>
                                <input type="text" className="form-control" id="applicantFiscalcode" style={{ textTransform: 'uppercase' }}></input>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-md-3" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantBirthdate" className="form-label">Data di nascita</label>
                                <input type="date" className="form-control" id="applicantBirthdate"></input>
                            </div>
                            <div className="form-group col-md-2" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantSex">Sesso</label>
                                <select className="form-control" id="applicantSex">
                                    <option defaultValue>Seleziona il sesso</option>
                                    <option>M</option>
                                    <option>F</option>
                                </select>
                            </div>
                            <div className="col-md-4" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantBirthplace" className="form-label">Luogo di nascita</label>
                                <input type="text" className="form-control" id="applicantBirthplace"></input>
                            </div>
                            <div className="col-md-3" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantCittadinanza" className="form-label">Cittadinanza</label>
                                <input type="text" className="form-control" id="applicantCittadinanza"></input>
                            </div>
                        </div>

                        <div className="row pb10">
                            <label style={{ display: 'block' }}>Residenza</label>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantProvince" className="form-label">Prov.</label>
                                <input type="text" className="form-control" id="exampleInputEmail1" style={{ textTransform: 'uppercase' }}></input>
                            </div>
                            <div className="col-md-2" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantMunicipality" className="form-label">Comune</label>
                                <input type="text" className="form-control" id="applicantMunicipality" ></input>
                            </div>
                            <div className="col-md-3" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantAddress" className="form-label">Indirizzo</label>
                                <input type="text" className="form-control" id="applicantAddress" ></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantCivicAddress" className="form-label">Civico</label>
                                <input type="text" className="form-control" id="applicantCivicAddress"></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantInternalAddress" className="form-label">Interno</label>
                                <input type="text" className="form-control" id="applicantInternalAddress"></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantStairAddress" className="form-label">Scala</label>
                                <input type="text" className="form-control" id="applicantStairAddress"></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantFloorAddress" className="form-label">Piano</label>
                                <input type="text" className="form-control" id="applicantFloorAddress"></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px', textAlign: 'center' }}>
                                <label htmlFor="applicantNoCivicAddress" className="form-label">SNC</label>
                                <input type="checkbox" className="form-control" id="applicantNoCivicAddress" style={{ marginTop: 0 }}></input>
                            </div>
                            <div className="col-md-1" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantCapAddress" className="form-label">CAP</label>
                                <input style={{ padding: '5px' }} type="text" className="form-control" id="applicantCapAddress"></input>
                            </div>
                        </div>

                        <div className="row pb10">
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantPhone" className="form-label">Cell.</label>
                                <input type="text" className="form-control" id="applicantPhone" ></input>
                            </div>
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantLandlinephone" className="form-label">Tel. fisso</label>
                                <input type="text" className="form-control" id="applicantLandlinephone"></input>
                            </div>
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantEmail" className="form-label">Email ordinaria</label>
                                <input type="email" className="form-control" id="applicantEmail"></input>
                            </div>
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="applicantCertifiedemail" className="form-label">Email certificata</label>
                                <input type="email" className="form-control" id="applicantCertifiedemail"></input>
                            </div>
                        </div>

                        <div className="row pb10" style={{ padding: '10px 5px' }}>
                            <h4 style={{ marginBottom: 0 }}>In qualità di (*)</h4>
                            <div className="col-md-12" style={{ padding: '0 ' }}>
                                <label htmlFor="role" className="form-label">Ruolo</label>
                                <select id="role" className="form-select" style={{ width: '100%', padding: '5px', borderRadius: '3px', backgroundColor: 'white', marginBottom: 5 }}>
                                    <option defaultValue>Seleziona il ruolo</option>
                                    <option value="1">Amministratore di sostegno</option>
                                    <option value="2">Curatore</option>
                                    <option value="3">Potestà genitoriale</option>
                                    <option value="4">Familiare</option>
                                    <option value="5">Tutore legale</option>
                                </select>
                            </div>
                            <span>In qualità di (*)<br></br>amministratore di sostegno/curatore/esercente/potestà genitoriale/familiare/tutore legale</span>
                        </div>
                    </div>

                    <div className="paragrafo" style={{ margin: '40px 0 20px 0' }}>
                        <h4 style={{ fontWeight: 'bold', marginBottom: 10 }}>in possesso del contrassegno disabili</h4>
                        <div className="row">
                            <div className="col-md-2" style={{ padding: '0 5px' }}>
                                <label htmlFor="marknumber" className="form-label">N. permesso</label>
                                <input type="text" className="form-control" id="marknumber"></input>
                            </div>
                            <div className="col-md-3" style={{ padding: '0 5px' }}>
                                <label htmlFor="releasedate" className="form-label">Data rilascio</label>
                                <input type="date" className="form-control" id="releasedate"></input>
                            </div>
                            <div className="col-md-3" style={{ padding: '0 5px' }}>
                                <label htmlFor="expirydate" className="form-label">Data scadenza</label>
                                <input type="date" className="form-control" id="expirydate"></input>
                            </div>
                            <div className="col-md-4" style={{ padding: '0 5px' }}>
                                <label htmlFor="referencebody" className="form-label">Ente di riferimento</label>
                                <input type="text" className="form-control" id="referencebody"></input>
                            </div>
                        </div>
                    </div>

                    <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>CHIEDE</h3>
                    <div className="paragrafo">

                        <div className="form-check row releaseDisableMark">
                            <input style={{ marginRight: 10 }} className="form-check-input" type="radio" name="flexRadioDefault" id="requestnewmark"></input>
                            <label className="form-check-label" htmlFor="requestnewmark">il rilascio del contrassegno permanente per i veicoli a servizio dei disabili</label>
                            <p style={{ marginLeft: 25 }} >(pertanto allega documentazione attestante lo stato di invalidità)</p>
                        </div>

                        <div className="form-check row releaseDisableMark">
                            <input style={{ marginRight: 10 }} className="form-check-input" type="radio" name="flexRadioDefault" id="requestAfterTheft"></input>
                            <label className="form-check-label" htmlFor="requestAfterTheft">il rilascio del contrassegno permanente per i veicoli a servizio dei disabili a seguito di furto</label>
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="marknumber" className="form-label">Numero</label>
                                <input type="text" className="form-control" id="marknumber"></input>
                            </div>
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="expirydate" className="form-label">Data scadenza</label>
                                <input type="date" className="form-control" id="expirydate"></input>
                            </div>
                            <p style={{ marginLeft: 25 }} >(pertanto allega dichiarazione di furto o copia della denuncia presentata agli organi di Pubblica Sicurezza)</p>
                        </div>

                        <div className="form-check row releaseDisableMark">
                            <input style={{ marginRight: 5 }} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"></input>
                            <label className="form-check-label" htmlFor="flexRadioDefault2">il rilascio del contrassegno permanente per i veicoli a servizio dei disabili a causa di deterioramento</label>
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="marknumber" className="form-label">Numero</label>
                                <input type="text" className="form-control" id="marknumber"></input>
                            </div>
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="expirydate" className="form-label">Data scadenza</label>
                                <input type="date" className="form-control" id="expirydate"></input>
                            </div>
                            <p style={{ marginLeft: 25 }} >(pertanto allega il contrassegno originale)</p>
                        </div>

                        <div className="form-check row releaseDisableMark">
                            <input style={{ marginRight: 10 }} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"></input>
                            <label className="form-check-label" htmlFor="flexRadioDefault2">il rilascio del contrassegno permanente per i veicoli a servizio dei disabili a seguito di smarrimento</label>
                            <div className="col-md-6" style={{ padding: '0 5px' }}>
                                <label htmlFor="marknumber" className="form-label">Numero</label>
                                <input type="text" className="form-control" id="marknumber"></input>
                            </div>
                            <div className="col-md-6" style={{ padding: '0 5px', marginBottom: '25px' }}>
                                <label htmlFor="expirydate" className="form-label">Data scadenza</label>
                                <input type="date" className="form-control" id="expirydate"></input>
                            </div>
                        </div>

                        <div className="form-check row releaseDisableMark">
                            <div>
                                <input style={{ marginRight: 10 }} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"></input>
                                <label className="form-check-label" htmlFor="flexRadioDefault2">il rinnovo del contrassegno permanente per i veicoli a servizio dei disabili</label>
                            </div>
                            <div>
                                <div className="col-md-6" style={{ padding: '0 5px' }}>
                                    <label htmlFor="marknumber" className="form-label">Numero</label>
                                    <input type="text" className="form-control" id="marknumber"></input>
                                </div>
                                <div className="col-md-6" style={{ padding: '0 5px' }}>
                                    <label htmlFor="expirydate" className="form-label">Data scadenza</label>
                                    <input type="date" className="form-control" id="expirydate"></input>
                                </div>
                            </div>
                            <div>
                                <p style={{ marginLeft: 25, marginBottom: 0 }} >(pertanto allega il contrassegno originale)</p>
                                <p style={{ marginLeft: 25 }} >(pertanto allega documentazione attestante lo stato di invalidità)</p>
                            </div>
                        </div>
                    </div>

                    <div style={{ padding: '50px 30px', marginTop: 10 }}>
                        <p>Valendosi della facoltà prevista dall'articolo 46 e dall'articolo 47 del Decreto del Presidente della Repubblica 28/12/2000, n.445, consapevole delle sanzioni penali previste dall'articolo 76 del Decreto del Presidente della Repubblica 28/12/2000, n.445 e dell'articolo 483 del Codice Penale nel caso di dichiarazioni non veritiere e di falsità in atti,</p>
                        <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>DICHIARA</h4>
                        <div className="form-check" style={{ display: 'flex', alignItems: 'start' }}>
                            <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" defaultChecked></input>
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                di essere consapevole delle sanzioni penali cui può andare incontro in caso di falsità negli atti e di dichiarazioni mendaci
                            </label>
                        </div>
                        <div className="form-check" style={{ display: 'flex', alignItems: 'start' }}>
                            <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox" value="" id="flexCheckChecked" defaultChecked></input>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                di essere consapevole che in caso di dichiarazioni non veritiere il sottoscritto decade dai benefici conseguenti al provvedimento eventualmente emanato sulla base delle presenti dichiarazioni
                            </label>
                        </div>
                        <div className="form-check">
                            <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox" value="" id="flexCheckChecked" defaultChecked></input>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                di aver smarrito il contrassegno <span style={{ fontSize: '14px', fontWeight: '400' }}>(se chiesto il rilascio del contrassegno a seguito di smarrimento)</span>
                            </label>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Eventuali annotazioni</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="7"></textarea>
                        </div>
                    </div>

                    <div className="paragrafo">
                        <h4 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}>Elenco allegati</h4>
                        <p style={{ textAlign: 'center', fontSize: '14px' }}>(barrare tutti gli allegati richiesti in fase di presentazione della pratica ed elencati sul portale)</p>

                        <div className="form-check" >
                            <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" defaultChecked></input>
                            <label className="form-check-label" style={{ marginBottom: 0 }} htmlFor="flexCheckDefault">Due fotografie in formato tessera</label><br></br>
                            <span style={{ marginLeft: 25, paddingBottom: 5 }} >(da portare presso l'ufficio XXX al momento del ritiro del contrassegno)</span>
                        </div>
                        <div className="form-check" style={{ display: 'flex', alignItems: 'start' }}>
                            <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox" value="" id="flexCheckChecked"></input>
                            <label className="form-check-label" htmlFor="flexCheckChecked">Contrassegno originale</label>
                            <span style={{ marginLeft: 25 }} >(da allegare alla richiesta di permesso)</span>
                        </div>
                        <div className="form-check" >
                            <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                            <label className="form-check-label" htmlFor="flexCheckDefault">Dichiarazione di furto o copia della denuncia presentata agli organi di Pubblica Sicurezza</label>
                        </div>
                        <div className="form-check" style={{ display: 'flex', alignItems: 'start' }}>
                            <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox" value="" id="flexCheckChecked" defaultChecked></input>
                            <label className="form-check-label" htmlFor="flexCheckChecked">Documentazione attestante lo stato di invalidità</label>
                        </div>
                        <div className="form-check" style={{ display: 'flex', alignItems: 'start' }}>
                            <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox" value="" id="flexCheckChecked"></input>
                            <label className="form-check-label" htmlFor="flexCheckChecked">Pagamento dell'imposta di bollo</label>
                        </div>
                        <div className="form-check" style={{ display: 'flex', alignItems: 'start' }}>
                            <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox" value="" id="flexCheckChecked" defaultChecked></input>
                            <label className="form-check-label" htmlFor="flexCheckChecked">Copia del documento d'identità</label>
                            <span style={{ marginLeft: 25 }} >(da allegare alla richiesta di permesso)</span>
                        </div>
                        <div className="form-check" style={{ display: 'flex', alignItems: 'start' }}>
                            <div className="col-md-3" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox" value="" id="flexCheckChecked"></input>
                                <label className="form-check-label" htmlFor="flexCheckChecked">Altri allegati</label>
                            </div>
                            <div className="col-md-9" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <input type="text" className="form-control" id="marknumber"></input>
                            </div>
                        </div>
                    </div>

                    <div className="paragrafo">
                        <h4 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}>Informativa sul trattamento dei dati personali</h4>
                        <p style={{ textAlign: 'center', fontSize: '14px' }}>(ai sensi del Regolamento Comunitario 27/04/2016, n.2016/679 e del Decreto Legislativo 30/06/2023, n.196)</p>
                        <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                            <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" name="flexCheckDefault" defaultChecked required ></input>
                            <label className="form-check-label" htmlFor="flexCheckDefault">dichiara di aver preso visione dell'informativa relativa al trattamento dei dati personali pubblicata sul sito internet istituzionale dell'amministrazione destinataria, titolare del trattamento delle informazioni trasmesse all'atto della presentazione della pratica.</label>
                        </div>
                    </div>

                    <div style={{ margin: '30px 0', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        <p>Pistoia, {this.getMomentDate()}</p>
                        <div style={{ display: 'flex', alignItems: 'end' }}>
                            <div style={{ paddingRight: 10 }}>Firma</div>
                            <div style={{ width: '300px', border: '1px solid black' }}></div>
                        </div>
                    </div>


                    <div id="btnGenerate" style={{ display: 'flex', justifyContent: 'center' }}>
                        <button type="button"
                            className="btn btn-primary"
                            //onserverclick="btnSave_OnServerClick"
                            onClick={() => this.GeneratePdf()}
                            value="GeneratePdf">Genera PDF
                        </button>
                    </div>
                </div>
            /*</form>*/

        )
    }
}

export default (DisableForm)