import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { getSubjectDescription, getSubMessageType } from 'libs/messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import Parser from 'html-react-parser';
import { getHtmlMsgFromString } from 'libs/news';

moment.locale('it')

class BackofficeMessageModal extends Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    msg: PropTypes.object,
    onClose: PropTypes.func.isRequired,    
    onReplyMessage: PropTypes.func.isRequired,    
    user: PropTypes.object.isRequired
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      content: '',
      checkErrors: null,
      sendErrors: null,
      refMmsg: this.props.msg
    }

    this.sendMessage = this.sendMessage.bind(this)
    this.checkFields = this.checkFields.bind(this)
    this.close       = this.close.bind(this)
   
  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    //console.log("componentDidMount")
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
   componentDidUpdate(prevProps) {    
    if ( prevProps.show != this.props.show ) {
    
      this.setState({        
        content: '',
        checkErrors: null,
        sendErrors: null      
      })      

    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []   

    if ( this.state.content == null || this.state.content.length <= 10 ) {
      errors.push("Il messaggio da inviare è vuoto")
    }

    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  sendMessage() {
    console.log("SEND MESSAGE",this.state.content)

    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }
    
    var msg = {      
      content: this.state.content
    }

    SostaOnlineApiService.replyMessage(msg,this.props.msg.id)
    .then( result => {      
      
      if ( this.props.onReplyMessage != null ) {
        this.props.onReplyMessage(msg)
      }

      this.close()      

    })
    .catch( error => {
      console.log("ERROR",error)
      this.setState({ sendErrors: "Errore durante l'invio del messagggio, riprovare pù tardi!" })
    })
    
    
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSelectedMessageType() {
    return getSubMessageType(this.state.messageType,this.state.subMessageType)    
  }


  /**
   * --------------------------------------------------------------
   */
  close() {
    if ( this.props.onClose != null )
      this.props.onClose()        
  }

  /**
   * --------------------------------------------------------------
   * @param {*} user 
   * @returns 
   */
  getUserDescr(user) {
    return user ? user.firstName + " " + user.lastName + " ( " + user.email + " ) ": "ASSISTENZA"        
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,      
      msg,
      user
    } = this.props

    const {
      sendErrors,
      checkErrors      
    } = this.state
      
    return (
      <Modal
        open={show}
        onClose={this.close}
        closeOnEsc={true}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        //focusTrapped={true}
        center
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faEnvelopeOpen} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>MESSAGGIO</strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4> 

        { msg == null && 
        <div className="col-md-12">
          <div className="app-modal-body">
            <div className="row">          
              Loading ...
            </div>
          </div>
        </div>
        }

        { msg != null && 
        <div className="col-md-12">
          <div className="app-modal-body">
            <div className="row">
                <h5 style={{ marginTop: 15 , marginBottom: 15 }}>                
                  <strong>Da:&nbsp;&nbsp;</strong>
                  { msg.FromUser &&  
                    <OverlayTrigger placement="top" overlay={<Tooltip id="1">Clicca per accedere al profilo dell'utente { msg.FromUser.firstName+" "+msg.FromUser.lastName }</Tooltip>}>
                    <NavLink to={'/backoffice/users/'+msg.FromUser.id} className="mainBtn" style={{ marginLeft: 0 }}>
                      <FontAwesomeIcon size="1x" className="mainIconsRevNoAction" icon={solidIcons.faUser} />
                    </NavLink> 
                    </OverlayTrigger>
                  }                  
                  { this.getUserDescr(msg.FromUser) }
                </h5>
            </div>
            <div className="row" >
                <h5 style={{ marginTop: 15 , marginBottom: 15 }}>     
                  <strong>A:&nbsp;&nbsp;</strong>             
                  { msg.ToUser &&  
                    <OverlayTrigger placement="top" overlay={<Tooltip id="2">Clicca per accedere al profilo dell'utente  { msg.ToUser.firstName+" "+msg.ToUser.lastName }</Tooltip>}>
                    <NavLink to={'/backoffice/users/'+msg.ToUser.id} className="mainBtn" style={{ marginLeft: "7px" }}>
                      <FontAwesomeIcon size="1x" className="mainIconsRevNoAction" icon={solidIcons.faUser} />
                    </NavLink> 
                    </OverlayTrigger>
                    }
                  { this.getUserDescr(msg.ToUser) }
                </h5>
            </div>
            <div className="row">
                <h5 style={{ marginTop: 0 , marginBottom: 10}} ><strong>Soggetto:&nbsp;&nbsp;</strong>{ getSubjectDescription(msg.subject) }</h5>
            </div>                  
            <div className="row">
                <h5 style={{ marginTop: 0 , marginBottom: 10}} ><strong>Data:&nbsp;&nbsp;</strong>{ moment(msg.createdAt).format('LLLL') }</h5>
            </div>                  
            <div className="row">
                <h5 style={{ marginTop: 10 , marginBottom: 5 }}><strong>Messaggio:</strong></h5>              
                <h5 style={{ 
                  marginTop: 0 , 
                  marginBottom: 10, 
                  color: 'grey', 
                  fontWeight: 'bold' , 
                  borderStyle: "solid" , 
                  borderWidth: "1px",
                  borderColor: "grey",
                  padding: "5px",
                  borderRadius: "5px"
                  }}>{getHtmlMsgFromString(msg.content)}</h5>   
            </div>     

            { msg.toId == null  &&  msg.FromUser != null && !msg.FromUser.archived  && 
            <React.Fragment>
              <div className="row">              
                  <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Rispondi:</strong></h5>              
                  <textarea 
                    style={{ 
                      color: "grey", 
                      fontWeight: "bold" ,                       
                      height: "200px"
                    }}
                    name="content" 
                    className="form-control"                     
                    onChange={ e => { this.setState({content: e.target.value}) }}   
                    value={this.state.content}                 
                    required />                              
                  <br/>
              </div>             

              <div className="row">
                { sendErrors && 
                  <h5 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {sendErrors}
                  </h5>
                }
                { checkErrors && checkErrors.length > 0 && 
                  <h5 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                  </h5>
                }
              </div>

              <div className="row">
                <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  <Button className="btn alertBtn"   onClick={this.close}>Annulla</Button>{' '}
                  <Button className="btn mainBtn" onClick={() => this.sendMessage()} >Invia</Button>
                </div>
              </div>          
            </React.Fragment>
            }
            {  msg.FromUser != null && msg.FromUser.archived && 
            <React.Fragment>
              <div className="row">
                  <br/><br/>
                  <h5>Utente Eliminato</h5>
                  <br/><br/>
              </div>
            </React.Fragment>
            }
            { msg.toId != null  && 
              <div className="row">
              <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                <Button className="btn alertBtn"   onClick={this.close}>Esci</Button>{' '}                
              </div>
            </div>   
            }
          </div>
        </div>           
        }  
      </Modal>
    )
  }
}

export default reduxForm({ form: 'BackofficeMessageModal'})(BackofficeMessageModal)
