import {Component}           from 'react'
import SostaOnlineApiService from 'services/SostaOnlineApiService';

export default class Privacy extends Component {

    constructor(props) {
        super(props)

        this.state = {
            privacyUrl : ""
        }
    }

    componentDidMount(){     
        SostaOnlineApiService.fetchPublicConfigs()
        .then( response => {            
            if ( response.data && response.data.privacy && response.data.privacy.privacyUrl ) {
                this.setState({ privacyUrl: response.data.privacy.privacyUrl })
                document.getElementById("privacy").click()
                history.back()
            }
                
        })
        
    }

    render() {    
        return(
            <div>
                <a id="privacy" href={this.state.privacyUrl} target="_blank">Loading...</a>
            </div>
        );
    }
}
