export const AUTH_LOGIN_REQUEST                  = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_ERROR                    = "AUTH_LOGIN_ERROR";
export const AUTH_LOGIN_SUCCESS                  = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGOUT                         = "AUTH_LOGOUT";
export const AUTH_SAVE_CREDENTIALS               = "AUTH_SAVE_CREDENTIALS";
export const AUTH_CLEAR_CREDENTIALS              = "AUTH_CLEAR_CREDENTIALS";

export const FETCH_PROJECTS_SUCCESS              = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECT_SUCCESS               = "FETCH_PROJECT_SUCCESS";

export const SAVE_REGISTRATION_RESIDENT_STEP_ONE = "SAVE_REGISTRATION_RESIDENT_STEP_ONE";
export const SAVE_REGISTRATION_RESIDENT_STEP_TWO = "SAVE_REGISTRATION_RESIDENT_STEP_TWO";
export const SAVE_REGISTRATION_GENERIC_STEP_ONE  = "SAVE_REGISTRATION_GENERIC_STEP_ONE";
export const SAVE_REGISTRATION_GENERIC_STEP_TWO  = "SAVE_REGISTRATION_GENERIC_STEP_TWO";

export const FETCH_USERS_SUCCESS                 = "FETCH_USERS_SUCCESS";

export const LOAD_REGISTRATION_INITIAL_DATA      = "LOAD_REGISTRATION_INITIAL_DATA";
