import moment from 'moment'

// TYPES
export const types = {
  I_UNDERSTAND: "COOKIE_LAW/I_UNDERSTAND",
  RESET: "COOKIE_LAW/RESET",
};

// INITIAL STATE
export const initialState = {
  understand: false,
  lastUpdate: null
};

// REDUCER
export default function auth(state = initialState, action) {
  switch (action.type) {
    case types.I_UNDERSTAND:
      return {
        ...state,
        understand: true,
        lastUpdate: moment().format()
      };
    case types.RESET:
      return {
        ...state,
        understand: false,
        lastUpdate: null
      };
    default:
      return state;
  }
}

// ACTIONS
export const actions = {
  setIUnderstand: () => {
    return {
      type: types.I_UNDERSTAND,
    };
  },
  reset: () => {
    return {
      type: types.RESET,
    };
  }
};