import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FileManager from './FileManager'
import SostaOnlineApiService from '../../services/SostaOnlineApiService'

export default class FileManagerContainer extends Component {
  static propTypes = {
    onFileSelected: PropTypes.func,
    documentTypeId: PropTypes.any
  }

  currentNewFile

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      files: [],      
      error: null,
      showUploadForm: false,
      newDocumentFileInputTouched: false,
      newDocumentName: '',
      newDocumentType: '',
      newDocumentSubmitting: false,
      newDocumentError: null,
      validateErrors: {},
      selectedRows: []
    }

    this.toggleShowUploadForm = this.toggleShowUploadForm.bind(this)            
    this.onNewDocumentSubmit = this.onNewDocumentSubmit.bind(this)
    this.onRowSelected = this.onRowSelected.bind(this)
    this.onFileSubmitSelected = this.onFileSubmitSelected.bind(this)
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async componentDidMount () {
    this.setState({loading: true})
    await Promise.all([
      this.fetchUserFiles(),      
    ])
    this.setState({loading: false})
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  async fetchUserFiles () {
    const {documentTypeId} = this.props
    const response = await SostaOnlineApiService.fetchMyDocuments()

    if (response.result !== 'ok') {
      this.setState({ error: 'Errore di comunicazione con il server' })
      return
    }

    if (documentTypeId) {
      this.setState({ files: response.data.filter(item => item.DocumentTypeId === documentTypeId) })
    } else {
      this.setState({ files: response.data })
    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  toggleShowUploadForm () {
    const { showUploadForm, newDocumentSubmitting } = this.state

    if (newDocumentSubmitting) {
      return
    }

    if (showUploadForm) {
      this.setState({
        newDocumentName: '',
        newDocumentType: '',
        newDocumentFileInputTouched: false,
        newDocumentError: null,
        validateErrors: {}
      })
    }
    this.setState({showUploadForm: !showUploadForm})
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  async onNewDocumentSubmit () {

    try {                  
      this.setState({loading: true}, async () => {

        await this.fetchUserFiles()

        this.setState({
          newDocumentSubmitting: false,
          loading: false
        })

        this.toggleShowUploadForm()

        const { files } = this.state

        if (this.props.onFileSelected )
          this.props.onFileSelected(files[files.length-1])
          
      })
    } 
    catch (e) {
      this.setState({
        newDocumentSubmitting: false,
        newDocumentError: 'Errore durante il caricamento del documento'
      })
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} index 
   * @param {*} row 
   * @returns 
   */
  onRowSelected (index, row) {
    if (!this.props.onFileSelected) {
      return
    }

    const newSelectedRows = []
    newSelectedRows.push(index)
    this.setState({selectedRows: newSelectedRows})
  }

  /**
   * --------------------------------------------------------------
   */
  onFileSubmitSelected () {
    const { onFileSelected } = this.props
    const { selectedRows, files } = this.state
    if (this.props.onFileSelected && selectedRows.length > 0) {
      onFileSelected(files[selectedRows[0]])
    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {
    
    const {
      loading,
      files,
      error,
      showUploadForm,      
      selectedRows
    } = this.state
    return (
      <FileManager        
        loading={loading}        
        error={error}
        files={files}
        showUploadForm={showUploadForm}        
        toggleShowUploadForm={this.toggleShowUploadForm}
        onNewDocumentSubmit={this.onNewDocumentSubmit}
        onRowSelected={this.onRowSelected}
        selectedRows={selectedRows}
        onFileSubmitSelected={this.onFileSubmitSelected}
      />
    )
  }
}
