
// TYPES
export const types = {
  FETCH_CATEGORIES: 'CATEGORIES/FETCH_CATEGORIES'
}

// INITIAL STATE
export const initialState = {
  data: [],
  loading: false,
  error: null
}

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CATEGORIES + '/REQUEST':
      return {
        ...state,
        loading: true
      }
    case types.FETCH_CATEGORIES + '/SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.data.data
      }
    case types.FETCH_CATEGORIES + '/FAILURE':
      return {
        ...state,
        loading: false,
        error: true
      }

    default:
      return state
  }
}

// ACTIONS
export const actions = {

  fetchCategories: () => {
    return {
      type: types.FETCH_CATEGORIES,
      $fetch: {
        url: '/api/v1/categories',
        method: 'GET'
      }
    }
  }
}
