import {Component}                      from 'react'
import {bindActionCreators}             from 'redux'
import {connect}                        from 'react-redux'
import PropTypes                        from 'prop-types'
import {actions as areaActions}         from '../../../../redux/modules/Area'
import {actions as categoriesActions}   from '../../../../redux/modules/Categories'
import {actions as addressActions}      from '../../../../redux/modules/Address'
import {actions as registrationActions} from '../../../../redux/modules/Registration'
import SostaOnlineApiService            from '../../../../services/SostaOnlineApiService'
import { Oval }                         from 'react-loader-spinner'
import {reduxForm, Field}               from 'redux-form'
import {NavLink}                        from 'react-router-dom'
import DatePickerFormComponent          from '../../../../components/redux-form/DatePickerFormComponent'
import VirtualizedSelectFormComponent   from '../../../../components/redux-form/VirtualizedSelectFormComponent'
import { FontAwesomeIcon }              from '@fortawesome/react-fontawesome'
import  * as solidIcons                 from '@fortawesome/free-solid-svg-icons'
import { getCity, getComuni, getComuniWithoutCurrentCity }          from 'libs/geo' 
import './RegistrationUserInfo.css'
import { formatCityString, parseCap, parseCivicNumber, parseEmail, parseFiscalCode, parsePhoneNumber, parseVatNumber } from 'libs/utils'
import RegistrationCompleted            from 'containers/Registration/RegistrationEmailConfirmed/RegistrationEmailConfirmed'



// eslint-disable-next-line
const mailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// ----------------------------------------------------------------
//
//
const mapStateToProps = (state) => {

  var spidProfile   = state.auth.spidProfile;
  var initialValues = {}
  
  if ( spidProfile != null  ) {
    
    initialValues.spidProfile      = true
    initialValues.firstName        = spidProfile.name
    initialValues.lastName         = spidProfile.familyName 
    initialValues.password         = spidProfile.sessionId
    initialValues.repeatedPassword = spidProfile.sessionId

    if ( spidProfile.fiscalNumber != null )  {

      var fiscalArr = spidProfile.fiscalNumber.split("-")

      if ( fiscalArr.length > 1 ) {
        initialValues.fiscalCode = fiscalArr[1]
      }

    }

  }

  return { 
    initialValues: initialValues, 
    auth:          state.auth,
    areas:         state.area,
    addresses:     state.address
  }
}

// ----------------------------------------------------------------
//
//
const mapDispatchToProps = (dispatch) => {
  return {
    areaActions:         bindActionCreators(areaActions,         dispatch),
    categoriesActions:   bindActionCreators(categoriesActions,   dispatch),
    addressActions:      bindActionCreators(addressActions,      dispatch),
    registrationActions: bindActionCreators(registrationActions, dispatch)
  }
}

// ----------------------------------------------------------------
// TODO gestire le blacklist da database
//
const BLACKLIST_COMPANIES = []

// ----------------------------------------------------------------
//
//
const RESIDENT_TYPES = {
  resident: 'resident',
  domiciled: 'domiciled'
}




// ================================================================
//
//
class RegistrationUserInfo extends Component {

  // ----------------------------------------------------------------
  //
  //
  constructor (props) {
    super(props)
    
    this.state = {      
      selectedWorkArea:      null,
      selectedResidenceArea: null,
      formErrors: {},
      loading: false,
      category: null,
      parkingSpaceSelectedArea: null,      


      adressesOptions: props.addresses.data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      }),
      companyName:              null,
      companyLegalCity:         null,
      isResidentOrDomiciled:    RESIDENT_TYPES.resident,
      categoryAreas :           null,
      residenceDataForAllUsers: false,
      cities:   {},
      city:     {},
      province: {}      

    }

    this.onWorkAddressSelected         = this.onWorkAddressSelected.bind(this)
    this.onResidentAddressSelected     = this.onResidentAddressSelected.bind(this)
    this.onSubmit                      = this.onSubmit.bind(this)
    this.onParkingSpaceAddressSelected = this.onParkingSpaceAddressSelected.bind(this)
    this.onWorkAreaSelected            = this.onWorkAreaSelected.bind(this)    
    this.onCompanyNameChange           = this.onCompanyNameChange.bind(this)
    

  }

  // ----------------------------------------------------------------
  //
  //
  async componentDidMount () {    

    this.setState({loading: true})

    var publicConfigs = await SostaOnlineApiService.fetchPublicConfigs()    
      .catch( error => {
        console.log("ERROR",error)
      }) 

    if ( publicConfigs && publicConfigs.data != null  ) {
      this.setState({
        city: publicConfigs.data.city,
        residenceDataForAllUsers:  publicConfigs.data.residenceDataForAllUsers 
      })
    }
    
    var cities = await SostaOnlineApiService.fetchGeoCities()
      .catch( error => {
        console.error("ERROR",error)
      })
        
    if ( cities != null && cities.data != null ) {                          
      this.setState({ cities: cities.data })
    }

    var province = await SostaOnlineApiService.fetchGeoProvince()
      .catch( error => {
        console.error("ERROR",error)
      })
        
    if ( province != null && province.data != null ) {                          
      this.setState({ province: province.data })
    }    

    var category = await SostaOnlineApiService.fetchUserCategoryById(this.props.match.params.categoryId)    
      .catch( error => {
        console.error("ERROR",error)
      })

    if ( category != null && category.data != null  ) {
      this.setState({category: category.data })

      var categoryAreas = await SostaOnlineApiService.fetchCategoryAreas(category.data.id)
        .catch( error => {
          console.log("ERROR",error)
        })

      if (categoryAreas && categoryAreas.data != null ) {
        this.setState({categoryAreas: categoryAreas.data})
      }
  
    }
    
    this.props.areaActions.fetchAreas()    
    this.props.addressActions.fetchAddresses()

    this.setState({loading: false })
    
    
    
  }

  // ----------------------------------------------------------------
  //
  //
  onCompanyNameChange (e) {
    this.setState({companyName: e.target.value})
  }


  // ----------------------------------------------------------------
  //
  //
  isCompanyInBlackList () {
    if (!this.state.companyName) {
      return false
    }

    if (this.state.companyName.length < 7) {
      return false
    }

    for (let i = 0; i < BLACKLIST_COMPANIES.length; i++) {
      if (BLACKLIST_COMPANIES[i].toLowerCase().indexOf(this.state.companyName.toLowerCase()) > -1) {
        return true
      }
    }
    return false
  }

  // ----------------------------------------------------------------
  //
  //
  getAreasSelectOptions() {
    var options = []

    if ( this.state.categoryAreas ) {
      options = this.state.categoryAreas.map( item => { 
        return { label : item.name , value : item.id }
      })
    }

    return options
  }

  // ----------------------------------------------------------------
  //
  //
  onWorkAddressSelected (addressId) {
    if (!addressId) {
      this.setState({selectedWorkArea: null})
      return
    }

    let address = this.props.addresses.data.filter(item => {
      return item.id === addressId
    })

    this.setState({selectedWorkArea: address[0].Area})
  }

  // ----------------------------------------------------------------
  //
  //
  onResidentAddressSelected (addressId) {
    if (!addressId) {
      this.setState({selectedResidenceArea: null})
      return
    }

    let address = this.props.addresses.data.filter(item => {
      return item.id === addressId
    })
    this.setState({ selectedResidenceArea: address[0].Area})
  }

  // ----------------------------------------------------------------
  //
  //
  onWorkAreaSelected(areaId) {    
    var area = this.props.areas.data.find( item => { return item.id === areaId } )
    this.setState({selectedWorkArea: area})
  }

  // ----------------------------------------------------------------
  //
  //
  getAddressById (addressId) {
    let address = this.props.addresses.data.filter(item => item.id === addressId)
    return address[0]
  }

  // ----------------------------------------------------------------
  //
  //
  onSubmit (values) {
    
    this.setState({formErrors: {}})

    if (!mailRegExp.test(values.email)) {
      this.setState({formErrors: {
        email: 'E-mail non valida'
      }})
      return
    }

    if (values.password == null || values.password.length < 8) {
      this.setState({formErrors: {
        passwords: 'La password deve essere lunga almeno 8 caratteri'
      }})
      return
    }

    if (values.password !== values.repeatedPassword) {
      this.setState({formErrors: {
        passwords: 'Le 2 password non coincidono.'
      }})
      return
    }

    if ( this.state.category.worker || this.state.category.workerPerson ) {
      values['workAreaId'] = this.state.selectedWorkArea.id
    }

    var foundCity = getCity( this.state.cities, this.state.city.longName, this.state.city.province )

    if ( foundCity != null ) {
      values['workCity']  = formatCityString(foundCity.name, this.state.city.province)
    }

    if ( values.workAddressId ) {
      values['workAddress'] = this.getAddressById(values.workAddressId).name
    }
 
    values['workCap'] = this.state.city.cap


    if ( values.companyLegalAddressId ) {
      values['companyLegalAddress'] = this.getAddressById(values.companyLegalAddressId).name
    }
    

    if (this.state.category.resident) {
      
      values['residenceAreaId']  = this.state.selectedResidenceArea.id
      values['residenceAddress'] = this.getAddressById(values.residenceAddress).name      

      if ( foundCity != null ) {
        values['residenceCity']  =  formatCityString(foundCity.name, this.state.city.province)
      }

      values['residenceCap']     = this.state.city.cap

    }
    
    let user = {
      ...values,
      userCategories: [this.state.category.id]
    }

    SostaOnlineApiService.register(user)
    .then(data => {
      this.props.history.push('/register-reseller/completed')
      // this.props.registrationActions.registrationCompleted()
      // send email to operator and admin or better: a message in the fetchtodos
      // "a new reseller was registered on the insosta reseller area"
    })
    .catch(err => {
      if (err.error === 'User already exists') {
        this.setState({formErrors: {form: 'Attenzione! Questo utente è già registrato '}})
        return
      }
      if (err.error === "User is not present in Resident's Registry") {
        this.setState({formErrors: {form: 'Attenzione! Il tuo profilo non risulta tra i residenti del Comune di '+process.env.REACT_APP_CITYL}})
        return
      }

      this.setState({formErrors: {form: 'Errore durante la registrazione'}})
    })

    
  }

  // ----------------------------------------------------------------
  //
  //
  onParkingSpaceAddressSelected (addressId) {
    if (!addressId) {
      this.setState({parkingSpaceSelectedArea: null})
      return
    }

    let address = this.props.addresses.data.filter(item => {
      return item.id === addressId
    })
    this.setState({parkingSpaceSelectedArea: address[0].Area})
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity) {
    var check = false

    if ( formatCityString(this.state.city.longName , this.state.city.province ) == companyLegalCity  )
      check = true

    return check
  }
   
  /**
   * ----------------------------------------------------------------
   * @param {*} cityId 
   */
  onCompanyLegalCitySelected(city) {    

    this.setState({ companyLegalCity: city })
    this.props.change( 'companyLegalCity', city )

    if ( this.isPortalLegalCityEqualToCurrentCitySelected(city) ) 
      this.props.change( 'companyLegalCap', this.state.city.cap )
    else
      this.props.change( 'companyLegalCap', null )
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} e 
   */
  onCompanyLegalAddressChange (e) {
    this.props.change('companyLegalAddressId', null)
  }

  // ----------------------------------------------------------------
  //
  //
  render () {

    const {
      loading,      
      category,
      selectedWorkArea,
      selectedResidenceArea,
      parkingSpaceSelectedArea,
      formErrors,
      residenceDataForAllUsers,
      isResidentOrDomiciled,
      cities,
      city,
      companyLegalCity
    } = this.state


    const {       
      spidProfile,      
      addresses,      
      areas      
    } = this.props

    const comuni = getComuni(cities)
    const comuniWithoutCurrentCity = getComuniWithoutCurrentCity(cities,city)
    console.log("CATEGORIA R:", category)

    
    if ( !category || !addresses.data || !areas || loading) {
      return (
        <div>
          <div className="row"><h2></h2></div>
          <div className="row" style={{padding: "2em", height: "40em"}}>
            <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
          </div>
        </div>
        )
    }

    return (<div>
      <div className="col-md-8 col-md-push-2">
        <div className="panel panel-default panel-border-login">
          {/* INTESTAZIONE */}
          <div className={'row'}>
            <div style={{padding: 8}}>
              <div className="col-md-9 col-xs-9">
                <div className="registration-title">REGISTRAZIONE</div>
                <div className="registration-subtitle">
                  DA QUI PUOI RICHIEDERE UN NUOVO ACCOUNT PER LA PIATTAFORMA INSOSTA. <br />
                  INSERISCI TUTTE LE TUE INFORMAZIONI PERSONALI E QUELLE RIGUARDANTI <br />LA TUA CATEGORIA UTENTE.<br />
                </div>
              </div>
              <div className="col-xs-3 col-md-3">
                <a className="btn-social btn-outline">
                  {<FontAwesomeIcon  className="mainIconsNoAction" style={{ margin: "2rem" }} size="6x" icon={solidIcons.faShop} />  }
                </a>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <form onSubmit={this.props.handleSubmit(values => {
                this.onSubmit({...values})
              })}>
              <div className="box">
                {/* Inizio informazioni generali utente */}
                <fieldset>
                  <div className="registration-title text-left">
                    INFORMAZIONI GENERALI
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-md-6 text-left">
                        <label>Nome</label> 
                        <Field component="input" name="firstName" type="text" className="mainField" required />
                      </div>
                      <div className="col-md-6 text-left">
                        <label>Cognome</label>
                        <Field component="input" name="lastName" type="text" className="mainField"  required />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 text-left">
                        <label>E-mail</label>
                        <Field 
                          parse={ (value,name) => parseEmail(value) }
                          component="input" 
                          name="email" 
                          type="text" 
                          className={'mainField' + (formErrors.email ? ' form-error' : '')} 
                          required />
                        {formErrors.email && <span className="text-danger"> {formErrors.email}</span>}
                      </div>
                      <div className="col-md-6 text-left">
                        <label>Telefono Mobile</label>
                        <Field 
                          parse={ (value,name) => parsePhoneNumber(value) }
                          component="input" 
                          name="phone" 
                          type="text" 
                          placeholder="0123456789" 
                          className="mainField" />
                      </div>
                    </div>

                    <div hidden={ spidProfile != null } className="row">
                      <div className="col-md-6 text-left">
                        <label>Password</label>
                        <Field component="input" name="password" type="password" className="mainField" disabled={ spidProfile != null } required />
                      </div>
                      <div className="col-md-6 text-left">
                        <label>Ripeti Password</label>
                        <Field component="input" name="repeatedPassword" type="password" className="mainField" disabled={ spidProfile != null } required />
                      </div>
                    </div>
                    
                    <div className="row">
                      {formErrors.passwords && <span className="text-danger">{formErrors.passwords}</span>}
                    </div>

                    <div className="row">
                      <div className="col-md-6 text-left">
                        <label>Codice Fiscale</label>
                        <Field 
                          parse={ (value,name) => parseFiscalCode(value) }
                          component="input" 
                          name="fiscalCode" 
                          type="text" 
                          className="mainField"  
                          disabled={ spidProfile != null } 
                          required />
                      </div>
                    </div>

                    <Field component="input" name="spidProfile" type="text" disabled hidden/>
                  </div>

                </fieldset>
                
                {/* Inizio Informazioni Residente */}
                {category.resident && 
                <fieldset>
                  <div className="registration-title text-left">
                    INFORMAZIONI RESIDENTE
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-md-3 text-left">
                        <label>Data di Nascita</label>
                        <Field
                          placeholderText="GG/MM/AAAA"
                          component={DatePickerFormComponent}
                          name="birthDate"
                          className="mainField"
                          required />
                      </div>
                      <div className="col-md-3 text-left">
                        <label>Città di Nascita</label>
                        <Field
                          name="birthPlace"
                          options={comuni}
                          component={VirtualizedSelectFormComponent}
                          required />
                      </div>
                      <div className="col-md-3 text-left">
                        <label>Sesso</label><br />
                        <label className="sex">
                          <Field name="sex" component="input" type="radio" value="M" required /> {' '} Uomo
                        </label>
                        {' '}
                        <label>
                          <Field name="sex" component="input" type="radio" value="F" required /> {' '} Donna
                        </label>
                      </div>                      
                    </div>
                    <div className="row" />
                    {category.domiciled && 
                    <div className="row">
                      <div className="col-md-12 text-left">
                        <label>Sei residente o domiciliato a {process.env.REACT_APP_CITYL}?</label><br />
                        <label className="sex">
                          <input
                            type="radio"
                            value="resident"
                            name="isResidentOrDomiciled"
                            checked={isResidentOrDomiciled === RESIDENT_TYPES.resident}
                            onChange={() => this.setState({isResidentOrDomiciled: RESIDENT_TYPES.resident})}
                            required
                          />
                          {' '} Residente
                        </label>
                        <label style={{marginLeft: '20px'}}>
                          <input
                            type="radio"
                            value="domiciled"
                            name="isResidentOrDomiciled"
                            checked={isResidentOrDomiciled === RESIDENT_TYPES.domiciled}
                            onChange={() => this.setState({isResidentOrDomiciled: RESIDENT_TYPES.domiciled})}
                            required
                          />
                          {' '} Domiciliato
                        </label>
                      </div>
                    </div>
                    }
                    <div className="row">
                      <div className="col-md-6 text-left">
                        <label>
                          Indirizzo di
                          {isResidentOrDomiciled === RESIDENT_TYPES.resident
                            ? ' Residenza'
                            : ' Domicilio'
                          }
                        </label>
                        <Field
                          onChange={(event, data) => this.onResidentAddressSelected(data)}
                          name="residenceAddress"
                          options={addresses.data.map(item => {
                            return {
                              label: item.name,
                              value: item.id
                            }
                          })} component={VirtualizedSelectFormComponent} required />
                      </div>

                      <div className="col-md-2 text-left">
                        <label>N°</label>
                        <Field
                          parse={ (value,name) => parseCivicNumber(value) }
                          component="input"
                          name="residenceCivicNumber"
                          type="text" 
                          placeholder="N°"
                          className="mainField"
                          required />
                      </div>  
                      
                      <div className="col-md-2 text-left">
                        <label>Interno</label>
                        <Field
                          parse={ (value,name) => parseCivicNumber(value) }
                          component="input"
                          name="residenceInside"
                          type="text" 
                          placeholder="Interno"
                          className="mainField"
                          required />
                      </div>

                      {selectedResidenceArea && <div className="col-md-2 text-left">
                        <label>Area</label>
                        <div style={{marginTop: 12}}>
                          <h4>{selectedResidenceArea.name}</h4>
                        </div>
                      </div>}
                    </div>

                    {isResidentOrDomiciled === RESIDENT_TYPES.domiciled && (
                      <div className="row">
                        <div className="col-md-6 text-left">
                          <label>Indirizzo di Residenza</label>
                          <Field
                            component="input"
                            name="secondaryResidenceAddress"
                            type="text"
                            placeholder="Indirizzo di residenza"
                            className="mainField"
                            required
                          />
                        </div>
                        <div className="col-md-2 text-left">
                          <label>N°</label>
                          <Field                        
                            parse={ (value,name) => parseCivicNumber(value) }
                            component="input"
                            name="secondaryResidenceCivicNumber"
                            type="text" 
                            placeholder="N°"
                            className="mainField"
                            required />
                        </div>
                        <div className="col-md-2 text-left">
                          <label>Interno</label>
                          <Field
                            parse={ (value,name) => parseCivicNumber(value) }
                            component="input"
                            name="secondaryResidenceInside"
                            type="text" 
                            placeholder="Interno"
                            className="mainField"
                            required />
                        </div>  
                        <div className="col-md-3 text-left">
                          <label>Città</label>
                          <Field
                            name="secondaryResidenceCity"
                            options={comuniWithoutCurrentCity}
                            component={VirtualizedSelectFormComponent}
                            required />
                        </div>
                        <div className="col-md-2 text-left">
                          <label>CAP</label>
                          <Field
                            parse={ (value,name) => parseCap(value) }
                            component="input"
                            name="secondaryResidenceCap"
                            type="text"
                            placeholder="CAP"
                            className="mainField"
                            required
                          />
                        </div>
                      </div>
                    )}

                    {category.parkingInDifferentPlace && (
                      <div className="row">
                        <div className="col-md-7 text-left">
                          <label>
                            Indirizzo del posto auto
                          </label>
                          <Field
                            onChange={(event, data) => this.onParkingSpaceAddressSelected(data)}
                            name="parkingSpaceAddress"
                            options={addresses.data.filter(item => item.Area.code.indexOf("ZTL") !== -1).map(item => {
                              console.log(item)
                              return {
                                label: item.name,
                                value: item.id
                              }
                            })} component={VirtualizedSelectFormComponent} required />
                        </div>

                        {parkingSpaceSelectedArea && <div className="col-md-3 text-left">
                          <label>Area Del Posto Auto</label>
                          <div style={{marginTop: 12}}>
                            <h4>{parkingSpaceSelectedArea.name}</h4>
                          </div>
                        </div>}
                      </div>
                    )}
                  </div>
                </fieldset>}

                { !category.resident && residenceDataForAllUsers &&
                <fieldset>
                  <div className="registration-title text-left">
                  INFORMAZIONI DI RESIDENZA
                  </div>
                  <div className="row">
                    <div className="col-md-6 text-left">
                      <label>
                        Indirizzo di Residenza                                                  
                      </label>
                      <Field                        
                        component="input"
                        name="residenceAddress"
                        type="text"
                        placeholder="Indirizzo di residenza"
                        className="mainField"
                        required 
                        />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>N°</label>
                      <Field
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="residenceCivicNumber"
                        type="text"
                        placeholder="N°"
                        className="mainField"
                        required />
                    </div> 
                    <div className="col-md-2 text-left">
                      <label>Interno</label>
                      <Field
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="residenceInside"
                        type="text"
                        placeholder="Interno"
                        className="mainField"
                        required />
                    </div> 
                    <div className="col-md-6 text-left">
                      <label>Città</label>
                      <Field
                        name="residenceCity"
                        options={comuniWithoutCurrentCity}
                        component={VirtualizedSelectFormComponent}
                        required />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>CAP</label>
                      <Field
                        parse={ (value,name) => parseCap(value) }
                        component="input"
                        name="residenceCap"
                        type="text"
                        placeholder="CAP"
                        className="mainField"
                        required
                      />
                    </div>                                
                  </div>
                  
                </fieldset>
                }    
                {/* Inizio Informazioni Lavoratore */}

                {/* Inizio Informazioni Fiscali e Domicilio per Azienda RESELLER */}
                {(category.worker || category.name == "Reseller") && 
                <fieldset style={{ marginTop: "1em" }}>
                  <div className="registration-title text-left">
                    {category.label.toUpperCase()}
                  </div>
                  <div>

                    <div className="row">

                      <div className="col-md-6 text-left">
                        <label>Nome Azienda</label>
                        <Field
                          onChange={this.onCompanyNameChange}
                          component="input" 
                          name="companyName" 
                          type="text"
                          placeholder="Nome Azienda..."
                          className={'mainField' + (this.isCompanyInBlackList() ? ' has-error' : '')}
                          required />
                        {this.isCompanyInBlackList() && <span className="text-danger">
                          Errore! Questa azienda non fa parte del piano di sosta del Comune di {process.env.REACT_APP_CITYL}
                        </span>}
                      </div>

                      <div className="col-md-6 text-left">
                        <label>Partita IVA</label>
                        <Field
                          component="input"
                          name="vatNumber"
                          type="text"
                          placeholder="0123456789"
                          className="mainField"
                          required />
                      </div>

                    </div>
                    
                    <div className="row" style={{ marginTop: "1em" , marginBottom:"0.5em"}}>
                      <div className="registration-title-2 col-md-12 text-left">
                      SEDE LEGALE
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3 text-left">
                        <label>Città</label>                        
                        <Field
                          onChange={(event, data) => this.onCompanyLegalCitySelected(data)}
                          name="companyLegalCity"
                          options={comuni}
                          component={VirtualizedSelectFormComponent}
                          required
                        />
                      </div>       

                      <div className="col-md-5 text-left">
                        <label>Indirizzo</label>
                        { !this.isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity)  && 
                        <Field
                          onChange={(event) => this.onCompanyLegalAddressChange(event)}
                          component="input"
                          name="companyLegalAddress"
                          type="text"
                          placeholder="Indirizzo Sede Legale"
                          className="mainField"
                          required
                        />
                        }

                      { this.isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity)  && 
                      <Field                        
                        name="companyLegalAddressId" 
                        options={
                        addresses.data. map(item => {
                            return { label: item.name, value: item.id }
                          }
                        )} 
                        component={VirtualizedSelectFormComponent} 
                        required 
                      />
                      }
                      </div>

                      <div className="col-md-2 text-left">
                        <label>N°</label>
                        <Field
                          parse={ (value,name) => parseCivicNumber(value) }
                          component="input"
                          name="companyLegalCivicNumber"
                          type="text"
                          placeholder="N°"
                          className="mainField"
                          required />
                      </div>

                      <div className="col-md-2 text-left">
                        <label>Interno</label>
                        <Field
                          parse={ (value,name) => parseCivicNumber(value) }
                          component="input"
                          name="companyLegalInside"
                          type="text"
                          placeholder="Interno"
                          className="mainField"
                          required />
                      </div>

                      <div className="col-md-2 text-left">
                        <label>CAP</label>
                        <Field
                          component="input"
                          name="companyLegalCap"
                          disabled={this.isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity)}
                          type="text"
                          placeholder="CAP"
                          className="mainField"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>}
                {/* Fine Informazioni Fiscali e Domicilio per Azienda RESELLER */}

                {/* Inizio Informazioni Lavoratore */}
                {category.workerPerson && 
                <fieldset>

                  <div className="registration-title text-left">
                    {category.label.toUpperCase()}
                  </div>

                  <div>

                    <div className="row">
                      <div className="col-md-6 text-left">
                        <label>Nome Azienda</label>
                        <Field
                            onChange={this.onCompanyNameChange}
                            component="input" name="companyName" type="text"
                            placeholder="Nome Azienda..."
                            className={'mainField' + (this.isCompanyInBlackList() ? ' has-error' : '')}
                            required />
                        {this.isCompanyInBlackList() && <span className="text-danger">
                          Errore! Questa azienda non fa parte del piano di sosta del Comune di {process.env.REACT_APP_CITYL}
                        </span>}
                      </div>

                      <div className="col-md-6 text-left">
                        <label>Partita IVA</label>
                        <Field
                          parse={ (value,name) => parseVatNumber(value) }
                          component="input"
                          name="vatNumber"
                          type="text"
                          placeholder="0123456789"
                          className="mainField"
                          required />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 text-left">
                        <label>Indirizzo di Lavoro</label>
                        <Field
                          onChange={(event, data) => this.onWorkAddressSelected(data)}
                          name="workAddressId" 
                          options={
                          addresses.data.map(item => {
                              return { label: item.name, value: item.id }
                            }
                          )} component={VirtualizedSelectFormComponent} required />
                      </div>

                      <div className="col-md-2 text-left">
                        <label>N°</label>
                        <Field
                          parse={ (value,name) => parseCivicNumber(value) }
                          component="input"
                          name="workCivicNumber"
                          type="text"
                          placeholder="N°"
                          className="mainField"
                        />
                      </div>

                      <div className="col-md-2 text-left">
                        <label>Interno</label>
                        <Field
                          parse={ (value,name) => parseCivicNumber(value) }
                          component="input"
                          name="workInside"
                          type="text"
                          placeholder="Interno"
                          className="mainField"
                        />
                      </div>

                      {selectedWorkArea && <div className="col-md-3 text-left">
                        <label>Area Selezionata</label>
                        <div style={{marginTop: 12}}>
                          <h4>{selectedWorkArea.name}</h4>
                        </div>
                      </div>}

                    </div>

                  </div>

                </fieldset>}

                {/* sezione privacy */}
                <div className="checkbox" style={{textAlign:'left'}}>
                  <label>
                    <Field component="input" type="checkbox" name={"privacy"} required />
                    Dichiaro di aver preso visione e di accettare l'informativa sulla privacy:{' '}
                    <NavLink to={'/privacy'}>Clicca qui per l'informativa completa</NavLink>
                  </label>
                </div>

                {loading && <div className="well">Registrazione in Corso...</div> }

                {/* sezione messaggi d'errore */}
                <div className="row">
                  <div className="col-md-4 col-md-offset-4" style={{marginTop: 10}}>
                    {/* <Field name='captcharesponse' component={Captcha}/> */}
                    {formErrors.form && <span className="text-danger">{formErrors.form}</span>}
                  </div>
                </div>
                <button disabled={this.isCompanyInBlackList()} type="submit" className="alertBtn">
                  REGISTRATI
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div style={{display: "none"}}>
      <RegistrationCompleted category={category} {...this.props} />
      </div>
    </div>
    
    )


  }

}

// ----------------------------------------------------------------
//
//
RegistrationUserInfo.propTypes = {  
  history:        PropTypes.object,
  areas:          PropTypes.object,
  areaActions:    PropTypes.object,
  addresses:      PropTypes.object,
  addressActions: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({form: 'registrationUserInfo', enableReinitialize: true })(RegistrationUserInfo)
)
