import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import { bindActionCreators } from 'redux'

import {actions as authActions} from '../redux/modules/Auth'
import BackOfficeUserDetailContainer from './BackOfficeUserDetailContainer'
import BackOfficeUserTableContainer from './BackOfficeUserTableContainer'
import BackOfficeUserCreateContainer from './BackOfficeUserCreateContainer'
import BackOfficeUserEditContainer from './BackOfficeUserEditContainer'
import BackOfficeUserWaitingListTableContainer from './BackOfficeUserWaitingListTableContainer'
import BackOfficeUserDocumentsContainer from './BackOfficeUserDocumentsContainer'

const mapStateToProps = (state) => {
  return {
    state: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  }
}

class BackOfficeContainer extends Component {
  constructor (props) {
    super(props)    
  }

  render () {
    return (
      <Switch>
        <Route path="/backoffice/users/create"        component={BackOfficeUserCreateContainer} />
        <Route path="/backoffice/users/waiting-list"  component={(props) => <BackOfficeUserWaitingListTableContainer {...props} />} />
        <Route path="/backoffice/users/:id/edit"      component={BackOfficeUserEditContainer} />
        <Route path="/backoffice/users/:id/documents" component={BackOfficeUserDocumentsContainer} />
        <Route path="/backoffice/users/:id"           component={BackOfficeUserDetailContainer} />        
        <Route path="/backoffice/users"               component={(props) => <BackOfficeUserTableContainer {...props} />} />
      </Switch>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeContainer)
