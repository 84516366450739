import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";//*
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';//
import { getSubMessageType, getSubMessageTypeList, MESSAGE_TYPES } from 'libs/messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import VirtualizedSelectFormComponent from 'components/redux-form/VirtualizedSelectFormComponent';
const thisRoute = 'reseller'

class ResellerNewMessageModal extends Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,    
    onSendMessage: PropTypes.func.isRequired,    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      subject: null,
      userId: null,      
      content: null,
      checkErrors: null,
      sendErrors: null,
      users: [],
      loading: true
    }

    this.sendMessage = this.sendMessage.bind(this)
    this.checkFields = this.checkFields.bind(this)
    this.close       = this.close.bind(this)
   
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {    
    if ( prevProps.show != this.props.show ) {
    
      this.setState({      
        subject: null,
        userId: null,      
        content: null,
        checkErrors: null,
        sendErrors: null      
      })

      this.props.initialize({ userId: null });

      if ( this.props.show ) {
        this.fetchUsers()
      }

    }
  }

  /**
   * --------------------------------------------------------------
   */
  fetchUsers() {
    SostaOnlineApiService.fetchUsers(null,100000000,0,thisRoute)
    .then( data => {
      this.setState({
        users: data.data.map( user => { 
          return { 
            label : user.firstName + ' ' + user.lastName + ' ' + user.fiscalCode + ' ' + user.email ,
            value : user.id            
          } 
        }),        
        loading: false
      })
    })
    .catch( error => {
      console.log("ERROR",error)
    } )

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    this.fetchUsers()
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []

    if ( this.state.userId == null ) {
      errors.push("Selezinare l'utente a cui mandare il messaggio")
    }

    if ( this.state.subject == null ||  this.state.subject.length <= 0 ) {
      errors.push("Il soggetto del messaggio è obbligatorio")
    }

    if ( this.state.content == null || this.state.content.length <= 10 ) {
      errors.push("Il messaggio da inviare è vuoto")
    }

    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  sendMessage() {


    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }
    
    var msg = {
      subject: this.state.subject,
      content: this.state.content,    
      toId: this.state.userId  
    }

    SostaOnlineApiService.sendMessage(msg,thisRoute)
    .then( result => {

      if ( this.props.onSendMessage != null ) {
        this.props.onSendMessage(msg)
      }

      this.close()

    })
    .catch( error => {
      console.log("ERROR",error)
      this.setState({ sendErrors: "Errore durante l'invio del messagggio, riprovare pù tardi!" })
    })

    
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSelectedMessageType() {
    return getSubMessageType(this.state.messageType,this.state.subMessageType)    
  }

   /**
   * --------------------------------------------------------------
   */
  close() {    
    if ( this.props.onClose )
        this.props.onClose()    
  }
  

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show
    } = this.props

    const {
      sendErrors,
      checkErrors,
      userId,
      subject,
      content
    } = this.state

    var selectedSubMessageType = this.getSelectedMessageType()

    return (
      <Modal
        open={show}
        onClose={this.close}
        closeOnEsc={true}
        showCloseIcon={false}       
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        focusTrapped={true}
        center               
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faEnvelope} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>INVIA UN MESSAGGIO</strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4>        

        <div className="col-md-12" >
          <div className="app-modal-body">
            <div className="row">              
              <Field
                  name="userId"
                  options={this.state.users}
                  component={VirtualizedSelectFormComponent}
                  onChange={ value => { this.setState({ userId: value}) } }
                  placeholder="Seleziona utente a cui inviare il messaggio ..."                  
                  required />                             
                <br/>             
            </div>

            <div className="row">
                <input    
                  style={{ width : "100%"}}
                  name="subject"                  
                  component="input"
                  onChange={ e => { this.setState({ subject: e.target.value })  } }
                  placeholder="Soggetto del messaggio ..."
                  value={subject}
                  required />                             
                <br/>
            </div>  

            <div className="row">
                
                <h5 style={{ marginTop: 10, marginBottom: 10 }}><strong>Messaggio:</strong></h5 >
                
                <textarea 
                  style={{ 
                    height: "250px",                     
                    color: 'grey', 
                    fontWeight: 'bold'  
                  }}
                  name="content" 
                  className="form-control"                   
                  onChange={ e => { this.setState({content: e.target.value}) }}
                  value={content}
                  required />                              
                <br/>
            </div>         

            <div className="row">
              { sendErrors && 
                <h5 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {sendErrors}
                </h5>
              }
              { checkErrors && checkErrors.length > 0 && 
                <h5 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                </h5>
              }
            </div>

            <div className="row">
              <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                <Button className="btn alertBtn"   onClick={this.close}>Annulla</Button>{' '}
                <Button className="btn mainBtn" onClick={() => this.sendMessage()} >Invia</Button>
              </div>
            </div>
          </div>
        </div>
        
      </Modal>
    )
  }
}

export default reduxForm({ form: 'ResellerNewMessageModal'})(ResellerNewMessageModal)
