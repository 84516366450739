import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FileManagerContainer from './FileManager/FileManagerContainer'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'


export default class FileInputField extends Component {
  static propTypes = {
    input: PropTypes.object,
    documentTypeId: PropTypes.any,
    editMode: PropTypes.bool,
    openFileManager: PropTypes.bool,
    onClose: PropTypes.func
  }

  static defaultProps = {
    editMode: false
  }

  constructor (props) {
    super(props)
    this.state = {
      showFileManagerModal: false
    }

    this.onOpenFileManagerModal  = this.onOpenFileManagerModal.bind(this)
    this.onCloseFileManagerModal = this.onCloseFileManagerModal.bind(this)
    this.onDocumentSelected      = this.onDocumentSelected.bind(this)
  }

  componentDidMount() {    
    if ( this.props.openFileManager )
      this.onOpenFileManagerModal()
  }

  onOpenFileManagerModal () {
    const { editMode, input } = this.props
    const { value } = input
    if (editMode && value.approved) {
      return
    }

    this.setState({
      showFileManagerModal: true
    })
  }

  onCloseFileManagerModal () {
    if ( this.props.onClose != null ) {      
      this.props.onClose()
    }

    this.setState({
      showFileManagerModal: false
    })
  }

  onDocumentSelected (document) {    
    const { input } = this.props
    const { onChange } = input
    onChange(document)
    this.setState({
      showFileManagerModal: false
    })
  }

  render () {
    const { showFileManagerModal } = this.state
    const { input, documentTypeId, editMode } = this.props
    const { value } = input    

    return (
      <div style={{textAlign: 'left'}}>
        { editMode && value.approved ? (
            <span className="text-success" style={{fontWeight: 'bold'}}>Approvato</span>
          ) : (
            <div
              className="mainBtn"            
              onClick={this.onOpenFileManagerModal}>
              { value 
                ? <div>
                  <FontAwesomeIcon className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
                  &nbsp;&nbsp;{value.name}
                </div> 
                : <div>
                  <FontAwesomeIcon className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
                  &nbsp;&nbsp;Cambia documento
                </div>
              }
            </div>
          )
        }

        <Modal
          classNames={{modal: 'app-modal-container'}}
          open={showFileManagerModal}
          onClose={this.onCloseFileManagerModal}
          closeOnOverlayClick={false}
          closeOnEsc={true}
          showCloseIcon={false}         
          animationDuration={500}
          center  
        >
          <h4 className="app-modal-title">
              <div style={{ flex: 10, textAlign : 'left'}}>
                <FontAwesomeIcon className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faFolderOpen} />
              </div>          
              <div style={{ flex: 80, textAlign : 'center' }}><strong>GESTIONE DOCUMENTI</strong></div>
              <div style={{ flex: 10, textAlign : 'right'}}>
                <FontAwesomeIcon  onClick={ evt => this.onCloseFileManagerModal() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
              </div>          
          </h4>           
          <div className="app-modal-body">
            <h4>
              Carica i tuoi documenti in questa sezione. Poi selezionali, cliccandoci sopra per allegarli alla richiesta del permesso
            </h4>

            <FileManagerContainer
              documentTypeId={documentTypeId}
              onFileSelected={this.onDocumentSelected}
            />
          </div>
        </Modal>
      </div>
    )
  }
}
