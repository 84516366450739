import React, {Component}    from 'react'
import SostaOnlineApiService from "../../../services/SostaOnlineApiService";
import { Field, reduxForm } from 'redux-form';
import SplashBtn from '../../../components/SplashBtn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'

export const STATE_PASSWORD_CHANGED = "STATE_PASSWORD_CHANGED"
export const STATE_CONFIRM_OTP      = "CONFIRM_OTP"
export const STATE_OTP_CONFIRMED    = "OTP_CONFIRMED"
export const STATE_SEND_OTP         = "SEND_OTP"

class PasswordChange extends Component {
    constructor(props) {

        super(props);

        this.state = {
            user : this.props.user,
            otp : null,
            password: null,
            repeatedPassword: null,
            currentState: this.props.action,
            formError: null
        }
        
        this.onSubmit               = this.onSubmit.bind(this);
        this.onChangeOtp            = this.onChangeOtp.bind(this)
        this.onChangePassword       = this.onChangePassword.bind(this)
        this.onChangeRepeatPassword = this.onChangeRepeatPassword.bind(this)
    }


    async componentDidMount() {
        if ( this.state.currentState === STATE_SEND_OTP ) {
            SostaOnlineApiService.sendOtp()
                .then( result => {
                    this.setState({'currentState': STATE_CONFIRM_OTP})
                })
                .catch( error => {
                    this.setState({formError: 'Errore durante l\'invio OTP: ' +error.error})
                })
            
        }
        
    }

    onSubmit(e) {
        e.preventDefault()

        SostaOnlineApiService.changePasswordMe1(this.state.otp,this.state.password,this.state.repeatedPassword)
            .then( data => { 
                //console.log("DATA",data)
                this.setState({'currentState': STATE_PASSWORD_CHANGED})
                this.setState({formError: null})
            })
            .catch( err => {
                //console.log("ERROR",err)

                var msg = 'Parametri non corretti'

                if ( err.error == 'Key not found' )
                    msg = 'Il codice inserito non è corretto'

                else if ( err.error == 'Passwords does not match' )
                    msg = 'Le password inserite devono coincidere'

                else if ( err.error == 'Password too short. Minimum 8 characters' )
                    msg = 'La password deve essere lunga almeno 8 caratteri'
                
                else if ( err.error == 'Otp expired' )
                    msg = 'Codice OTP scaduto'

                this.setState({formError: msg})
            })

        //console.log("SUBMIT",e)
        
    }

    onChangeOtp(e) {
        //console.log(e.target.value)
        this.setState({'otp': e.target.value})
    }

    onChangePassword(e) {
        //console.log(e.target.value)
        this.setState({'password': e.target.value})
    }

    onChangeRepeatPassword(e) {
        //console.log(e.target.value)
        this.setState({'repeatedPassword': e.target.value})
    }
    
    render() {


        const {
            currentState,
            error,
            formError
        } = this.state

        //console.log(error);

        return(
            <form onSubmit={this.onSubmit}>
                <div>
                    <div className="col-md-6 col-md-offset-3 ">
                        <div className="panel panel-default panel-border">
                            <div className="panel-body">

                            {currentState === STATE_SEND_OTP && <div>
                                <div className="text-recovey-password">
                                    <h2>INVIO OTP</h2>
                                    <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faKey} />
                                    <br/><br/>
                                </div>
                                <p>{ formError }</p>
                            </div>}

                            {currentState === STATE_CONFIRM_OTP && <div>
                                <div className="text-recovey-password">
                                    <h2>Modifica password</h2>
                                    <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faKey} />
                                    <br/><br/>
                                </div>
                                <p>
                                    Controlla la tua Email, ti abbiamo appena inviato un codice OTP.<br/> 
                                    Inseriscilo nel campo sottostante e scegli la tua nuova password.
                                </p>
                                <Field required 
                                    component="input"  
                                    name="otp" 
                                    type="text" 
                                    className="inputLogin" 
                                    placeholder="Otp" 
                                    aria-describedby="basic-addon1"
                                    onChange={this.onChangeOtp} />
                                <Field required 
                                    component="input" 
                                    name="password"         
                                    type="password" 
                                    className="inputLogin" 
                                    placeholder="Nuova Password"  
                                    aria-describedby="basic-addon1" 
                                    onChange={this.onChangePassword} />
                                <Field required 
                                    component="input" 
                                    name="repeatedPassword" 
                                    type="password" 
                                    className="inputLogin" 
                                    placeholder="Ripeti password" 
                                    aria-describedby="basic-addon1" 
                                    onChange={this.onChangeRepeatPassword} />

                                {formError && <div><span style={{color: 'red'}}>{formError}</span></div>}
                                <p>{ }</p>
                                <SplashBtn> Invio </SplashBtn>
                            </div>}

                            {currentState === STATE_PASSWORD_CHANGED && <div>
                                <div className="testo">
                                    <h2>Password Modificata con Successo</h2>
                                    <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faKey} />
                                    <br/><br/>
                                </div>
                                <p>La tua password è stata modificata.</p>
                                <p>{ }</p>                                
                            </div>}
                        </div>
                    </div>
                    </div>
                </div>
            </form>
        )
        
    }
}


export default reduxForm({ form: 'passwordChange'})(PasswordChange)