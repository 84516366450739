import React, { Component } from 'react'
import PropTypes from 'prop-types'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SostaOnlineApiService from '../../../services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import RequestConfirmModal from 'components/General/RequestConfirmModal';//

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import {actions as authActions} from 'redux/modules/Auth'
import { RESELLER } from 'libs/roles'
import { Oval } from 'react-loader-spinner';


moment.locale('it')

/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    resellerValues: state.form.resellerSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

/**
 * --------------------------------------------------------------
 * @returns 
 */
export function getNullTimeSlot() {
  return {
    id: null,
    markTypeId: null,    
    categoryId: null,
    DateSlots: []
  }    
}


class ResellerEditTimeSlot extends Component {

  static propTypes = {    
    timeSlot:     PropTypes.object,    
    onSave:       PropTypes.func.isRequired,
    onDelete:     PropTypes.func.isRequired,
    onCancelEdit: PropTypes.func.isRequired,
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {
      timeSlot: this.props.timeSlot,
      checkErrors: null,
      sendErrors: null,
      displayColorPicker: false,
      showConfirmDelete: false,      
         
    }

    this.save            = this.save.bind(this)
    this.checkFields     = this.checkFields.bind(this)
    this.delete          = this.delete.bind(this)
       
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
      timeSlot: this.props.timeSlot,
      checkErrors: null,
      sendErrors: null,      
      displayColorPicker: false,
      showConfirmDelete: false
    })    

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    this.stateInit()
    this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
   componentDidUpdate(prevProps) {       

    if ( prevProps.timeSlot != this.props.timeSlot  ) {
      this.stateInit()
      this.fetchData()
    }
        
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchData() {
    
    

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []   

    if ( this.state.timeSlot.name == null || this.state.timeSlot.name.trim().length <= 0 ) {
      errors.push("Nome non specificato")
    }    
    
    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  save() {
    
    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }    

    if ( this.state.timeSlot != null && this.props.onSave != null ) {      
      this.props.onSave(this.state.timeSlot)
    }

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async delete() {    

    if ( this.props.timeSlot != null  ) {      
      
      if ( this.props.onDelete != null ) {
        this.props.onDelete(this.props.timeSlot)
      }

      this.setState({ showConfirmDelete: false})

    }

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  onCancelEdit() {
    this.stateInit()
    this.fetchData()

    if ( this.props.onCancelEdit != null )
      this.props.onCancelEdit()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} name 
   */
  setName(name) {
    var timeSlot = this.state.timeSlot
    timeSlot.name=name
    this.setState({timeSlot: timeSlot})
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {        
      timeSlot 
    } = this.props

    const {
      sendErrors,
      checkErrors,
      showConfirmDelete
    } = this.state

    if ( this.props.auth.user.role != RESELLER ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    return (
      <React.Fragment>
              
        { timeSlot  == null &&
        <div className="col-md-12">
          <div className="row">          
            <div className="text-center" style={{ margin: "1em" }}>
              <div className="row"><h2>Caricamento in corso...</h2></div>
              <div className="row" style={{padding: "2em", height: "40em"}}>
                <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
              </div>
            </div>
          </div>          
        </div>
        }

        { timeSlot != null &&
        <div className="col-md-12">
          <div className="app-modal-body">                                  
            
            <React.Fragment>

              { timeSlot.id != null &&
              <div className="row" style={{ marginBottom: "1em" }}>
                  <div className="col-xs-3">
                    <h5 style={{ marginTop: 5 , marginBottom: 5, verticalAlign: "top" }}><strong>Id:</strong></h5>
                  </div>
                  
                  <div className="col-xs-9">
                    <h5 style={{ marginTop: 5 , marginBottom: 5, marginLeft: 15, verticalAlign: "top" }}><strong>{timeSlot.id}</strong></h5>
                  </div>                                
              </div>
              }
              
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-3">
                  <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Nome:</strong></h5>
                </div>
                
                <div className="col-xs-9">
                  <input
                    name="name" 
                    className="form-control" 
                    type="text" 
                    onChange={ e => {  this.setName(e.target.value) }}
                    value={timeSlot.name}
                    required />
                </div>                                
              </div>

              <div className="row" style={{ marginBottom: "1em" }}>
                { sendErrors && 
                  <h4 className="col-xs-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {sendErrors}
                  </h4>
                }
                { checkErrors && checkErrors.length > 0 && 
                  <h4 className="col-xs-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                  </h4>
                }
              </div>              
              
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  <Button className="btn alertBtn" onClick={() => { this.onCancelEdit() } }>Annulla</Button>{' '}
                  <Button className="btn mainBtn"  onClick={() => { this.save() }} >Conferma</Button>
                  { timeSlot &&
                  <Button className="btn alertBtn" onClick={() => { this.setState({ showConfirmDelete: true })} }><FontAwesomeIcon  size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina</Button>
                  }
                </div>
              </div>          

            </React.Fragment>            
          </div>
        </div>           
        }  

        <RequestConfirmModal
          requestStyle={2}
          requestTitle="Conferma Eliminazione Slot Temporale"
          requestSubTitle="ATTENZIONE"
          requestMsg={"Proseguendo con l'operazione si eliminerà lo slot temporale.<br/><br/>Si vuole procedere?"}
          show={showConfirmDelete}
          onConfirm={this.delete}
          onRefuse={ () => {this.setState({ showConfirmDelete: false }) }}
        />

       
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'ResellerEditTimeSlot'})(ResellerEditTimeSlot))
