import React, {Component}       from 'react'
import {connect}                from 'react-redux'
import BackOfficeReceiptScreen  from '../screens/BackOfficeReceiptScreen'
import SostaOnlineApiService    from '../services/SostaOnlineApiService'
import {actions as authActions} from '../redux/modules/Auth'
import { bindActionCreators }   from 'redux'
import { reportActions }        from 'redux/modules/Report' 

const mapStateToProps = (state) => {
  return {
    state: state.users,
    stateReport : state.report   
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  };
};

class BackOfficeReceiptsTableContainer extends Component {
  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      receipts: [],
      filters: {},
      loading: false
    }

    this.fetchReceipts = this.fetchReceipts.bind(this)
    this.onRowClick = this.onRowClick.bind(this)
  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount () {
    this.setState({loading: true})
    this.fetchReceipts()
    this.fetchThingsToDo()
  }

  /**
   * --------------------------------------------------------------
   */
  fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo().then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} filterObj 
   * @param {*} limit 
   * @param {*} offset 
   */
  fetchReceipts (filterObj, limit, offset) {
    this.setState({loading: true})
    let string = ''
    for (let key in filterObj) {
      string = string + key + '=' + filterObj[key] + '&'
    }

    SostaOnlineApiService.fetchReceipts(string, limit || 100, offset || 0).then((data) => {
      this.setState({
        receipts: data.data,
        pagination: data.pagination,
        loading: false
      })
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} rowIdx 
   * @param {*} row 
   * @returns 
   */
  onRowClick (rowIdx, row) {
    if (rowIdx <= -1 || !row.Order.userId) {
      return
    }

    let scrollTo = ''
    if (row.Order.MarkOrders.length > 0) {
      scrollTo = '?scrollTo=mark-' + row.Order.MarkOrders[0].markId
    }
    this.props.history.push('/backoffice/users/' + row.Order.userId + scrollTo)
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {
    return (
      <BackOfficeReceiptScreen
        loading={this.state.loading}
        fetchReceipts={this.fetchReceipts}
        receipts={this.state.receipts}
        rows={this.state.receipts}
        pagination={this.state.pagination}
        onRowClick={this.onRowClick} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeReceiptsTableContainer);