import React, { Component } from 'react'
import { connect } from 'react-redux'
import ResellerNavigatorScreen from '../screens/Reseller/ResellerNavigatorScreen'//
import { Route, Switch, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { actions as authActions } from '../redux/modules/Auth'
import { reportActions } from 'redux/modules/Report'
import SostaOnlineApiService from '../services/SostaOnlineApiService'//
import ResellerMarksTableContainer from './Reseller/ResellerMarksTableContainer'//
import ResellerReceiptsTableContainer from './Reseller/ResellerReceiptsTableContainer'//
import ResellerUsersContainer from './Reseller/ResellerUsersContainer'//
import ResellerUserContainer from './Reseller/ResellerUserContainer'
import ResellerReports from './Reseller/Reports/ResellerReports'//
import { ADMIN, OPERATOR, RESELLER, USER } from 'libs/roles'
import ResellerMessages from './Reseller/Messages/ResellerMessages'//
import ResellerVehiclesContainer from './Reseller/ResellerVehiclesContainer'//
import ResellerSettingsContainer from './Reseller/ResellerSettingsContainer'//
import PasswordChange, { STATE_SEND_OTP } from '../containers/Reseller/Password/PasswordChange'//
import history from '../history'//
import ResellerNews from './Reseller/News/ResellerNews'//
import ResellerOperatorProfile from '../screens/Reseller/ResellerOperatorProfile'//
const thisRoute = 'reseller'
import ResellerHomeContainer from './Reseller/HomeArea/ResellerHomeContainer'
import ResellerUserCreateScreen from 'screens/Reseller/ResellerUserCreateScreen'

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class ResellerMainContainer extends Component {

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {

    super(props)

    this.state = {
      settings: {
        messages: {
          enable: false
        }
      }
    }

    this.fetchThingsToDo = this.fetchThingsToDo.bind(this)
    this.onLogoutClick = this.onLogoutClick.bind(this)
    this.onPasswordChangeClick = this.onPasswordChangeClick.bind(this)

  
    this.tasks = [
      {
        id: '1',
        name: 'fetchThingsToDo',
        fn: this.fetchThingsToDo,        
        //config: '0,5,10,15,20,25,30,35,40,45,50,55 * * * *'
        config: '* * * * *'
      }    
    ]    
  

    // console.log('thisprops:', this.props) // {history: Object, location: Object, match: Object, staticContext: undefined, stateAuth: Object, …}ObjectauthActions: {loginRequest: function, spidLogin: function, logout: function, getSpidToken: function, setSpidEnable: function, …}Objecthistory: {length: 8, action: "REPLACE", location: Object, createHref: function, push: function, …}Objectlocation: {pathname: "/reseller", search: "", hash: "", state: undefined, key: "4660ae"}Objectmatch: {path: "/reseller", url: "/reseller", isExact: true, params: {}}ObjectreportActions: {fetchThingsToDo: function}ObjectstateAuth: {token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3M…5NTl9.t-jnWQ1yXWZNzL0-v8KkCHeLfWr2Xwiqci86JgL8U2Y", user: Object, loading: false, error: false, spidToken: null, …}ObjectstaticContext: undefinedPrototipo Object
  }


  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    //console.log('ResellerMainContainer componentDidMount');

    //console.log('thisprops:', this.props)
    this.fetchSettings()
    this.fetchThingsToDo()
  }

  /**
   * --------------------------------------------------------------
   */
  fetchSettings() {

    var settings = this.state.settings

    SostaOnlineApiService.fetchMessagesSettings()
      .then(data => {
        settings.messages = data.data
        this.setState({ settings: settings })
      })
      .catch(error => {
        console.log("ERROR", error)
      })
  }


  /**
   * --------------------------------------------------------------
   */
  fetchThingsToDo() {
    SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {
      this.props.reportActions.fetchThingsToDo(data.data)
      console.log ("src/containers/ResellerMainContainer.js:fetchThingsToDo: data", data.data)
    })
      .catch(error => {
        console.log("ERROR", error)
      })
  }

  /**
   * --------------------------------------------------------------
   */
  onLogoutClick() {
    this.props.authActions.logout()
  }

  /**
   * --------------------------------------------------------------
   */
  onPasswordChangeClick() {
    history.push("/reseller/password-change")
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkRedirectTo() {
    if (this.props.stateAuth == null || this.props.stateAuth.user == null) {
      return (<Redirect to="/splash-reseller" />)
    }

    if (this.props.stateAuth.user.role !== RESELLER) {
      if (this.props.stateAuth.user.role == ADMIN || this.props.stateAuth.user.role == OPERATOR) {
        return (<Redirect to="/backoffice" />)
      } else {
        return (<Redirect to="/user-area" />)
      }
    /*} else if(this.props.stateAuth.user.budget === null) {
        return (<Redirect to="/user-area" />)*/
    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {

    var redirect = this.checkRedirectTo()

    if (redirect != null)
      return (redirect)
    //console.log('ResellerMainContainer Props:', this.props);
    //console.log('ResellerMainContainer this.props.stateAuth.user.budget:', this.props.stateAuth.user.budget)

    return (


      <React.Fragment>
        <ResellerNavigatorScreen
          //onMarksWaitingClick={() => this.props.history.push('/reseller/users/waiting-list')}
          onNewMessagesClick={() => this.props.history.push('/reseller/messages')}
          onVehiclesWaitingClick={() => this.props.history.push('/reseller/vehicles/waiting-list')}
          user={this.props.stateAuth.user}
          settingsMessages={this.state.settings.messages}
          onLogoutClick={this.onLogoutClick}
          onPasswordChangeClick={this.onPasswordChangeClick}
          stateAuth={this.props.stateAuth}
          reportActions={this.props.reportActions}
        >
          <Switch>
            <Route path="/reseller/password-change" component={(props) => <PasswordChange user={this.props.stateAuth.user} action={STATE_SEND_OTP} />} />
            {/*<Route path="/reseller/marks" component={ResellerMarksTableContainer} />*/}
            <Route path="/reseller/home" component={ResellerHomeContainer} />
            <Route path="/reseller/users" component={(props) => <ResellerUsersContainer user={this.props.stateAuth.user} />} /> 
            <Route path="/reseller/users/create" component={ResellerUserCreateScreen} />
            <Route path="/reseller/user" component={ResellerUserContainer} />
            {/*<Route path="/reseller/vehicles" component={ResellerVehiclesContainer} />*/}
            {/*<Route path="/reseller/receipts" component={ResellerReceiptsTableContainer} />*/}
            {/*<Route path="/reseller/settings" component={ResellerSettingsContainer} />*/}
            {/*<Route path="/reseller/reports" component={ (props) => <ResellerReports reseller={this.props.stateAuth.user} />} />*/}
            {/*<Route path="/reseller/news" component={ResellerNews} />*/}
            {/*<Route path="/reseller/operator-profile" component={ResellerOperatorProfile} />*/}
            {<Route path="/reseller/messages" component={
              (props) => <ResellerMessages settingsMessages={this.state.settings.messages} {...props} />
            } />}

            <Redirect to="/reseller/home" />

          </Switch>
        </ResellerNavigatorScreen>
      </React.Fragment>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerMainContainer)
