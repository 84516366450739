import React, {Component} from 'react'
import {reduxForm, Field} from 'redux-form'
import SostaOnlineApiService from '../services/SostaOnlineApiService'
import { Oval } from 'react-loader-spinner'
import { getValidPlateNumberContent } from 'libs/vehicles'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser';

class BackOfficeUserDetailVehicleCreateForm extends Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    onVehicleSubmit: PropTypes.func.isRequired,
    onHideCreateVehicleClick: PropTypes.func.isRequired,        
  }

  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      errors: false,     
      vehicleLoading: false, 
      vehicleError: null, 
      vehicleSuccess: false,
      ownershipTypes: [],
      powerSupplies: [],
      ownershipType: null,
      powerSupply: null,
      plateNumber: null,
    }

    this.onVehicleSubmit = this.onVehicleSubmit.bind(this)
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  async componentDidMount () {
    try {
      this.setState({loading: true})
      let promises = [
        SostaOnlineApiService.fetchVehicleOwnershipTypes(),
        SostaOnlineApiService.fetchVehiclePowerSupplies()
      ]

      const result = await Promise.all(promises)

      if (result.filter(item => item.result !== 'ok').length > 0) {
        this.setState({errors: true, loading: false})
        return
      }

      this.setState({
        ownershipTypes: result[0].data,
        powerSupplies: result[1].data,
        ownershipType: null,
        powerSupply: null,
        plateNumber: null,
        loading: false
      })
    } 
    catch (e) {
      this.setState({loading: false, errors: true})
    }
  }

   /**
   * --------------------------------------------------------------   
   */
  onVehicleSubmit() {

    this.setState({ vehicleLoading: true, vehicleError: null, vehicleSuccess: false })

    let error = null    

    let obj = {
      userId:      this.props.user.id,
      plateNumber: this.state.plateNumber,
      type:        this.state.ownershipType,
      powerSupply: this.state.powerSupply
    }

    if ( !obj.plateNumber || obj.plateNumber.length <= 0 ) {
      let errorMsg = 'Targa veicolo obbligatoria'

      error = error == null ?  errorMsg : error+'<br/>'+errorMsg
    }

    if ( !obj.type || obj.type.length <= 0 ) {
      let errorMsg = 'Tipo proprità obbligatoria'

      error = error == null ?  errorMsg : error+'<br/>'+errorMsg
    }

    if ( !obj.powerSupply || obj.powerSupply.length <= 0 ) {
      let errorMsg = 'Alimentazione veicolo obbligatoria'

      error = error == null ?  errorMsg : error+'<br/>'+errorMsg
    }

    if ( error != null ) {
      this.setState({ vehicleLoading: false, vehicleError: error, vehicleSuccess: false })
    }        
    else {
      SostaOnlineApiService.createVehicle(obj)
        .then( result => {

          console.log("RESULT",result)

          this.setState({ vehicleLoading: false, vehicleError: null, vehicleSuccess: true })

          if ( this.props.onVehicleSubmit )
            this.props.onVehicleSubmit()

          this.props.onHideCreateVehicleClick()

        })
        .catch(err => {

          let error = 'Errore nella creazione del veicolo'

          if (err.error === 'Plate already exists') {
            error = 'Errore! Targa già esistente!'
          }

          this.setState({ vehicleLoading: false, vehicleError: error, vehicleSuccess: false })

        })
    }
  }


  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {
    const {      
      onHideCreateVehicleClick
    } = this.props

    const {
      vehicleLoading,
      vehicleError,
      vehicleSuccess
    } = this.state



    if (this.state.loading) {
      return (
        <div className="static-modal col-md-12">
          <div tabIndex="-1" role="dialog" className="modal my-modal1" style={{ display: 'block' }}>
            <div className="modal-dialog" style={{ marginTop: '0' }}>
              <div className="modal-content my-modal2" role="document">
                <div className="modal-header">
                  <div className="row justify-content-between">
                    <div className="col-md-3"><strong>Crea veicolo:</strong></div>
                    <div className="col-md-offset-11 text-center">                          
                        <a className="glyphicon glyphicon-remove chiudi-btn" onClick={onHideCreateVehicleClick}>{}</a>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="text-center"></div>
                  <div className="box">
                    <div className="row"><h3>Caricamento in corso...</h3></div>
                    <div className="row" style={{padding: "2em", height: "10em"}}>
                      <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>          
      )
    }

    if (this.state.errors) {
      return (
        <div className="static-modal col-md-12">
          <div tabIndex="-1" role="dialog" className="modal my-modal1" style={{ display: 'block' }}>
            <div className="modal-dialog" style={{ marginTop: '0' }}>
              <div className="modal-content my-modal2" role="document">
                <div className="modal-header">
                  <div className="row justify-content-between">
                    <div className="col-md-3"><strong>Crea veicolo:</strong></div>
                    <div className="col-md-offset-11 text-center">                          
                        <a className="glyphicon glyphicon-remove chiudi-btn" onClick={onHideCreateVehicleClick}>{}</a>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="text-center"></div>
                  <div className="box text-danger">
                    Errore nel caricamento dei dati...
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>    
      )
    }

    return (
      <div className="static-modal col-md-12">
        <div tabIndex="-1" role="dialog" className="modal my-modal1" style={{ display: 'block' }}>
          <div className="modal-dialog" style={{ marginTop: '0' }}>
            <div className="modal-content my-modal2" role="document">
              <div className="modal-header">
                <div className="row justify-content-between">
                  <div className="col-md-3"><strong>Crea veicolo:</strong></div>
                  <div className="col-md-offset-11 text-center">                          
                      <a className="glyphicon glyphicon-remove chiudi-btn" onClick={onHideCreateVehicleClick}>{}</a>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="text-center"></div>
                  
                <div className="box">
                  <div className="row form-group">
                    <div className="col-md-6 text-left">
                      <label>Seleziona tipo Proprietà</label>
                      <Field 
                        name="ownershipType" 
                        className="form-control" 
                        component="select" 
                        required
                        onChange={ e => { this.setState({ ownershipType: e.target.value}) } }
                        >
                        <option value="">Seleziona tipo di proprietà...</option>
                        {this.state.ownershipTypes.map((item, index) => {
                          return (<option value={item.id} key={item.id + '-' + index}>
                            {item.name}
                          </option>)
                        })}
                      </Field>
                    </div>

                    <div className="col-md-6 text-left">
                      <label>Seleziona Alimentazione Veicolo</label>
                      <Field 
                        name="powerSupply" 
                        className="form-control" 
                        component="select" 
                        required 
                        onChange={ e => { this.setState({ powerSupply: e.target.value }) } }
                        >
                        <option value="">Seleziona alimentazione veicolo...</option>
                        {this.state.powerSupplies.map((item, index) => {
                          return (<option value={item.id} key={item.id + '-' + index}>
                            {item.name}
                          </option>)
                        })}
                      </Field>
                    </div>

                    <div className="col-md-6 text-left">
                      <label>Targa</label>
                      <Field 
                        component="input" 
                        name="plateNumber" 
                        type="text" 
                        className="mainField" 
                        normalize={ value => getValidPlateNumberContent(value) }
                        onChange={ e => { this.setState({ plateNumber:  getValidPlateNumberContent(e.target.value )}) } }
                        required/>
                    </div>
                  </div>
                  {vehicleLoading && <div className="well">Creazione Veicolo in Corso...</div> }

                  {vehicleError && 
                    <div>
                      <div className="row">
                        <div className="col-md-8 col-md-offset-2 ">
                          <h4 className="text-danger">
                            {Parser(vehicleError)}
                          </h4>
                        </div>
                      </div>
                    </div>                                        
                  }

                  {vehicleSuccess && 
                    <div>
                      <div className="row">
                        <div className="col-md-8 col-md-offset-2 ">
                          <div className="contrassegno">
                            <h4 className="contrassegno-h">Veicolo creato correttamente!</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <p>
                    <button type="submit" className="btn mainBtn" onClick={ evt => { this.onVehicleSubmit() } }>
                      Crea Veicolo
                    </button>
                  </p>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>      
    );
  }
}

export default reduxForm({
  form: 'BackOfficeUserDetailVehicleCreateForm'
})(BackOfficeUserDetailVehicleCreateForm)