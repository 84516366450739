import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import { bindActionCreators } from 'redux'

import {actions as authActions} from '../redux/modules/Auth'
import BackOfficeVehiclesTableContainer from './BackOfficeVehiclesTableContainer'
import BackOfficeVehiclesWaitingListTableContainer from './BackOfficeVehiclesWaitingListTableContainer'


const mapStateToProps = (state) => {
  return {
    state: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  }
}

class BackOfficeVehiclesContainer extends Component {
  constructor (props) {
    super(props)    
  }

  render () {
    return (
      <Switch>        
        <Route path="/backoffice/vehicles/waiting-list" component={(props) => <BackOfficeVehiclesWaitingListTableContainer {...props} />} />        
        <Route path="/backoffice/vehicles"              component={(props) => <BackOfficeVehiclesTableContainer {...props} />} />
      </Switch>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeVehiclesContainer)
