import React, {Component} from 'react'
import { connect } from 'react-redux'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import { Oval } from 'react-loader-spinner'
import {actions as authActions} from '../../../redux/modules/Auth'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import BackOfficeSettingsMenuComponent from 'components/BackOfficeSettingsMenuComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import { ADMIN } from 'libs/roles'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import BackofficeEditArea from 'components/Areas/BackofficeEditArea'
import BackofficeEditAreaModal from 'components/Areas/BackofficeEditAreaModal'
import { scroller , Element } from 'react-scroll'


/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    backofficeValues: state.form.backofficeSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

/**
 * --------------------------------------------------------------------------
 */
class BackofficeSettingsAreas extends Component {

  /**
   * --------------------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      success: false,
      loading: false,
      submitting: false,
      error: false,
      errorMsg: null,    
      areas: [],
      selectedAreaId: null,
      showEditAreaModal: false,      
      showMaximizedList: true,
    }
    
    this.onSaveArea                = this.onSaveArea.bind(this)    
    this.editArea                  = this.editArea.bind(this)
    this.onCloseEditAreaModal      = this.onCloseEditAreaModal.bind(this)    
    this.onCancelEditArea          = this.onCancelEditArea.bind(this)
  }

  /**
   * --------------------------------------------------------------------------
   */
  async componentDidMount () {
    this.fetchData()    
  }

  /**
   * --------------------------------------------------------------------------
   */
  async fetchData() {
    this.setState({loading: true})

    const areas = await SostaOnlineApiService.fetchAreas()    
        
    if ( areas != null && areas.data != null  )
      this.setState({ areas: areas.data })

    this.fetchThingsToDo()

    this.setState({loading: false})
  }

  /**
   * --------------------------------------------------------------------------
   */
  fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo().then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }   

  /**
   * --------------------------------------------------------------------------
   * @param {*} e 
   */
  async onSaveArea (areaId) {        
    this.setState({submitting: true, error: null, success: false })
    await this.fetchData()
    this.setState({submitting: false, error: false, success: true, selectedAreaId: areaId})           
  }


  /**
   * --------------------------------------------------------------------------
   * @param {*} evt 
   */
  editArea(id) {    
    this.setState({
      selectedAreaId: id,      
    })

    this.scrollToDetail()
  }


  /**
   * --------------------------------------------------------------------------
   * 
   */
  onCloseEditAreaModal() {
    this.setState({
      selectedAreaId: null,
      showEditAreaModal: false
    })
  }

  /**
   * --------------------------------------------------------------------------
   */
  async onCancelEditArea() {

  }

  /**
   * --------------------------------------------------------------------------
   */
  windowMaximize() {
    this.setState({ showEditAreaModal: true})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} element 
   */
  scrollToDetail() {
    scroller.scrollTo("detail", {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }


  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  render () {    

    const {
      success,
      loading,
      submitting,
      error,
      errorMsg,
      areas,
      showEditAreaModal,
      selectedAreaId,      
      showMaximizedList
    } = this.state


    var areasSorted = areas.sort((a, b) => (a.id > b.id ) ? 1 : -1)


    if (loading) {
      return (
        <React.Fragment>
        <h3><strong>Caricamento in corso...</strong></h3>
        <div className="row" style={{padding: "2em", height: "40em"}}>
          <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />                  
        </div>
        </React.Fragment>
      )
    } 
    if ( error )    {
      return (<div className="text-primary">{errorMsg}</div>)
    }

    if ( this.props.auth.user.role != ADMIN ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    return (
      <React.Fragment>
        <BackOfficeSettingsMenuComponent {...this.props} />
        <div className="row">
          <div className="pull-left" style={{ margin: "1em" , marginBottom: "0.5em" }}>
            <button className="mainBtn" onClick={ e => { this.editArea(null) } } ><FontAwesomeIcon  size="1x" icon={solidIcons.faPlusCircle} />
            <span className="hidden-xs hidden-sm">&nbsp;&nbsp;Crea Zona</span></button>
          </div>
        </div>
        <div className="row" style={{  padding: 0,  margin: 0 }}>

          { showMaximizedList ?           
          <div className={ showEditAreaModal ? "box col-md-12" :  "box col-md-4" } style={{ padding: "0.3em", margin: 0 , marginBottom: "0.3em" }}>
            
            <div className="row backofficeSettingsHeader" >
              <div className="col-xs-2"><label>Id</label></div>
              <div className="col-xs-8"><label>Nome</label> </div>       
              <div className="col-xs-1 pull-right" style={{ verticalAlign: "middle"}}>
                <FontAwesomeIcon  size="lg" className="mainIconsRev" icon={solidIcons.faCaretLeft} onClick={ () => { this.setState({showMaximizedList: false}) } } />
              </div>                  
            </div>
                      
            {areasSorted.map(area =>              
              <OverlayTrigger key={`area-${area.id}`}  placement="top" overlay={<Tooltip id={'tooltip-area-'+area.id}>Clicca per modificare i dati della zona</Tooltip>}>
                <div className={ selectedAreaId == area.id ? "backofficeSettingsRowSelected row" :  "backofficeSettingsRow row"}  >
                  <div className="col-xs-12 row">
                    <div className="col-xs-1" onClick={ evt => { this.editArea(area.id)} } ><label >{area.id}</label></div>                  
                    <div className="col-xs-8" onClick={ evt => { this.editArea(area.id)} } ><label >{area.name}</label></div>    
                    <div className="col-xs-1" onClick={ evt => { this.editArea(area.id)} } ><div style={{width:'30px', height:'30px', backgroundColor:area.color}}></div></div>                
                    <div className="col-xs-1 pull-right" style={{ verticalAlign: "middle"}}>{ selectedAreaId == area.id ? <FontAwesomeIcon  size="lg" icon={solidIcons.faCaretRight} /> : "" }</div>
                  </div>
                </div>
              </OverlayTrigger>
            )}

            <div className="row backofficeSettingsFooter" >
              <div></div>
            </div>
          
            {submitting && <div className="text-primary col-xs-12 ">
              Salvataggio in corso
            </div>}

            {error && <div className="text-danger col-xs-12">
              Si sono verificati degli errori
            </div>}

            {success && <div className="text-success col-xs-12">
            
            </div>}
            
            
          </div>
          :
          <div className="col-md-1" style={{ padding: 0, margin: 0 , marginBottom: "0.3em" }}>
            <div className="mainBtn" onClick={ () => { this.setState({showMaximizedList: true}) } } >
              <FontAwesomeIcon  size="lg" icon={solidIcons.faCaretDown} />
            </div>
          </div>
          }
        
          { !showEditAreaModal && 
          <div className={ showMaximizedList ? "col-md-8": "col-md-11"} style={{  padding: "0.3em" , margin: 0 }}>
            <Element name="detail"/>
            <div className="backofficeSettingsBox" >
              <div className="backofficeSettingsBoxHeader row text-center">
                <div className="col-xs-1 pull-left">
                { selectedAreaId != null 
                    ? <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
                    : <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faPlusCircle} />
                  }                
                </div>          
                <div className="col-xs-10 pull-left" >
                  <strong>{ selectedAreaId != null ? "MODIFICA" : "CREA NUOVA" } ZONA </strong>
                </div>
                <div className="col-xs-1 pull-right" onClick={ evt => this.windowMaximize() }>
                  <OverlayTrigger placement="left" overlay={<Tooltip id={"tooltip-category-to-window"}>Mostra in finestra</Tooltip>}>
                    <FontAwesomeIcon  size="1x" className="mainIconsRev" icon={solidIcons.faWindowRestore} />
                  </OverlayTrigger>
                </div>
              </div>
              <div className="row" >
                <BackofficeEditArea                    
                    areaId={selectedAreaId}
                    onSaveArea={this.onSaveArea}      
                    onDeleteArea={this.onSaveArea}    
                    onCancelEditArea={this.onCancelEditArea}
                    />
              </div>
            </div>
          </div>        
          }
        </div>
        
        <BackofficeEditAreaModal
          show={showEditAreaModal}
          areaId={selectedAreaId}
          onSaveArea={this.onSaveArea}      
          onDeleteArea={this.onSaveArea}              
          onCancelEditArea={this.onCancelEditArea}
          onClose={this.onCloseEditAreaModal}
          />
        

      </React.Fragment>

      
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackofficeSettingsAreas)
