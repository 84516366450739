import React, {Component}          from 'react'
import ReactTable                  from 'react-table'
import PaymentMethodTypeFilter     from '../components/ReactTable/Filters/PaymentMethodTypeFilter'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Oval } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { getGridHeight } from 'libs/utils'


export default class BackOfficeReceiptScreen extends Component {
  /**
   * ----------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      maxHeight: 0,
      columns: [
        {
          Header: 'ID Ricevuta',
          accessor: 'id',
          filterable: true,
          sortable: false,
          width: 80,
          Cell: (row) => {
            return (
              <div
                style={row.original.Order.MarkOrders.length > 0 && row.original.Order.MarkOrders[0].Mark && row.original.Order.MarkOrders[0].Mark.archived ? {textDecoration: 'line-through', color: '#a94442'} : null}
              >
                {row.value}

                {row.original.Order.MarkOrders.length > 0 && row.original.Order.MarkOrders[0].Mark && row.original.Order.MarkOrders[0].Mark.archived && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={row.original.Order.MarkOrders[0].Mark.id}>
                        Questa ricevuta è collegata ad un permesso <b>eliminato</b>
                      </Tooltip>
                    }
                  >
                    <div style={{float: 'right', marginRight: '2px'}}>
                      <i className="glyphicon glyphicon-info-sign">{}</i>
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            )
          }
        },
        {
          Header: '',
          accessor: 'Order',
          id: 'orderContentType',
          filterable: false,
          sortable: false,
          width: 140,
          Cell: (row) => {            
            if (row.value.MarkOrders.length > 0) {
              return (
                <span
                  className={row.value.MarkOrders[0].Mark && row.value.MarkOrders[0].Mark.archived ? 'text-danger' : 'text-primary'}
                  style={row.value.MarkOrders[0].Mark && row.value.MarkOrders[0].Mark.archived ? {textDecoration: 'line-through'} : null}
                >
                  PERMESSO
                </span>
              )
            }
            else if  ( row.value.VehiclesChangeOrders.length > 0  ) {
              return (
                <span className="text-primary">CAMBIO VEICOLO</span>
                )
            }

            return (<div>N.D</div>)
          }
        },
        {
          Header: 'Veicoli',
          accessor: 'Order',
          id: 'vehicles',
          filterable: false,
          sortable: false,
          minWidth: 200,
          Cell: (row) => {
                    
            if (row.value && row.value.MarkOrders && row.value.MarkOrders.length > 0) {
              return (
                <div
                  style={row.value.MarkOrders[0].Mark && row.value.MarkOrders[0].Mark.archived ? {textDecoration: 'line-through', color: '#a94442'} : null}
                >
                  <span>
                    {
                      row.value.MarkOrders && row.value.MarkOrders.map((mOrderItem, mOrderIndex) => {

                        if (!mOrderItem.Mark) {
                          return ''
                        }

                        var string = null

                        for ( let index in  mOrderItem.Mark.Vehicles ) {                         
                          var item = mOrderItem.Mark.Vehicles[index]
                          if ( string == null ) {
                            string = item.plate_number
                          }
                          else  {
                            string = string + " , " + item.plate_number
                          }
                        }

                        return string

                      })
                    }
                  </span>
                </div>
              )
            }    
            
            else if ( 
              row.value && row.value.VehiclesChangeOrders.length > 0 &&
              row.value.VehiclesChangeOrders[0].VehiclesChangeRequest && 
              row.value.VehiclesChangeOrders[0].VehiclesChangeRequest.Vehicle 
            )
            {
                return row.value.VehiclesChangeOrders[0].VehiclesChangeRequest.Vehicle.plate_number + " -> " + row.value.VehiclesChangeOrders[0].VehiclesChangeRequest.plate_number
            }

            return (
              <div>N.D.</div>
            )
          }
        },
        {
          Header: 'Tipo',
          accessor: 'Order',
          id: 'orderContentMarkParkTicketType',
          filterable: false,
          sortable: false,
          minWidth: 200,
          Cell: (row) => {

            if (row.value && row.value.MarkOrders.length > 0) {
              return (
                <div style={row.value.MarkOrders[0].Mark && row.value.MarkOrders[0].Mark.archived ? {textDecoration: 'line-through', color: '#a94442'} : null}>
                  <span>
                    {row.value.MarkOrders.map((mOrderItem, mOrderIndex) => {
                      if (!mOrderItem.Mark) {
                        return
                      }

                      return mOrderItem.Mark.MarkType.name + ' '
                    })
                    }
                  </span>
                </div>
              )
            }

            return (
              <div>N.D.</div>
            )
          }
        },
        {
          Header: 'ID Permesso/Ricevuta',
          accessor: 'Order',
          id: 'orderContentId',
          filterable: false,
          sortable: false,
          width: 80,
          Cell: (row) => {
            
            if (row.value && row.value.MarkOrders.length > 0) {
              return (
                <span
                  style={row.value.MarkOrders[0].Mark && row.value.MarkOrders[0].Mark.archived ? {textDecoration: 'line-through', color: '#a94442'} : null}
                >
                  {
                    row.value.MarkOrders && row.value.MarkOrders.map((mOrderItem, mOrderIndex) => {
                      if (!mOrderItem.Mark) {
                        return
                      }

                      return mOrderItem.Mark.id + ' '
                    })
                  }
                </span>
              )
            }            

            return (<div>N.D.</div>)
          }
        },
        {
          Header: 'Totale',
          accessor: 'total',
          filterable: false,
          sortable: false,
          Cell: (row) => {
            return (
              <div
                style={row.original.Order.MarkOrders.length > 0 && row.original.Order.MarkOrders[0].Mark && row.original.Order.MarkOrders[0].Mark.archived ? {textDecoration: 'line-through', color: '#a94442'} : null}
              >
                € {Number(row.value).toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </div>
            )
          }
        },
        {
          Header: 'Metodo di Pagamento',
          accessor: 'method',
          filterable: true,
          sortable: false,
          Cell: (row) => {
            return (
              <div
                style={row.original.Order.MarkOrders.length > 0 && row.original.Order.MarkOrders[0].Mark && row.original.Order.MarkOrders[0].Mark.archived ? {textDecoration: 'line-through', color: '#a94442'} : null}
              >
                {row.value}
              </div>
            )
          },
          Filter: ({ filter, onChange }) => <PaymentMethodTypeFilter filter={filter} onChange={onChange} />
        },
        {
          Header: 'Nome',
          accessor: 'Order.User.firstName',
          filterable: true,
          sortable: false,
          Cell: (row) => {
            return (
              <div
                style={row.original.Order.MarkOrders.length > 0 && row.original.Order.MarkOrders[0].Mark && row.original.Order.MarkOrders[0].Mark.archived ? {textDecoration: 'line-through', color: '#a94442'} : null}
              >
                {row.value}
              </div>
            )
          }
        },
        {
          Header: 'Cognome',
          accessor: 'Order.User.lastName',
          filterable: true,
          sortable: false,
          Cell: (row) => {
            return (
              <div
                style={row.original.Order.MarkOrders.length > 0 && row.original.Order.MarkOrders[0].Mark && row.original.Order.MarkOrders[0].Mark.archived ? {textDecoration: 'line-through', color: '#a94442'} : null}
              >
                {row.value}
              </div>
            )
          }
        }
      ]
    }

    this.getTotalPages = this.getTotalPages.bind(this)
    this.onFetchData   = this.onFetchData.bind(this)
    this.handleResize  = this.handleResize.bind(this)
  }

  /**
   * ----------------------------------------------------------------
   */
  componentDidMount () {    
    window.addEventListener('resize', this.handleResize)
    this.setState({ maxHeight : getGridHeight() })
  }

  /**
   * ----------------------------------------------------------------
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  /**
   * ----------------------------------------------------------------
   */
  handleResize ( ) {        
    this.setState({ maxHeight : getGridHeight() })    
  }


  /**
   * ----------------------------------------------------------------
   * @param {*} state 
   * @param {*} instance 
   */
  onFetchData (state, instance) {
    let filterObj = {}
    for (let i in state.filtered) {
      let f = state.filtered[i]

      if (f.id === 'id') {
        filterObj['id'] = f.value
      }

      if (f.id === 'Order.User.firstName') {
        filterObj['firstName'] = f.value
      }

      if (f.id === 'Order.User.lastName') {
        filterObj['lastName'] = f.value
      }

      if (f.id === 'method') {
        filterObj['method'] = f.value
      }
    }

    let sortObj = {}
    for (let i in state.sorted) {
      let s = state.sorted[i]
      sortObj[s.id] = s.desc ? 'desc' : 'asc'
    }

    this.props.fetchReceipts(filterObj, state.pageSize, state.page)
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  getTotalPages () {
    const {pagination} = this.props

    if (!pagination) {
      return 0
    }

    if (pagination.total === 0) {
      return 0
    }

    return Math.ceil(pagination.total / pagination.limit)
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  render () {
    const {
      columns,
      maxHeight
    } = this.state
    return (<div>
      <ReactTable
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (!rowInfo) {
                return
              }
              this.props.onRowClick(rowInfo.index, rowInfo.original)
            }}
        }}
        manual
        filterable
        loading={this.props.loading}
        noDataText={'Nessuna Ricevuta Presente'}
        onFetchData={this.onFetchData}
        pages={this.getTotalPages()}
        className="-highlight"
        data={this.props.receipts ? this.props.receipts : []}
        style={{ maxHeight: maxHeight }}
        defaultPageSize={100}
        nextText={<FontAwesomeIcon  className="mainIconsNoAction"  size="2x" icon={solidIcons.faArrowRight} />}
        previousText={<FontAwesomeIcon  className="mainIconsNoAction"  size="2x" icon={solidIcons.faArrowLeft} />}
        loadingText={<Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />}
        rowsText="elementi"
        ofText="di"
        pageText="Pagina"
        columns={columns} />
    </div>)
  }
}
