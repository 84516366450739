import { methods } from '../middlewares/fetch';

// ======================================================
// TYPES
//
export const types = {
    LOGOUT:                 "AUTH/LOGOUT",
    LOGIN_REQUEST:          "AUTH/LOGIN_REQUEST",
    LOGIN_ERROR:            "AUTH/LOGIN_ERROR",
    LOGIN_GET_SPID_TOKEN:   "AUTH/GET_SPID_TOKEN",
    LOGIN_CLEAR_SPID_TOKEN: "AUTH/CLEAR_SPID_TOKEN",
    SPID_LOGIN:             "AUTH/SPID_LOGIN",
    CLEAR_LOGIN_ERROR:      "AUTH/CLEAR_LOGIN_ERROR",
    SET_SPID_ENABLE:        "AUTH/SET_SPID_ENABLE"
};

// ======================================================
// INITIAL STATE
//
export const initialState = {
    token: null,
    user: null,
    loading: false,
    error: false,
    spidToken: null, 
    spidProfile: null,
    errorMsg: null,
    spidEnable: false
};

// ======================================================
// REDUCER
//
export default function auth(state = initialState, action) {
    switch (action.type) {

        // ----------------------------------------------------------------
        //
        //
        case types.LOGOUT:
            return {                
                token: null,
                user: null,
                spidToken: null, 
                spidProfile: null,
            };

        // ----------------------------------------------------------------
        //
        //
        case types.LOGIN_REQUEST + "/SUCCESS":
            return {
                ...state,
                token: action.data.data.token,
                user: {
                    id: action.data.data.user.id,
                    username: action.data.data.user.username,
                    email: action.data.data.user.email,
                    phone: action.data.data.user.phone,
                    firstName: action.data.data.user.firstName,
                    lastName: action.data.data.user.lastName,
                    role: action.data.data.user.role,
                    budget: action.data.data.user.budget
                },
                spidToken: null, 
                spidProfile: null
            };

        // ----------------------------------------------------------------
        //
        //
        case types.LOGIN_REQUEST + "/ERROR":
            return {
                ...state,
                token: null,
                user: null,
                spidToken: null, 
                spidProfile: null,
            };

        // ----------------------------------------------------------------
        //
        //
        case types.LOGIN_ERROR:
            return {
                ...state,
                token: null,
                user: null,
                spidToken: null, 
                spidProfile: null,
            };

        // ----------------------------------------------------------------
        //
        //
        case types.LOGIN_CLEAR_SPID_TOKEN:
            //console.log("LOGIN_CLEAR_SPID_TOKEN")
            return {
                ...state,
                spidToken: null, 
                spidProfile: null,
            }

        // ----------------------------------------------------------------
        //
        //
        case types.SPID_LOGIN + "/SUCCESS":
            //onsole.log("SPID_LOGIN/SUCCESS DATA ",action.data)
            return {
                ...state,
                token: action.data.data.token,
                user: {
                    id: action.data.data.user.id,
                    username: action.data.data.user.username,
                    email: action.data.data.user.email,
                    phone: action.data.data.user.phone,
                    firstName: action.data.data.user.firstName,
                    lastName: action.data.data.user.lastName,
                    role: action.data.data.user.role,
                    budget: action.data.data.user.budget
                },
                spidToken: null, 
                spidProfile: action.data.data.spidProfile
            };
        
        // ----------------------------------------------------------------
        //
        //
        case types.SPID_LOGIN + "/REGISTRATION_REDIRECT":
            //console.log("SPID_LOGIN/REGISTRATION_REDIRECT DATA ",action.data)
            return {
                ...state,
                token: null,
                user: null,
                spidToken: null, 
                spidProfile: action.data.data.spidProfile
            };

        // ----------------------------------------------------------------
        //
        //
        case types.SPID_LOGIN + "/ERROR":
            //console.log("SPID_LOGIN/ERROR",action.data)
            return {
                ...state,
                token: null,
                user: null,
                spidToken: null, 
                spidProfile: null,
            };


        // ----------------------------------------------------------------
        //
        //
        case types.LOGIN_GET_SPID_TOKEN:
            var result = {
                ...state,
                token: null,
                user: null,                
                spidToken: null, 
                error: false,
                errorMsg: null
            }

            if ( action != null && action.data != null ) {

                if ( action.data.result === "ko" ) {
                    return {
                        ...result, 
                        error: true,
                        errorMsg: action.data.error
                    };
                }
                else if ( action.data.result === "ok" && action.data.data.success  ) {
                    return {
                        ...result,
                        spidToken: action.data.data.data,
                    };
                }
            }

            return result;
        
        case types.SET_SPID_ENABLE:

            if ( action != null && action.data != null ) {

                return {
                    ...state,
                    spidEnable: action.data.enable
                }
            }
            
            return {
                ...state,
                spidEnable: false
            }
            
        
        // ----------------------------------------------------------------
        //
        //
        default:
            return state;
    }
}


// ======================================================
// ACTIONS
//
export const actions = {

    // ----------------------------------------------------------------
    //
    //
    loginRequest: (credentials) => {
        return {
            type: types.LOGIN_REQUEST,
            $api: {
                method: methods.LOGIN,
                body: {
                    username: credentials.username,
                    password: credentials.password
                }
            }
        };
    },

    // ----------------------------------------------------------------
    //
    //
    spidLogin: (spidToken) => {
        return {
            type: types.SPID_LOGIN,
            $api: {
                method: methods.SPID_LOGIN,
                body: {
                    spidToken: spidToken
                }
            }
        }
    },

    // ----------------------------------------------------------------
    //
    //
    logout: () => {
        return {
            type: types.LOGOUT,
            $api: {
                method: methods.LOGOUT,
            }
        }
    },

    // ----------------------------------------------------------------
    //
    //
    getSpidToken: () => {
        return {
            type: types.LOGIN_GET_SPID_TOKEN,
            $api: {
                method: methods.GET_SPID_TOKEN
            }
        }
    },

    // ----------------------------------------------------------------
    //
    //
    setSpidEnable: () => {
        return {
            type: types.SET_SPID_ENABLE,
            $api: {
                method: methods.SET_SPID_ENABLE
            }
        }
    },


    // ----------------------------------------------------------------
    //
    //
    clearSpidToken: () => {
        return {
            type: types.LOGIN_CLEAR_SPID_TOKEN,
            $api: {
                
            }
        }
    },

    // ----------------------------------------------------------------
    //
    //
    clearLoginError: () => {
        return {
            type: types.CLEAR_LOGIN_ERROR,
            $api: {
                method: methods.CLEAR_LOGIN_ERROR
            }
        }
    }
   

};