import React, {Component} from 'react'
import queryString from 'query-string';
import PasswordRecoveryScreen from '../screens/PasswordRecoveryScreen'
import SostaOnlineApiService from "../services/SostaOnlineApiService";

export const STATE_SEND_MAIL = "SEND_MAIL";
export const STATE_MAIL_SENDED = "MAIL_SENDED";
export const STATE_CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const STATE_PASSWORD_CHANGED = "STATE_PASSWORD_CHANGED";

export default class PasswordRecoveryContainer extends Component {
    constructor(props) {
        super(props);
        let params = queryString.parse(props.location.search);
        let currentState = STATE_SEND_MAIL;
        if(params.key && params.userId) {
            currentState = STATE_CHANGE_PASSWORD;
        }
        this.state = {
            currentState: currentState,
            loading: false,
            error: null,
            params: params
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        switch (this.state.currentState) {

            case STATE_SEND_MAIL:
                this.setState({error: null, loading: true});
                SostaOnlineApiService.sendMailChangePasswordMe(values).then(data => {
                    this.setState({currentState: STATE_MAIL_SENDED, loading: false, error: null});
                }).catch(err => {
                    if(err.error === "User not found") {
                        this.setState({error: "Utente non trovato", loading: false});
                    } else {
                        this.setState({error: err.error, loading: false});
                    }
                });
                break;

            case STATE_CHANGE_PASSWORD:
                this.setState({error: null, loading: true});
                SostaOnlineApiService.changePasswordMe({...values, key: this.state.params.key, userId: this.state.params.userId}).then(data => {
                    this.setState({currentState: STATE_PASSWORD_CHANGED});
                }).catch(err => {
                    if(err.error === "Key not found") {
                        this.setState({error: "Richiesta recupero password non trovata", loading: false});
                    } else if(err.error === "Passwords does not match") {
                        this.setState({error: "Le due password non coincidono", loading: false});
                    } else if(err.error === "Password too short. Minimum 8 characters") {
                        this.setState({error: "La password deve avere almeno 8 caratteri", loading: false});
                    } else if(err.error === "Key expired") {
                        this.setState({error: "Richiesta recupero password scaduta. Effettuane una nuova.", loading: false});
                    }  else {
                        this.setState({error: err.error, loading: false});
                    }
                });
                break;

            default:
                break;

        }
    }

    render() {
        
        return(
            <PasswordRecoveryScreen
                history={this.props.history}
                currentState={this.state.currentState}
                formError={this.state.error}
                onSubmit={this.onSubmit} />
        );
    }
}