import React, {Component}                from 'react'
import {reduxForm, Field}                from 'redux-form'
import {connect}                         from 'react-redux'
import {NavLink}                         from 'react-router-dom'
import DatePickerFormComponent           from '../components/redux-form/DatePickerFormComponent'
import VirtualizedSelectFormComponent    from '../components/redux-form/VirtualizedSelectFormComponent'
import                                        './BackOfficeUserEditScreen.css'
import CategoriesTreeSelectFormComponent from '../components/redux-form/CategoriesTreeSelectFormComponent'
import { FontAwesomeIcon }               from '@fortawesome/react-fontawesome'
import  * as solidIcons                  from '@fortawesome/free-solid-svg-icons'
import { Oval } from 'react-loader-spinner'
import { formatCityString, parseCap, parseCivicNumber, parseEmail, parseFiscalCode, parsePhoneNumber, parseVatNumber } from 'libs/utils'

/**
 * ----------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    userFormValues: state.form.backofficeUserEdit
  }
}

/**
 * ----------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}


/**
 * ================================================================
 * 
 */
class BackOfficeUserEditScreen extends Component {

  /**
   * ----------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      deleteClicked: false,      
      residenceArea: null,
      workArea: null,
      companyLegalCity: null,
    }
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} categories 
   * @returns 
   */
  isResident(categories) {
    let filteredCategories = categories.filter(item => {
      return item.resident
    })

    return filteredCategories.length > 0
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} categories 
   * @returns 
   */
  isParkPlaceInDifferentAddress (categories) {
    let filteredCategories = categories.filter(item => {
      //console.log(item)
      return item.parkingInDifferentPlace
    })

    return filteredCategories.length > 0
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} categories 
   * @returns 
   */
  isWorker(categories) {
    let filteredCategories = categories.filter(item => {
      return item.worker
    })

    return filteredCategories.length > 0
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} categories 
   * @returns 
   */
  isWorkerPerson (categories) {
    //console.log(categories)
    let filteredCategories = categories.filter(item => {
      return item.workerPerson
    })

    return filteredCategories.length > 0
  }


  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  getAreasSelectOptions() {
    var options = []

    if ( this.props.areas ) {
      options = this.props.areas.map( item => { 
        return { label : item.name , value : item.id }
      })
    }

    return options
  }


  /**
   * ----------------------------------------------------------------
   * @param {*} areaId 
   */
  onWorkAreaSelected(areaId) {

    if (!areaId) {
      this.props.change( 'workAreaId', null )
      this.setState({workArea: null})
      return
    }

    // let address = this.props.addresses.data.find(item => { return item.id === addressId })
    this.setState({workArea: this.getArea(areaId)})
    this.props.change( 'workAreaId', areaId )
        
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} addressId 
   * @returns 
   */
  onWorkAddressSelected (addressId) {
    if (!addressId) {
      this.props.change( 'workAreaId', null )
      this.setState({workArea: null})
      return
    }

    let address = this.props.addresses.data.find(item => { return item.id === addressId })
    this.setState({workArea: address.Area})
    this.props.change( 'workAreaId', address.Area.id )
  }   

  /**
   * ----------------------------------------------------------------
   * @param {*} cityId 
   */
  onCompanyLegalCitySelected(city) {    
    this.setState({ companyLegalCity: city })
    this.props.change( 'companyLegalCity', city )

    if ( this.isPortalLegalCityEqualToCurrentCitySelected(city) ) 
      this.props.change( 'companyLegalCap', this.props.city.cap )
    else
      this.props.change( 'companyLegalCap', null )
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} areaId 
   * @returns 
   */
  getArea(areaId) {        
    let area = this.props.areas.find( item => { return item.id === areaId } )    
    return area
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} addressId 
   * @returns 
   */
  onResidentAddressSelected (addressId) {
    if (!addressId) {      
      this.props.change( 'residenceAreaId', null )
      this.setState({ residenceArea: null })
      return
    }

    let address = this.props.addresses.data.find(item => { return item.id === addressId })
    this.setState({residenceArea: address.Area})    
    this.props.change( 'residenceAreaId' ,address.Area.id )
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} areaId 
   * @returns 
   */
  getAreaAddresses(areaId) {
    var addresses =  this.props.addresses.data

    addresses = addresses.filter( address => { 
      if ( areaId != null ) {
        if ( address.Area.id == areaId )
          return address
      }
      else 
        return address
    })

    addresses =  addresses.map(item => {
        return { label: item.name, value: item.id }
    })

    return addresses
  }


  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity) {
    var check = false

    if ( formatCityString(this.props.city.longName , this.props.city.province ) == companyLegalCity  )
      check = true

    return check
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} e 
   */
  onCompanyLegalAddressChange (e) {
    this.props.change('companyLegalAddressId', null)
  }


  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  render () {
    const { 
      handleSubmit, 
      loading, 
      errorMessage, 
      user, 
      comuni, 
      comuniWithoutCurrentCity, 
      success,
      onDeleteUser,
      addresses,
      residenceDataForAllUsers      
    } = this.props

    var {
      residenceArea,
      workArea,
      companyLegalCity
    } = this.state    

    if ( !residenceArea) {
      residenceArea = this.getArea(this.props.initialValues.residenceAreaId)            
    }

    if ( !workArea) {
      workArea = this.getArea(this.props.initialValues.workAreaId)            
    }

    if ( ! companyLegalCity ) {
      companyLegalCity = this.props.initialValues.companyLegalCity
    }

    if (loading) {
      return (
        <div>
          <div className="row"><h3>Caricamento in corso...</h3></div>
          <div className="row" style={{padding: "2em", height: "40em"}}>
            <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
          </div>
        </div>
      )
    }

    return (<div className="col-md-12">
      <div className="panel panel-default panel-border">
        <div className="panel-body">
          <div className="titolo-categoria">
            <h3 className="text-left sectionTitle">                    
              <FontAwesomeIcon size="1x" className="sectionIcons" icon={solidIcons.faUserEdit} />&nbsp;&nbsp;MODIFICA UTENTE
            </h3>
          </div>
          <div className="row">
            <div className="pull-left" style={{  padding: 0,  margin: 0 , marginLeft: "1em" }}>
              {user && 
                <NavLink exact className="btn mainBtn" to={'/backoffice/users/' + user.id}>
                  <FontAwesomeIcon size="1x" icon={solidIcons.faDeleteLeft} />&nbsp;&nbsp;
                  TORNA AL PROFILO
                </NavLink>
              }
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="box">
              {/* Inizio informazioni generali utente */}
              <fieldset>
                <div className="titolo-categoria">
                  <legend className="text-left">Informazioni Generali</legend>
                </div>
                <div className="row">
                  <div className="col-md-6 text-left">
                    <label>Codice Fiscale</label>
                    <Field 
                      parse={ (value,name) => parseFiscalCode(value) }
                      component="input" 
                      name="fiscalCode" 
                      type="text" 
                      className="mainField" 
                      required />
                  </div>
                  <div className="col-md-6 text-left">
                    <label>Rigenera Credenziali</label><br />
                    <button onClick={() => this.props.onGenerateUserCredentials()} className="btn btn-default">Rigenera Password e Stampa Credenziali</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-left">
                    <label>Nome</label>
                    <Field component="input" name="firstName" type="text" className="mainField" required />
                  </div>
                  <div className="col-md-6 text-left">
                    <label>Cognome</label>
                    <Field component="input" name="lastName" type="text" className="mainField" required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-left">
                    <label>E-mail</label>
                    <Field 
                      parse={ (value,name) => parseEmail(value) }
                      component="input" 
                      name="email" 
                      type="text" 
                      className="mainField" 
                      required />
                  </div>
                  <div className="col-md-6 text-left">
                    <label>Telefono Mobile</label>
                    <Field 
                      parse={ (value,name) => parsePhoneNumber(value) }
                      component="input" 
                      name="phone" 
                      type="text" 
                      placeholder="333 333 3333"
                      className="mainField" />
                  </div>
                </div>
                
              </fieldset>
              {/* Fine informazioni generali utente */}

              {/* Inizio Categorie Utente */}
              <div className="row">
                <div className="col-md-6 text-left">
                  <label>Seleziona Categorie Utente</label>
                  <Field
                    mode="multiSelect"
                    required
                    options={this.props.categoryOptions}
                    component={CategoriesTreeSelectFormComponent}
                    onCategoriesChange={(categories) => this.props.onCategoriesChange(categories)}
                    name="userCategories"
                    placeholder="Seleziona Categorie Utente" />
                </div>
              </div>
              {/* Fine Categorie Utente */}

              {/* Inizio Informazioni Residente */}
              { this.isResident(this.props.selectedCategories)  &&
              <fieldset>
                <div className="titolo-categoria">
                  <legend  className="text-left">Informazioni Residente</legend>
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-3 text-left">
                      <label>Data di Nascita</label>
                      <Field 
                        placeholderText="GG/MM/AAAA"
                        component={DatePickerFormComponent} 
                        name="birthDate" 
                        className="mainField" 
                        required />
                    </div>
                    <div className="col-md-3 text-left">
                      <label>Città di Nascita</label>
                      <Field
                        name="birthPlace" options={comuni}
                        component={VirtualizedSelectFormComponent} required />
                    </div>
                    <div className="col-md-3 text-left">
                      <label>Sesso</label><br />
                      <label className="sex">
                        <Field name="sex" component="input" type="radio" value="M" required /> {' '} Uomo
                      </label>
                      {' '}
                      <label>
                        <Field name="sex" component="input" type="radio" value="F" required /> {' '} Donna
                      </label>
                    </div>
                  </div>                                                        
                  <div className="row">
                    <div className="col-md-6 text-left">
                      <label>Indirizzo di Residenza/Domicilio</label>                      
                      <Field
                          onChange={(event, data) => this.onResidentAddressSelected(data)}
                          name="residenceAddress"
                          options={addresses.data.map(item => {
                            return {
                              label: item.name,
                              value: item.id
                            }
                          })} 
                          component={VirtualizedSelectFormComponent} 
                          required 
                        />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>N°</label>
                      <Field                        
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="residenceCivicNumber"
                        type="text" 
                        placeholder="N°"
                        className="mainField"
                        required />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>Interno</label>
                      <Field
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="residenceInside"
                        type="text" 
                        placeholder="Interno"
                        className="mainField"
                        required />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>Area di Residenza</label>                      
                      { residenceArea && <h4>{residenceArea.name}</h4> }                      
                      <Field
                        hidden={true}
                        disabled={true}
                        component="input"
                        name="residenceAreaId"
                        type="text"
                        placeholder="AreaId"
                        className="mainField"                            
                        required
                      />                    
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-info text-left">
                      I campi a seguire sono da compilare solo nel caso l'indirizzo precedente rappresenti il domicilio
                    </div>
                    <div className="col-md-6 text-left">
                      <label>Indirizzo di Residenza</label>                      
                      <Field
                        component="input"
                        name="secondaryResidenceAddress"
                        type="text"
                        placeholder="Indirizzo di residenza"
                        className="mainField"
                      />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>N°</label>
                      <Field                        
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="secondaryResidenceCivicNumber"
                        type="text" 
                        placeholder="N°"
                        className="mainField"
                        />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>Interno</label>
                      <Field
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="secondaryResidenceInside"
                        type="text" 
                        placeholder="Interno"
                        className="mainField"
                        />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2 text-left">
                      <label>CAP</label>
                      <Field
                        parse={ (value,name) => parseCap(value) }
                        component="input"
                        name="secondaryResidenceCap"
                        type="text"
                        placeholder="CAP"
                        className="mainField"
                      />
                    </div>
                    <div className="col-md-3 text-left">
                      <label>Città</label>
                      <Field
                        name="secondaryResidenceCity"
                        options={comuniWithoutCurrentCity}
                        component={VirtualizedSelectFormComponent}
                      />
                    </div>
                  </div>                  
                  {this.isParkPlaceInDifferentAddress(this.props.selectedCategories) && (
                    <div className="row">
                      <div className="col-md-12 text-info text-left" style={{marginTop: 10, marginBottom: 10}}>
                        Questa categoria utente ha un posto auto in un luogo diverso da quello di residenza/domicilio
                      </div>
                      <div className="col-md-7 text-left">
                        <label>
                          Indirizzo del Posto Auto
                        </label>
                        <Field
                          onChange={(event, data) => this.props.onParkingSpaceAddressSelected(data)}
                          name="parkingSpaceAddress"
                          options={
                            this.props.addresses.data.map(item => {
                              return { label: item.name, value: item.id }
                            })} component={VirtualizedSelectFormComponent} required />
                      </div>
                      {this.props.selectedParkingSpaceArea && <div className="col-md-3 text-left">
                        <label>Area Selezionata</label>
                        <div style={{marginTop: 12}}>
                          <h4>{this.props.selectedParkingSpaceArea.name}</h4>
                        </div>
                      </div>}
                    </div>
                  )}
                </div>
              </fieldset>}
              {/* Fine Informazioni Residente */}

              {/* dati di residenza per i non residenti nel comune */}
              { !this.isResident(this.props.selectedCategories) && residenceDataForAllUsers &&
              <fieldset>
                <div className="titolo-categoria">
                  <legend  className="text-left">Informazioni di Residenza</legend>
                </div>
                <div>                                                                    
                  <div className="row">
                    <div className="col-md-6 text-left">
                      <label>Indirizzo di Residenza</label>                      
                      <Field                     
                          component="input"     
                          name="residenceAddress"
                          type="text" 
                          placeholder="Indirizzo di residenza"
                          className="mainField"
                          required 
                        />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>N°</label>
                      <Field
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="residenceCivicNumber"
                        type="text" 
                        placeholder="N°"
                        className="mainField"
                        required />
                    </div>  
                    <div className="col-md-2 text-left">
                      <label>Interno</label>
                      <Field
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="residenceInside"
                        type="text" 
                        placeholder="Interno"
                        className="mainField"
                        required />
                    </div>
                    <div className="col-md-6 text-left">
                      <label>Città</label>
                      <Field
                        name="residenceCity"
                        options={comuniWithoutCurrentCity}
                        component={VirtualizedSelectFormComponent}
                        required />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>CAP</label>
                      <Field
                        parse={ (value,name) => parseCap(value) }
                        component="input"
                        name="residenceCap"
                        type="text"
                        placeholder="CAP"
                        className="mainField"
                        required
                      />
                    </div>                       
                  </div>                                    
                </div>
              </fieldset>}
              {/* fine dati di residenza per i non residenti nel comune */}

              {/* Inizio Informazioni Azienda */}              
              { this.isWorker(this.props.selectedCategories) &&
              <fieldset>
                <div className="titolo-categoria">
                  <legend  className="text-left">Informazioni Azienda</legend>
                </div>
                <div>

                  <div className="row" style={{ marginTop: "1em" }}>
                    <div className="col-md-6 text-left">
                      <label>Nome Azienda</label>
                      <Field
                        component="input"
                        name="companyName"
                        type="text"
                        placeholder="Nome Azienda..."
                        className="mainField"
                        required />
                    </div>
                  
                    <div className="col-md-4 text-left">
                      <label>Partita IVA</label>
                      <Field 
                        parse={ (value,name) => parseVatNumber(value) }
                        component="input" 
                        name="vatNumber" 
                        type="text" 
                        placeholder="0123456789" 
                        className="mainField" 
                        required />
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "1em" }}>
                    <label className="col-md-12 text-left">SEDE LEGALE</label>
                  </div>
              
                  <div className="row" style={{ marginTop: "1em" }}>

                    <div className="col-md-3 text-left">
                      <label>Città</label>
                      <Field
                        onChange={(event, data) => this.onCompanyLegalCitySelected(data)}
                        name="companyLegalCity" 
                        options={comuni}                        
                        component={VirtualizedSelectFormComponent} 
                        required />
                    </div>
 
                    <div className="col-md-5 text-left">
                      <label>Indirizzo</label>
                      {/*!this.isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity)  &&*/
                      <Field
                        onChange={(event) => this.onCompanyLegalAddressChange(event)}
                        component="input"
                        name="companyLegalAddress"
                        type="text"
                        placeholder="Indirizzo ..."
                        className="mainField"
                        required />
                      }

                      {/*this.isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity)  && 
                      <Field                        
                        name="companyLegalAddressId" 
                        options={
                        addresses.data. map(item => {
                            return { label: item.name, value: item.id }
                          }
                        )} 
                        component={VirtualizedSelectFormComponent} 
                        required 
                      />
                      */}
                    </div>                   

                    <div className="col-md-2 text-left">
                      <label>N°</label>
                      <Field
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="companyLegalCivicNumber"
                        type="text"
                        placeholder="N°"
                        className="mainField"
                        required />
                    </div>

                    <div className="col-md-2 text-left">
                      <label>Interno</label>
                      <Field
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="companyLegalInside"
                        type="text"
                        placeholder="Interno"
                        className="mainField"
                        required />
                    </div>

                    <div className="col-md-2 text-left">
                      <label>CAP</label>
                      <Field
                        parse={ (value,name) => parseCap(value) }
                        component="input"
                        name="companyLegalCap"
                        disabled={this.isPortalLegalCityEqualToCurrentCitySelected(companyLegalCity)}
                        type="text"
                        placeholder="CAP"
                        className="mainField"
                        required />
                    </div>

                  </div>

                  <div className="row" style={{ marginTop: "1em" }}>
                    <label className="col-md-12 text-left">AREA DI LAVORO</label>
                  </div>

                  <div className="row" style={{ marginTop: "1em" }}>

                    <div className="col-md-3 text-left">
                      <label>Seleziona Area</label>
                      <Field
                          name="workAreaId"
                          options={this.getAreasSelectOptions()}
                          component={VirtualizedSelectFormComponent}
                          placeholder="Seleziona Area di Lavoro"
                          onChange={(event, data) => this.onWorkAreaSelected(data)}
                          required
                        />                                                
                    </div>   

                    <div className="col-md-5 text-left">
                        <label>Indirizzo</label>
                        <Field
                          onChange={(event, data) => this.onWorkAddressSelected(data)}
                          name="workAddressId" 
                          options={ this.getAreaAddresses(workArea != null ? workArea.id : null) } 
                          component={VirtualizedSelectFormComponent} 
                          required 
                        />
                    </div>
                    
                    <div className="col-md-2 text-left">
                      <label>N°</label>
                      <Field
                        component="input"
                        name="workCivicNumber"
                        type="text"
                        placeholder="N°"
                        className="mainField"
                        required />
                    </div>  

                    <div className="col-md-2 text-left">
                      <label>Interno</label>
                      <Field
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="workInside"
                        type="text"
                        placeholder="Interno"
                        className="mainField"
                        required />
                    </div>                              
                    
                  </div>
                  
                </div>

                
              </fieldset>}
              {/* Fine Informazioni Azienda */}

              {/* Inizio Informazioni Lavoratore/Titolare di Attività Economica */}
              { !this.isWorker(this.props.selectedCategories) && this.isWorkerPerson(this.props.selectedCategories) && 
              <fieldset>
               <div className="titolo-categoria">
                  <legend  className="text-left">Informazioni Dipendente</legend>
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-6 text-left">
                      <label>Nome Azienda</label>
                      <Field
                        component="input"
                        name="companyName"
                        type="text"
                        placeholder="Nome Organizzazione..."
                        className="mainField"
                      />
                    </div>

                    <div className="col-md-5 text-left">
                        <label>Partita IVA</label>
                        <Field
                          parse={ (value,name) => parseVatNumber(value) }
                          component="input"
                          name="vatNumber"
                          type="text"
                          placeholder="0123456789"
                          className="mainField"
                        />
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 text-left">
                        <label>Indirizzo di Lavoro</label>
                        <Field
                          onChange={(event, data) => this.onWorkAddressSelected(data)}
                          name="workAddressId" 
                          options={
                            addresses.data.map(item => {
                                return { label: item.name, value: item.id }
                              }
                            )} 
                          component={VirtualizedSelectFormComponent} 
                          required 
                        />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>N°</label>
                      <Field
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="workCivicNumber"
                        type="text"
                        placeholder="N°"
                        className="mainField"
                      />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>Interno</label>
                      <Field
                        parse={ (value,name) => parseCivicNumber(value) }
                        component="input"
                        name="workInside"
                        type="text"
                        placeholder="Interno"
                        className="mainField"
                      />
                    </div>
                    <div className="col-md-2 text-left">
                      <label>Area di Lavoro</label>
                      { workArea && <h4>{workArea.name}</h4> }
                      <Field
                        hidden={true}
                        disabled={true}
                        component="input"
                        name="workAreaId"
                        type="text"
                        placeholder="AreaId"
                        className="mainField"                            
                        required
                      />                    
                    </div>
                  </div>                 
                </div>
              </fieldset>}
              {/* Fine Informazioni Lavoratore/Titolare di Attività Economica */}
              
              {loading && <div className="well">Modifica Utente in Corso...</div> }

              {errorMessage && <div className="panel panel-danger text-danger">
                <strong>
                <br/>
                Errore nella modifica utente, verificare i dati e riprovare!
                <br/>
                {errorMessage}
                </strong>
              </div>}

              {success && 
              <div>
                <div className="row">
                  <div className="col-md-4 col-md-offset-4 ">
                    <div className="contrassegno">
                      <h4 className="contrassegno-h">Utente modificato correttamente!</h4>
                      <p>
                        <NavLink to={'/backoffice/users/' + user.id} className="btn mainBtn">
                          Torna Al Profilo
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>}

              <br/>

              {this.state.deleteClicked && <div className="row">
                <div className="col-md-12 text-center" style={{marginBottom: 10}}>
                  <h3>Attenzione! Questa operazione non è reversibile, assicurarsi che non ci siano abbonamenti o permessi attivi su questo utente prima di confermare l{"'"}eliminazione</h3>
                </div>
              </div>}
              <div className="row">                                                                  
                <div className="col-md-12 text-center">
                  <button type="submit" className="mainBtn">
                    <FontAwesomeIcon size="1x" icon={solidIcons.faEdit} />&nbsp;&nbsp;
                    Modifica Utente
                  </button>
                  { !this.state.deleteClicked &&
                    <a onClick={() => this.setState({deleteClicked: true})} className="alertBtn">
                      <FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;
                      ELIMINA UTENTE
                    </a>
                  }
                  { this.state.deleteClicked &&
                    <a onClick={() => this.setState({deleteClicked: false})} className="cancelBtn">
                      <FontAwesomeIcon size="1x" icon={solidIcons.faXmarkCircle} />&nbsp;&nbsp;
                      ANNULLA ELIMINAZIONE
                    </a>
                  }
                  { this.state.deleteClicked &&
                    <a onClick={onDeleteUser} className="alertBtn">
                      <FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;
                      CONFERMA ELIMINAZIONE UTENTE
                    </a>
                  }
                </div>
              </div>

              <br/>

            </div>
          </form>
        </div>        
      </div>
    </div>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'backofficeUserEdit',
  enableReinitialize: true
})(BackOfficeUserEditScreen))
