import { createSlice  } from "@reduxjs/toolkit";

const report = createSlice({
    name: 'report',

    initialState: {
        waitingMarks: 0,
        newMessages: 0,
        waitingVehicles: 0
    },

    reducers: {

        fetchThingsToDo: (state,action) => {                                                  
            state.waitingMarks    = action.payload.waitingMarks    != null ? action.payload.waitingMarks     : state.waitingMarks
            state.newMessages     = action.payload.newMessages     != null ? action.payload.newMessages      : state.newMessages    
            state.waitingVehicles = action.payload.waitingVehicles != null ? action.payload.waitingVehicles  : state.waitingVehicles    
        } 

    }

  })

export const  reportActions  = report.actions

export default report.reducer